import { Action } from "../action";
import { EndpointsActionType } from "./endpoints.actions";
import createReducer from "../createReducer";
import initialState, { EndpointsState, Endpoint } from "./endpoints.store";

export default createReducer(initialState, {
  [EndpointsActionType.SET_ENDPOINTS](
    state: EndpointsState,
    action: Action<{ allEndpoints: Endpoint[]; receiverEndpoints: Endpoint[] }>
  ): EndpointsState {
    const { allEndpoints, receiverEndpoints } = action.payload;
    return {
      ...state,
      allEndpoints,
      receiverEndpoints,
    };
  },
  [EndpointsActionType.SEND_FILES](state: EndpointsState): EndpointsState {
    return {
      ...state,
      sendFilesInProgress: true,
    };
  },
  [EndpointsActionType.SEND_FILES_SUCCESSFUL](state: EndpointsState) {
    return {
      ...state,
      sendFilesInProgress: false,
    };
  },
  [EndpointsActionType.SEND_FILES_FAILURE](state: EndpointsState) {
    return {
      ...state,
      sendFilesInProgress: false,
    };
  },
  [EndpointsActionType.PUBLISH_FILES](state: EndpointsState): EndpointsState {
    return {
      ...state,
      publishFilesInProgress: true,
    };
  },
  [EndpointsActionType.PUBLISH_FILES_SUCCESSFUL](state: EndpointsState) {
    return {
      ...state,
      publishFilesInProgress: false,
    };
  },
  [EndpointsActionType.PUBLISH_FILES_FAILURE](state: EndpointsState) {
    return {
      ...state,
      publishFilesInProgress: false,
    };
  },
  [EndpointsActionType.RELOAD](state: EndpointsState): EndpointsState {
    return {
      ...state,
      reloadInProgress: true,
    };
  },
  [EndpointsActionType.RELOAD_SUCCESSFUL](state: EndpointsState) {
    return {
      ...state,
      reloadInProgress: false,
    };
  },
  [EndpointsActionType.RELOAD_FAILURE](state: EndpointsState) {
    return {
      ...state,
      reloadInProgress: false,
    };
  },
  [EndpointsActionType.FETCH_ALL_ENDPOINTS](state: EndpointsState): EndpointsState {
    return {
      ...state,
      fetchInProgress: true,
    };
  },
  [EndpointsActionType.FETCH_ALL_ENDPOINTS_SUCCESSFUL](state: EndpointsState) {
    return {
      ...state,
      fetchInProgress: false,
    };
  },
  [EndpointsActionType.FETCH_ALL_ENDPOINTS_FAILURE](state: EndpointsState) {
    return {
      ...state,
      fetchInProgress: false,
    };
  },
});
