import { connect } from "react-redux";
import { Dispatch } from "redux";

import CapabilitiesComponent, { ConnectedState, ConnectedDispatch } from "./capabilities.component";
import { AppState } from "../../store/app.state";
import { getSaveCapabilitiesProgress } from "../../store/global/global.selectors";
import { saveCapabilities } from "../../store/global/global.actions";
import { Capability } from "../../api/backend-api";

export interface OwnProps {
  readonly?: boolean;
  capabilities: Capability[];
  pushNotifications?: number;
  blockClassName?: string;
  isMqtt: boolean;
}

const mapStateToProps = (state: AppState): ConnectedState => ({
  saveStatus: getSaveCapabilitiesProgress(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  save: (capabilities: Capability[], pushNotifications: number) =>
    dispatch(saveCapabilities(capabilities, pushNotifications)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CapabilitiesComponent);
