import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "../../store/app.state";
import TelemetryComponent, { ConnectedDispatch, ConnectedState } from "./telemetry.component";

import {
  download,
  initTelemetry,
  loadTelemetry,
  resetTelemetry,
  updateTelemetry,
} from "../../store/telemetry/telemetry.actions";

import { getLastError, getPushNotifications } from "../../store/global/global.selectors";
import { getFileById } from "../../store/data-storage";

import {
  getDownloadStatus,
  getLoadTelemetryStatus,
  getTelemetryCaption,
  getTelemetryData,
  getTelemetryStatus,
  getTotal,
} from "../../store/telemetry/telemetry.selectors";

export interface OwnProps {
  match: { params: { fileId?: string } };
}

const mapStateToProps = (
  state: AppState,
  {
    match: {
      params: { fileId = "" },
    },
  }: OwnProps
): ConnectedState => {
  const { type = "" } = getFileById(state, fileId);

  return {
    loadTelemetryInProgress: getLoadTelemetryStatus(state),
    downloadInProgress: getDownloadStatus(state),
    telemetry: getTelemetryData(state),
    caption: getTelemetryCaption(state),
    pushNotifications: getPushNotifications(state),
    fileType: type,
    lastError: getLastError(state),
    telemetryStatus: getTelemetryStatus(state),
    total: getTotal(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  {
    match: {
      params: { fileId = "" },
    },
  }: OwnProps
): ConnectedDispatch => ({
  init: (rows: number) => dispatch(initTelemetry(fileId, rows)),
  load: (rows: number) => dispatch(loadTelemetry(fileId, rows)),
  update: () => dispatch(updateTelemetry(fileId)),
  download: (isIsoxml: boolean) => dispatch(download(isIsoxml, fileId)),
  resetTelemetry: () => dispatch(resetTelemetry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TelemetryComponent);
