import React, { FunctionComponent, useCallback } from "react";

import { Box, Typography, IconButton } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Logging from "../logging/logging.container";
import PaperComponent from "../paper/paper.component";
import { useDispatch, useSelector } from "react-redux";
import { changeLoggingStatus } from "../../store/global/global.actions";
import { getIsLoggingStatusEnabled } from "../../store/global/global.selectors";
import { AppState } from "../../store/app.state";
import AuthService from "../../shared/services/auth.service";

export interface Props {
  children?: React.ReactNode;
  isCollapsible?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    page: {
      position: "relative",
      height: "100vh",
    },
    pageWithLoggingContainer: {
      paddingBottom: 300,
    },
    pageWithoutLoggingContainer: {
      paddingBottom: 50,
    },
    logging: {
      position: "fixed",
      bottom: 0,
      left: 230,
      right: 0,
    },
    loggingTitleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    arrow: {
      marginLeft: theme.spacing(0.5),
    },
  })
);

const PageWithLogging: FunctionComponent<Props> = ({ children, isCollapsible }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEndUser = AuthService.getInstance().isEndUser();

  const isLoggingStatusEnabled = useSelector((state: AppState) => getIsLoggingStatusEnabled(state));

  const onChangeLoggingStatus = useCallback(() => {
    if (!isLoggingStatusEnabled) {
      dispatch(changeLoggingStatus(true));
    } else {
      dispatch(changeLoggingStatus(false));
    }
  }, [dispatch, isLoggingStatusEnabled]);

  return (
    <div className={classes.page}>
      <Box
        className={
          isLoggingStatusEnabled || !isCollapsible
            ? classes.pageWithLoggingContainer
            : classes.pageWithoutLoggingContainer
        }
      >
        <PaperComponent>{children}</PaperComponent>
      </Box>
      {!isEndUser && (
        <Box className={classes.logging} style={{ paddingBottom: "0" }}>
          <PaperComponent isTiny={true}>
            <div className={classes.loggingTitleWrapper}>
              <Typography variant="h6" style={{ padding: "10px 0" }}>
                Logging
              </Typography>
              {isCollapsible && (
                <IconButton className={classes.arrow} size="small" onClick={onChangeLoggingStatus}>
                  {isLoggingStatusEnabled ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
              )}
            </div>
            {(isLoggingStatusEnabled || !isCollapsible) && <Logging />}
          </PaperComponent>
        </Box>
      )}
    </div>
  );
};

export default PageWithLogging;
