import { connect } from "react-redux";

import SendFilesWindowComponent, { ConnectedProps } from "./send-files-window.component";
import { getFiles } from "../../../store/data-storage";
import { AppState } from "../../../store/app.state";
import { getPublishFilesProgress, getSendFilesProgress } from "../../../store/endpoints/endpoints.selectors";
import { FileTypes } from "../../../shared/accordion-titles";
import { IFileDto } from "../../../store/data-storage/data-storage.store";

const mapStateToProps = (state: AppState): ConnectedProps => {
  const files = getFiles(state);

  return {
    files,
    filesByType: Object.values(FileTypes).reduce(
      (byTypes, type: string) => ({
        ...byTypes,
        [type]: files.filter((file: IFileDto) => type.toLowerCase() === file.type.toLowerCase()),
      }),
      {}
    ),
    sendFilesProgress: getSendFilesProgress(state),
    publishFilesProgress: getPublishFilesProgress(state),
  };
};

export default connect(mapStateToProps)(SendFilesWindowComponent);
