import { createMuiTheme } from "@material-ui/core/styles";
import { palette } from "@material-ui/system";
import { Colors } from "./colors";

const ioPalette = {
  ...palette,
  primary: { main: Colors.Green },
  secondary: { main: Colors.DeepBlue },
};

export const ioTheme = createMuiTheme({
  palette: ioPalette,
  appSidebar: {
    width: 230,
  },
  props: {
    MuiLink: {
      color: "primary",
    },
  },
});
