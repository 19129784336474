export enum Roles {
  AGRIROUTER = "agrirouter",
  FORMAT = "format",
  FILE = "file",
  USER = "user",
  SIMULATOR = "simulator",
  TELEMETRY = "telemetry",
}

export enum WsMethods {
  PROGRESS = "progress",
  GET_FEED = "get-feed",
  LOG = "log",
  FILE = "file",
  CAPABILITIES = "capabilities",
  SUBSCRIPTIONS = "subscriptions",
  UPDATE = "update",
  ENDPOINTS = "update-endpoints",
}

export enum MethodsName {
  STATUS = "status",
  LIST_ENDPOINTS = "listEndpoints",
  AGRIROUTER_SUBLIST = "agrirouterSubList",
  LIST_CAPABILITIES = "listCapabilities",
  SET_CAPABILITIES = "setCapabilities",
  LIST_FORMATS = "listFormats",
  LIST_FILES = "listFiles",
  SET_FILE_LISTENER = "setFileListener",
  LIST_LOGS = "listLogs",
  SET_LOG_LISTENER = "setLogListener",
  DELETE_FILES = "deleteFiles",
  SEND_FILES = "sendFiles",
  PUBLISHFILES = "publishFiles",
  REONBOARD = "reonboard",
  RECONNECT = "reconnect",
  START_SECURE_ONBOARDING = "startSecureOnboarding",
  SECURE_ONBOARD = "agrirouterSecureOnboard",
  DISCONNECT = "agrirouterOffboard",
  UNREGISTER = "unregister",
  SET_SUBSCRIPTIONS = "agrirouterSetSubs",
  GET_TIMELOGS = "timelogInfo",
  SET_PROGRESS_LISTENER = "setProgressListener",
  SIMULATION_PROGRESS = "progress",
  START_SIMULATION = "simulate",
  SIMULATION_FORWARD = "forward",
  SIMULATION_RESUME = "resume",
  SIMULATION_PAUSE = "pause",
  SIMULATION_STOP = "stop",
  CLEAR_LOGS = "clearLogs",
  RENAME_FILE = "renameFile",
  TELEMETRY = "telemetry",
  SET_TELEMETRY_LISTENER = "setUpdateListener",
  UPDATE_TELEMETRY = "updateTelemetry",
  RECEIVE = "receiveFiles",
  CLEAR_FEED = "agrirouterClearFeeds",
  FETCH_LOGGING_STATUSES = "fetchLoggingStatus",
  CHANGE_LOGGING_STATUS = "changeLoggingStatus",
  ENABLE_TRACE_LOGGING = "enableTraceLogging",
  CHANGE_FILE_TYPE = "changeFileType",
}

export interface SocketRequest {
  id?: number;
  method: string;
  params: any;
}

export interface SocketResponse {
  id: number;
  message?: any;
  result?: any;
  error?: any;
  endpoint?: string;
  method?: string;
  params?: any;
}

export const Agrirouter = {
  [MethodsName.LIST_ENDPOINTS]: `${Roles.AGRIROUTER}.${MethodsName.LIST_ENDPOINTS}`,
  [MethodsName.AGRIROUTER_SUBLIST]: `${Roles.AGRIROUTER}.${MethodsName.AGRIROUTER_SUBLIST}`,
  [MethodsName.LIST_CAPABILITIES]: `${Roles.AGRIROUTER}.${MethodsName.LIST_CAPABILITIES}`,
};

export const Format = {
  [MethodsName.LIST_FORMATS]: `${Roles.FORMAT}.${MethodsName.LIST_FORMATS}`,
};

export const File = {
  [MethodsName.LIST_FILES]: `${Roles.FILE}.${MethodsName.LIST_FILES}`,
  [MethodsName.SET_FILE_LISTENER]: `${Roles.FILE}.${MethodsName.SET_FILE_LISTENER}`,
};

export const User = {
  [MethodsName.LIST_LOGS]: `${Roles.USER}.${MethodsName.LIST_LOGS}`,
  [MethodsName.SET_LOG_LISTENER]: `${Roles.USER}.${MethodsName.SET_LOG_LISTENER}`,
};

export const requiredMethods: any = [
  MethodsName.LIST_LOGS,
  MethodsName.LIST_FILES,
  MethodsName.SET_LOG_LISTENER,
];
