import React, { FunctionComponent } from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

export interface OwnProps {
  children?: React.ReactNode;
  isTiny?: boolean;
  style?: any;
}

const PaperComponent: FunctionComponent<OwnProps> = ({ children, isTiny, style }) => {
  return (
    <Paper elevation={2}>
      <Box style={style ? style : !isTiny ? { margin: "16px 0", padding: "32px" } : { padding: "0 16px" }}>
        {children}
      </Box>
    </Paper>
  );
};

export default PaperComponent;
