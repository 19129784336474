import moment from "moment";

import createReducer from "../createReducer";
import { Action } from "../action";

import { GlobalActionType } from "./global.actions";
import initialState, { GlobalState } from "./global.store";
import { Capability, LogEntry, StatusData, Subscription } from "../../api/backend-api";

export default createReducer(initialState, {
  [GlobalActionType.INIT](state: GlobalState): GlobalState {
    return { ...state, inited: false, fetchDataInProgress: true };
  },
  [GlobalActionType.INIT_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, inited: true, fetchDataInProgress: false, dataIsFetched: true };
  },
  [GlobalActionType.INIT_FAILURE](state: GlobalState): GlobalState {
    return { ...state, inited: false, fetchDataInProgress: false };
  },
  [GlobalActionType.SET_INITIALIZATION_CHECKINGS_DONE](state: GlobalState): GlobalState {
    return { ...state, inited: true };
  },
  [GlobalActionType.JUSTIFY_ONBOARDING_FINISH](state: GlobalState): GlobalState {
    return { ...state, onboardingIsJustified: true };
  },
  [GlobalActionType.SAVE_CAPABILITIES](state: GlobalState): GlobalState {
    return { ...state, saveCapabilitiesInProgress: true };
  },
  [GlobalActionType.SAVE_CAPABILITIES_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, saveCapabilitiesInProgress: false };
  },
  [GlobalActionType.SAVE_CAPABILITIES_FAILURE](state: GlobalState): GlobalState {
    return { ...state, saveCapabilitiesInProgress: false };
  },
  [GlobalActionType.SAVE_SUBSCRIPTIONS](state: GlobalState): GlobalState {
    return { ...state, saveSubscriptionsInProgress: true };
  },
  [GlobalActionType.SAVE_SUBSCRIPTIONS_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, saveSubscriptionsInProgress: false };
  },
  [GlobalActionType.SAVE_SUBSCRIPTIONS_FAILURE](state: GlobalState): GlobalState {
    return { ...state, saveSubscriptionsInProgress: false };
  },
  [GlobalActionType.RELOAD_CAPABILITIES](state: GlobalState): GlobalState {
    return { ...state, reloadCapabilitiesInProgress: true };
  },
  [GlobalActionType.RELOAD_CAPABILITIES_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, reloadCapabilitiesInProgress: false };
  },
  [GlobalActionType.RELOAD_CAPABILITIES_FAILURE](state: GlobalState): GlobalState {
    return { ...state, reloadCapabilitiesInProgress: false };
  },
  [GlobalActionType.RELOAD_SUBSCRIPTIONS](state: GlobalState): GlobalState {
    return { ...state, reloadSubscriptionsInProgress: true };
  },
  [GlobalActionType.RELOAD_SUBSCRIPTIONS_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, reloadSubscriptionsInProgress: false };
  },
  [GlobalActionType.RELOAD_SUBSCRIPTIONS_FAILURE](state: GlobalState): GlobalState {
    return { ...state, reloadSubscriptionsInProgress: false };
  },
  [GlobalActionType.ONBOARD](state: GlobalState): GlobalState {
    return { ...state, onboardInProgress: true };
  },
  [GlobalActionType.ONBOARD_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, onboardInProgress: false };
  },
  [GlobalActionType.ONBOARD_FAILURE](state: GlobalState): GlobalState {
    return { ...state, onboardInProgress: false };
  },
  [GlobalActionType.REONBOARD](state: GlobalState): GlobalState {
    return { ...state, onboardInProgress: true };
  },
  [GlobalActionType.REONBOARD_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, onboardInProgress: false };
  },
  [GlobalActionType.REONBOARD_FAILURE](state: GlobalState): GlobalState {
    return { ...state, onboardInProgress: false };
  },
  [GlobalActionType.RECONNECT](state: GlobalState): GlobalState {
    return { ...state, reconnectInProgress: true };
  },
  [GlobalActionType.RECONNECT_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, reconnectInProgress: false };
  },
  [GlobalActionType.RECONNECT_FAILURE](state: GlobalState): GlobalState {
    return { ...state, reconnectInProgress: false };
  },
  [GlobalActionType.DISCONNECT](state: GlobalState): GlobalState {
    return { ...state, disconnectInProgress: true };
  },
  [GlobalActionType.DISCONNECT_SUCCESSFUL](state: GlobalState): GlobalState {
    return { ...state, disconnectInProgress: false };
  },
  [GlobalActionType.DISCONNECT_FAILURE](state: GlobalState): GlobalState {
    return { ...state, disconnectInProgress: false };
  },
  [GlobalActionType.SET_LAST_ERROR](state: GlobalState, { payload = {} }: Action<any>): GlobalState {
    const lastError = { code: payload.code, message: payload.message || payload.msg };
    return { ...state, lastError };
  },
  [GlobalActionType.SET_CAPABILITIES](
    state: GlobalState,
    action: Action<{ capabilities: Capability[]; pushNotifications: number }>
  ): GlobalState {
    const { capabilities, pushNotifications } = action.payload;

    return { ...state, capabilities, pushNotifications };
  },
  [GlobalActionType.SET_SUBSCRIPTIONS](
    state: GlobalState,
    { payload: { subscriptions } }: Action<{ subscriptions: Subscription[] }>
  ): GlobalState {
    return { ...state, subscriptions };
  },
  [GlobalActionType.SET_LOGS](state: GlobalState, action: Action<[LogEntry]>): GlobalState {
    const earliestLogEntryTime = moment(action.payload[0]?.time).valueOf();
    const logs = action.payload.reverse();
    const latestLogEntryTime = moment(logs[0]?.time).valueOf();

    return { ...state, logs, earliestLogEntryTime, latestLogEntryTime };
  },
  [GlobalActionType.SET_LOG_ENTRY](state: GlobalState, { payload }: Action<LogEntry>): GlobalState {
    const latestLogEntryTime = moment(payload.time).valueOf();

    return { ...state, logs: [payload, ...state.logs], latestLogEntryTime };
  },
  [GlobalActionType.SET_STATUS_DATA](state: GlobalState, action: Action<StatusData>): GlobalState {
    return { ...state, ...action.payload };
  },
  [GlobalActionType.CLEAR_LOGS](state: GlobalState): GlobalState {
    return { ...state, clearLogsInProgress: true };
  },
  [GlobalActionType.CLEAR_LOGS_FAILURE](state: GlobalState): GlobalState {
    return { ...state, clearLogsInProgress: false };
  },
  [GlobalActionType.CLEAR_LOGS_SUCCESS](state: GlobalState): GlobalState {
    return { ...state, clearLogsInProgress: false, logs: [] };
  },
  [GlobalActionType.FETCH_LOGGING_STATUS](state: GlobalState): GlobalState {
    return { ...state, fetchLoggingStatusProgress: true };
  },
  [GlobalActionType.FETCH_LOGGING_STATUS_SUCCESS](state: GlobalState, action: Action<boolean>): GlobalState {
    const isLoggingEnabled = action.payload;
    return { ...state, isLoggingEnabled, fetchLoggingStatusProgress: false };
  },
  [GlobalActionType.FETCH_LOGGING_STATUS_FAILURE](state: GlobalState): GlobalState {
    return { ...state, fetchLoggingStatusProgress: false };
  },
  [GlobalActionType.CHANGE_LOGGING_STATUS](state: GlobalState): GlobalState {
    return { ...state, changeLoggingStatusProgress: true };
  },
  [GlobalActionType.CHANGE_LOGGING_STATUS_SUCCESS](
    state: GlobalState,
    action: Action<{ isLoggingEnabled: boolean }>
  ): GlobalState {
    const { isLoggingEnabled } = action.payload;
    return {
      ...state,
      isLoggingEnabled,
      changeLoggingStatusProgress: false,
    };
  },
  [GlobalActionType.CHANGE_LOGGING_STATUS_FAILURE](state: GlobalState): GlobalState {
    return { ...state, changeLoggingStatusProgress: false };
  },
  [GlobalActionType.FETCH_TRACE_LOGGING_STATUS](state: GlobalState): GlobalState {
    return { ...state, fetchLoggingStatusProgress: true };
  },
  [GlobalActionType.FETCH_TRACE_LOGGING_STATUS_SUCCESS](
    state: GlobalState,
    action: Action<number>
  ): GlobalState {
    const traceLoggingEnabledTill = action.payload;
    return { ...state, traceLoggingEnabledTill, fetchTraceLoggingStatusProgress: false };
  },
  [GlobalActionType.FETCH_TRACE_LOGGING_STATUS_FAILURE](state: GlobalState): GlobalState {
    return { ...state, fetchTraceLoggingStatusProgress: false };
  },
  [GlobalActionType.CHANGE_TRACE_LOGGING_STATUS](state: GlobalState): GlobalState {
    return { ...state, changeTraceLoggingStatusProgress: true };
  },
  [GlobalActionType.CHANGE_TRACE_LOGGING_STATUS_SUCCESS](
    state: GlobalState,
    action: Action<number>
  ): GlobalState {
    return {
      ...state,
      traceLoggingEnabledTill: action.payload,
      changeTraceLoggingStatusProgress: false,
    };
  },
  [GlobalActionType.CHANGE_TRACE_LOGGING_STATUS_FAILURE](state: GlobalState): GlobalState {
    return { ...state, changeTraceLoggingStatusProgress: false };
  },
  [GlobalActionType.FETCH_ENVIRONMENT_SUCCESS](state: GlobalState, action: Action<number>): GlobalState {
    return { ...state, links: action.payload };
  },
});
