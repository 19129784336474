import React, { FunctionComponent, useState, useCallback, useMemo } from "react";

import { Typography, TableRow, TableCell, Checkbox } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import TransitionsModal from "../../common/modal-winidow/modal-window.component";
import { IFileDto } from "../../../store/data-storage/data-storage.store";
import { Endpoint } from "../../../store/endpoints/endpoints.store";
import TableComponent from "../../common/table/table.component";
import AppButton from "../../common/button/button.component";
import { ButtonSize } from "../../../shared/button-style";
import { Colors } from "../../../theme/colors";
import { FileTypes } from "../../../shared/accordion-titles";

import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { formatUnsupportedTypesMessage } from "../../../utils/unsupported-types-message-formatter";

export interface ConnectedProps {
  endpoints: Endpoint[];
  sendFilesProgress: boolean;
  publishProgress: boolean;
}

export interface ConnectedDispatch {
  sendToEndpoints: (files: string[], endpoints: string[]) => void;
  publishFiles: (files: string[], endpoints: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "flex-end",
    },
    tableTitle: {
      padding: "20px 0px",
    },
    cancelButton: {
      backgroundColor: Colors.Grey,
      "&:hover": {
        backgroundColor: Colors.DarkGrey,
      },
    },
    warning: {
      borderRadius: 4,
      backgroundColor: Colors.Orange,
      color: Colors.White,
      padding: 8,
      display: "flex",
      maxHeight: 50,
    },
    textWrapper: {
      lineHeight: "20px",
    },
  })
);

const UNSUPPORTED_TYPES = [FileTypes.EFDI_TIMELOG, FileTypes.UNKNOWN];

interface Props extends ConnectedProps, ConnectedDispatch {
  publishMode: boolean;
  open: boolean;
  closeModal: () => void;
  files: IFileDto[];
}

const SendToEndpointsWindow: FunctionComponent<Props> = (props: Props) => {
  const { open, endpoints, files = [], sendFilesProgress, publishMode, publishProgress } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState<Endpoint[]>([]);

  const areFilesHaveUnsupportedTypes = useMemo(
    () =>
      files.some((file: IFileDto) => file.type === FileTypes.UNKNOWN || file.type === FileTypes.EFDI_TIMELOG),
    [files]
  );

  const unsupportedTypes = useMemo(
    () =>
      Array.from(new Set(files.map(({ type }) => type).filter((type) => UNSUPPORTED_TYPES.includes(type)))),
    [files]
  );

  const toggleSelection = useCallback(
    (id: string) => {
      const selectedIndex = selected.findIndex(({ id: selectedId }) => id === selectedId);

      if (selectedIndex === -1) {
        const selectedValue = endpoints.find((endpoint: Endpoint) => endpoint.id === id);

        if (selectedValue) {
          setSelected([...selected, selectedValue]);
        }
      } else {
        setSelected(selected.filter(({ id: selectedId }) => selectedId !== id));
      }
    },
    [selected, endpoints]
  );

  const getFilesProcessor = useCallback(
    (publish: boolean) => () => {
      props[publish ? "publishFiles" : "sendToEndpoints"](
        files.map(({ id }) => id),
        selected.map(({ id }) => id)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files, selected]
  );

  return (
    <TransitionsModal closeModal={props.closeModal} open={open} size="800px">
      <Typography variant="h6">{publishMode ? "Publish files" : "Send files to endpoints"}</Typography>
      {areFilesHaveUnsupportedTypes && (
        <div className={classes.warning}>
          <div style={{ marginRight: 6, display: "flex" }}>
            <ReportProblemIcon />
          </div>
          <div
            className={classes.textWrapper}
            dangerouslySetInnerHTML={{
              __html: formatUnsupportedTypesMessage(files, unsupportedTypes, publishMode),
            }}
          />
        </div>
      )}
      <TableComponent
        tableTitle={
          <Typography className={classes.tableTitle} variant="h6">
            Files
          </Typography>
        }
        tableHead={
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        }
        tableBody={files.map(({ id, filename, size, type }) => {
          return (
            <TableRow key={id}>
              <TableCell component="th" scope="row">
                {filename}
              </TableCell>
              <TableCell>{size}</TableCell>
              <TableCell>{type}</TableCell>
            </TableRow>
          );
        })}
      />

      {!publishMode && (
        <TableComponent
          tableTitle={
            <Typography className={classes.tableTitle} variant="h6">
              Endpoints
            </Typography>
          }
          tableHead={
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
            </TableRow>
          }
          tableBody={endpoints.map(({ name, id }) => {
            const isSelected = selected.findIndex(({ id: selectedId }) => id === selectedId) !== -1;

            return (
              <TableRow
                key={id}
                hover={true}
                role="checkbox"
                onClick={() => toggleSelection(id)}
                selected={isSelected}
                aria-checked={isSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox inputProps={{ "aria-labelledby": id }} checked={isSelected} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {name}
                </TableCell>
              </TableRow>
            );
          })}
        />
      )}
      <div className={classes.buttonsContainer}>
        <AppButton size={ButtonSize.TINY_BUTTON} className={classes.cancelButton} handler={props.closeModal}>
          Cancel
        </AppButton>
        <AppButton
          size={ButtonSize.TINY_BUTTON}
          handler={getFilesProcessor(publishMode)}
          disabled={
            (!publishMode && selected.length === 0) || sendFilesProgress || areFilesHaveUnsupportedTypes
          }
          progress={publishMode ? publishProgress : sendFilesProgress}
        >
          {publishMode ? "Publish" : "Send"}
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default SendToEndpointsWindow;
