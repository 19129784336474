import { createSelector } from "reselect";
import { INITIAL_CONFIGURATION } from "../../pages/simulation/simulator-configuration.component";

import { AppState } from "../app.state";
import { SimulatorFile } from "./simulation.store";

export const getSimulationState = (state: AppState) => state.simulation;

export const getSimulationFilesStatus = createSelector(
  getSimulationState,
  (state) => state.getFilesInProgress || false
);

export const getSimulationTimelogsStatus = createSelector(
  getSimulationState,
  (state) => state.getTimelogsInProgress || false
);

export const isSimulationInProgress = createSelector(
  getSimulationState,
  (state) => state.simulationInProgress || false
);

export const exportProgress = createSelector(getSimulationState, (state) => state.exportInProgress || false);
export const getSimulationFiles = createSelector(getSimulationState, (state) => state.files || []);

export const getSimulationFilesByType = createSelector(getSimulationState, (state) => {
  const filesCopy = [...state.files];

  return filesCopy.reduce((acc: { [key: string]: SimulatorFile[] }, file: SimulatorFile) => {
    if (!acc[file.type]) {
      acc[file.type] = [];
    }

    acc[file.type].push(file);
    return acc;
  }, {});
});

export const getSimulationTimelogs = createSelector(getSimulationState, (state) => state.timelogs || []);
export const getSimulationDevices = createSelector(getSimulationState, (state) => state.devices || []);
export const getProgress = createSelector(getSimulationState, (state) => state.progress || null);
export const getConfiguration = createSelector(
  getSimulationState,
  (state) => state.configuration || INITIAL_CONFIGURATION
);
