import { FunctionComponent } from "react";
import React from "react";

import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import useStyles from "./accordion.styles";

export interface Props {
  title: string;
  panelId: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
}

export const AccordionComponent: FunctionComponent<Props> = ({
  title,
  children,
  panelId,
  defaultExpanded = false,
}) => {
  const WITHOUT_ELEVATION = 0;
  const classes = useStyles();
  return (
    <div className={classes.accordionWrapper}>
      <Accordion defaultExpanded={defaultExpanded} elevation={WITHOUT_ELEVATION}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          classes={{ root: classes.root }}
          id={panelId}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};
