import React, { FunctionComponent, useState, useEffect } from "react";

import { Box, Typography, Checkbox } from "@material-ui/core";

import AppButton from "../../components/common/button/button.component";
import { ButtonSize } from "../../shared/button-style";
import { Subscription } from "../../api/backend-api";

export interface ConnectedState {
  subscriptions: Subscription[];
  saveSubscriptionsStatus: boolean;
}

export interface ConnectedDispatch {
  save: (subscriptions: string[]) => any;
}

interface SubscriptionProps extends ConnectedState, ConnectedDispatch {}

const Subscriptions: FunctionComponent<SubscriptionProps> = ({
  subscriptions,
  saveSubscriptionsStatus,
  save,
}: SubscriptionProps) => {
  const [activeSubscriptions, setActiveSubscriptions] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    const activeSet = subscriptions.reduce<string[]>((types, { active, type }) => {
      if (active) {
        types.push(type);
      }

      return types;
    }, []);
    setActiveSubscriptions(activeSet);
    setSelectedAll(activeSet.length === subscriptions.length);
  }, [subscriptions]);

  const selectSubscription = (type: string, checked: boolean) => {
    let activeSet = [...activeSubscriptions];

    if (checked) {
      activeSet.push(type);
    } else {
      activeSet = activeSet.filter((selectedType) => selectedType !== type);
    }

    setSelectedAll(activeSet.length === subscriptions.length);
    setActiveSubscriptions(activeSet);
  };

  const toggleSelectAll = (value: boolean) => {
    if (value) {
      setActiveSubscriptions(subscriptions.map(({ type }) => type));
      setSelectedAll(true);
    } else {
      setActiveSubscriptions([]);
      setSelectedAll(false);
    }
  };

  const onSavePress = () => save(activeSubscriptions);

  return (
    <Box>
      <Typography variant="h6" style={{ fontWeight: "bold" }}>
        Subscriptions
      </Typography>
      <Box display="flex" alignItems="center" paddingBottom="5px" paddingTop="20px">
        <Checkbox
          checked={selectedAll}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => toggleSelectAll(event.target.checked)}
        />
        <Typography>{`${selectedAll ? "Deselect" : "Select"} All`}</Typography>
      </Box>
      <Box paddingLeft="20px">
        {subscriptions.map(({ type, name }) => (
          <Box key={type} display="flex" alignItems="center">
            <Checkbox
              checked={activeSubscriptions.includes(type)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                selectSubscription(type, event.target.checked)
              }
              role="subscription-checkbox"
            />
            <Typography>{name}</Typography>
          </Box>
        ))}
      </Box>
      <Box>
        <AppButton size={ButtonSize.SMALL_BUTTON} disabled={saveSubscriptionsStatus} handler={onSavePress}>
          Send &amp; Save
        </AppButton>
      </Box>
    </Box>
  );
};

export default Subscriptions;
