import Cookies from "universal-cookie";
import StorageService, { AuthLocalStorageKeys } from "./storage.service";

interface IAuth {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: string;
  refresh_expires_in: string;
  roles: string[];
  email: string;
  user_name: string;
}

export const C_SESSION = "SDSDSESSION";
const cookies = new Cookies();

const localStorageService = StorageService.getInstance();

class AuthService {
  private static instance: AuthService;

  private constructor() {
    if (AuthService.instance) {
      throw new Error("Instantiation failed: use service.getInstance() instead of new.");
    }
  }

  static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  initialize(authConfig: IAuth) {
    const {
      access_token,
      refresh_token,
      id_token,
      expires_in,
      refresh_expires_in,
      roles,
      email,
      user_name,
    } = authConfig;
    localStorageService.setItem(AuthLocalStorageKeys.AUTH_TOKEN, access_token ?? "");
    localStorageService.setItem(AuthLocalStorageKeys.REFRESH_TOKEN, refresh_token ?? "");
    localStorageService.setItem(AuthLocalStorageKeys.TOKEN_ID, id_token ?? "");
    localStorageService.setItem(AuthLocalStorageKeys.EXPIRES_IN, expires_in ?? 300);
    localStorageService.setItem(
      AuthLocalStorageKeys.REFRESH_EXPIRES_IN_DATE,
      (refresh_expires_in ?? 300) + Math.floor(Date.now() / 1000)
    );
    localStorageService.setItem(
      AuthLocalStorageKeys.ACCESS_EXPIRES_IN_DATE,
      (expires_in ?? 300) + Math.floor(Date.now() / 1000)
    );
    localStorageService.setItem(AuthLocalStorageKeys.ROLES, JSON.stringify(roles) ?? "");
    localStorageService.setItem(
      AuthLocalStorageKeys.USER_INFO,
      JSON.stringify({
        email: email ?? "",
        userName: user_name ?? "",
      })
    );

    return true;
  }

  getToken() {
    return localStorageService.getItem(AuthLocalStorageKeys.AUTH_TOKEN) ?? "";
  }

  getRefreshToken() {
    return localStorageService.getItem(AuthLocalStorageKeys.REFRESH_TOKEN) ?? "";
  }

  getTokenId() {
    return localStorageService.getItem(AuthLocalStorageKeys.TOKEN_ID) ?? "";
  }

  get refreshDateTime() {
    return parseInt(localStorageService.getItem(AuthLocalStorageKeys.REFRESH_EXPIRES_IN_DATE) ?? "", 10) ?? 0;
  }

  get accessDateTime() {
    return parseInt(localStorageService.getItem(AuthLocalStorageKeys.ACCESS_EXPIRES_IN_DATE) ?? "", 10) ?? 0;
  }

  getRoles() {
    const rolesString = localStorageService.getItem(AuthLocalStorageKeys.ROLES) ?? "";
    return rolesString ? JSON.parse(rolesString) : [];
  }

  getUserInfo() {
    return JSON.parse(localStorageService.getItem(AuthLocalStorageKeys.USER_INFO) ?? "{}");
  }

  isEndUser() {
    return !this.getRoles().includes("developer");
  }

  updateToken(data: any) {
    localStorageService.setItem(AuthLocalStorageKeys.EXPIRES_IN, data?.expires_in ?? 300);
    localStorageService.setItem(
      AuthLocalStorageKeys.REFRESH_EXPIRES_IN_DATE,
      (data?.refresh_expires_in ?? 300) + Math.floor(Date.now() / 1000)
    );
    localStorageService.setItem(
      AuthLocalStorageKeys.ACCESS_EXPIRES_IN_DATE,
      (data?.expires_in ?? 300) + Math.floor(Date.now() / 1000)
    );
    localStorage.setItem(AuthLocalStorageKeys.AUTH_TOKEN, data?.access_token ?? "");
    localStorage.setItem(AuthLocalStorageKeys.REFRESH_TOKEN, data?.refresh_token ?? "");
    document.cookie = `X-Authorization=${data?.access_token}; path=/`;
  }

  isRefreshTokenExpired() {
    const currentTime = Math.floor(Date.now() / 1000);
    return this.refreshDateTime < currentTime;
  }

  isAccessTokenExpired() {
    const currentTime = Math.floor(Date.now() / 1000);
    return this.accessDateTime < currentTime;
  }

  get isLoggedIn() {
    return !!this.getToken();
  }

  getCookie() {
    return cookies.get(C_SESSION);
  }

  deleteCookie() {
    cookies.remove(C_SESSION);
  }
}

export default AuthService;
