/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import moment from "moment";

import { Box, Typography, Select, MenuItem, Checkbox, TextField, ListSubheader } from "@material-ui/core";

import { SimulatorFile, SimulatorTimelogEntry } from "../../store/simulation/simulation.store";
import { OwnProps } from "./simulator-configuration.container";
import useStyles from "./simulation.styles";
import { FILTER_FORMAT } from "../../shared/time-formats";
import { useHistory } from "react-router";

export interface ConnectedState {
  timelogs: SimulatorTimelogEntry[];
  getTimelogsStatus: boolean;
}

export interface ConnectedDispatch {
  getTimelogs: (fileIds: string[]) => any;
}

export interface ConfigurationParams {
  file: string;
  timelog: string | null;
  endless: boolean;
  replaceTime: string | null;
  skipInitial: number;
  interval: number;
  scale: number;
}

export const INITIAL_CONFIGURATION = {
  file: "",
  timelog: "all",
  endless: false,
  interval: 5,
  skipInitial: 0,
  scale: 1,
  replaceTime: "",
};

interface SimulatorConfigurationProps extends ConnectedState, ConnectedDispatch, OwnProps {}

const SimulatorConfiguration: FunctionComponent<SimulatorConfigurationProps> = (
  props: SimulatorConfigurationProps
) => {
  const { files, timelogs, isSimulationInProgress, configuration } = props;
  const classes = useStyles();
  const history = useHistory();
  const [file, setFile] = useState(configuration.file);
  const [timelog, setTimelog] = useState(configuration.timelog);
  const [replaceTimeIsEnabled, setReplaceTimeIsEnabled] = useState(!!configuration.replaceTime);
  const [replaceTime, setReplaceTime] = useState(
    moment(configuration.replaceTime).format(FILTER_FORMAT) ?? ""
  );
  const [interval, setInterval] = useState(configuration.interval);
  const [skipInitial, setSkipInitial] = useState(configuration.skipInitial);
  const [scale, setScale] = useState(configuration.scale);
  const [endless, setEndless] = useState(configuration.endless);

  useEffect(() => {
    if (file) {
      props.getTimelogs([file]);
    }
  }, [file]);

  useEffect(() => {
    props.onUpdateConfiguration({
      ...configuration,
      file,
      timelog,
      replaceTime: replaceTimeIsEnabled
        ? replaceTime
          ? moment(replaceTime).toISOString()
          : moment().toISOString()
        : null,
      interval,
      skipInitial,
      scale,
      endless,
    });
  }, [file, timelog, replaceTime, interval, skipInitial, scale, endless, replaceTimeIsEnabled]);

  const renderSelectGroup = useCallback((key: string, entries: SimulatorFile[]) => {
    const items = entries.map(({ id, filename }) => {
      return (
        <MenuItem key={id} value={id}>
          {filename}
        </MenuItem>
      );
    });
    return [<ListSubheader key={key}>{key}</ListSubheader>, items];
  }, []);

  const onFileChange = useCallback((fileId: string) => {
    setFile(fileId);
    history.replace(`?id=${fileId}`);
  }, []);

  return (
    <Box display="flex">
      <Box className={classes.controlColumn}>
        <Box className={classes.controlRow}>
          <Typography className={classes.configurationLabel}>Sourcefile</Typography>
          <Select
            value={file ?? ""}
            disabled={isSimulationInProgress}
            className={classes.configurationControl}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              onFileChange(event.target.value as string)
            }
            inputProps={{
              "data-testid": "filename-input",
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {Object.entries(files ?? {}).map(([key, entries]) => renderSelectGroup(key, entries))}
          </Select>
        </Box>
        <Box className={classes.controlRow}>
          <Typography className={classes.configurationLabel}>Timelog</Typography>
          <Select
            disabled={timelogs.length === 0 || isSimulationInProgress}
            value={timelogs.length === 0 ? "" : timelog}
            onChange={(event: React.ChangeEvent<any>) => setTimelog(event.target.value)}
            className={classes.configurationControl}
          >
            <MenuItem value="all">All timelogs</MenuItem>
            {timelogs.map(({ id }: SimulatorTimelogEntry) => (
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={classes.controlRow}>
          <Typography className={classes.configurationLabel}>Endless Loop</Typography>
          <Checkbox
            disabled={isSimulationInProgress}
            checked={endless}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEndless(event.target.checked)}
          />
        </Box>
        <Box className={classes.controlRow}>
          <Typography className={classes.configurationLabel}>Replace Time</Typography>
          <Box className={classes.configurationControl}>
            <Checkbox
              disabled={isSimulationInProgress}
              checked={replaceTimeIsEnabled}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setReplaceTimeIsEnabled(event.target.checked);
                setReplaceTime(event.target.checked ? moment().format(FILTER_FORMAT) : "");
              }}
            />

            {replaceTimeIsEnabled && (
              <TextField
                type="datetime-local"
                value={replaceTime}
                disabled={isSimulationInProgress || !replaceTimeIsEnabled}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setReplaceTime(event.target.value)}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box className={classes.controlRow}>
          <Typography className={classes.configurationLabel}>Skip Initial</Typography>
          <Box className={classes.configurationControl}>
            <TextField
              type="number"
              inputProps={{
                min: 0,
              }}
              disabled={isSimulationInProgress}
              value={skipInitial}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = parseInt(event.target.value, 10);

                setSkipInitial(
                  isNaN(value) || value < INITIAL_CONFIGURATION.skipInitial
                    ? INITIAL_CONFIGURATION.skipInitial
                    : value
                );
              }}
            />
          </Box>
        </Box>
        <Box className={classes.controlRow}>
          <Typography className={classes.configurationLabel}>Interval (sec)</Typography>
          <Box className={classes.configurationControl}>
            <TextField
              type="number"
              inputProps={{
                min: 5,
              }}
              disabled={isSimulationInProgress}
              value={interval}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = parseInt(event.target.value, 10);

                setInterval(
                  isNaN(value) || value < INITIAL_CONFIGURATION.interval
                    ? INITIAL_CONFIGURATION.interval
                    : value
                );
              }}
            />
          </Box>
        </Box>
        <Box className={classes.controlRow}>
          <Typography className={classes.configurationLabel}>Scale (x-times)</Typography>
          <Box className={classes.configurationControl}>
            <TextField
              type="number"
              inputProps={{
                min: 1,
                step: 0.1,
              }}
              disabled={isSimulationInProgress}
              value={scale}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = parseFloat(event.target.value);

                setScale(
                  isNaN(value) || value < INITIAL_CONFIGURATION.scale ? INITIAL_CONFIGURATION.scale : value
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SimulatorConfiguration;
