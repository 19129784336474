import { LoadingStatus, loadInitial } from "../loading-status";

export interface IUser {
  username?: string;
  isAdmin?: boolean;
}

export interface AuthenticationState {
  loginInProgress: boolean;
  isAuthenticated: boolean;
  changePasswordInProgress: boolean;
  changePasswordError: string;
  user: IUser;
  loadStatus: LoadingStatus;
}

const state: AuthenticationState = {
  loginInProgress: false,
  isAuthenticated: false,
  changePasswordInProgress: false,
  changePasswordError: "",
  user: {},
  loadStatus: loadInitial(),
};

export default state;
