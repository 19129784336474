export enum Colors {
  Green = "rgb(113,183,144)",
  Green2 = "rgb(100,163,124)",
  DarkGreen = "rgb(100,163,124)",
  Grey = "rgb(123, 124, 126)",
  Grey2 = "rgb(200,200,200)",
  LightGrey = "rgb(236,236,236)",
  LightGrey2 = "rgb(250, 250, 250)",
  DarkGrey = "rgb(60, 60, 62)",
  Red = "rgb(198, 29, 43)",
  Red2 = "rgb(150, 21, 32)",
  Red3 = "rgb(244, 67, 54)",
  Yellow = "rgb(241, 203, 77)",
  DarkYellow = "rgb(254, 195, 14)",
  Orange = "rgb(240, 152, 75)",
  DarkOrange = "rgb(173, 129, 83)",
  White = "rgb(255, 255, 255)",
  Black = "rgb(0,0,0)",
  DeepBlue = "rgb(32,42,68)",
}
