import React, { FunctionComponent, useState } from "react";

import { Collapse, TableCell, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";

import { DdiElement } from "../../../store/simulation/simulation.store";
import TableComponent from "../../common/table/table.component";

interface Props {
  element: DdiElement;
}

const useStyles = makeStyles({
  row: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  buttonCell: {
    width: "30px",
  },
  ddiCell: {
    width: "40px",
  },
  headRow: {
    "&>th": { fontWeight: "bold" },
  },
});

const Element: FunctionComponent<Props> = ({ element: { element, ddis } }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell className={classes.buttonCell}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{element}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <TableComponent
              fixed={false}
              tableHead={
                <TableRow className={`${classes.row} ${classes.headRow}`}>
                  <TableCell className={classes.ddiCell}>DDI</TableCell>
                  <TableCell>Name</TableCell>
                </TableRow>
              }
              tableBody={ddis.map(({ ddi, name }) => (
                <TableRow key={ddi}>
                  <TableCell className={classes.ddiCell}>{ddi}</TableCell>
                  <TableCell>{name}</TableCell>
                </TableRow>
              ))}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Element;
