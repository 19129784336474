export enum ApiControllers {
  USER = "/rest/user",
  LOGS = "/rest/user/logs",
  FILES = "/rest/files",
  SIMULATOR = "/rest/simulator",
  VALIDATOR = "/rest/validate",
  TELEMETRY = "/rest/telemetry",
  AUTH = "/rest/auth",
  FORMATS = "/rest/formats",
  AGRIROUTER_FILES = "/rest/agrirouter/files",
  AGRIROUTER_ENDPOINTS = "/rest/agrirouter/endpoints",
  AGRIROUTER_CAPABILITIES = "/rest/agrirouter/capabilities",
  AGRIROUTER_SUBSCRIPTIONS = "/rest/agrirouter/subscriptions",
  AGRIROUTER_ONBOARDING = "/rest/agrirouter/onboarding",
}

export enum ApiEndpoints {
  CALLBACK = "/callback",
  URL = "/url",
  REFRESH = "/refresh",
  LOGOUT = "/logout",
  STATUS = "/status",
  UPLOAD = "/upload",
  DOWNLOAD = "/download",
  EFDI = "/efdi",
  ISOXML = "/isoxml",
  VALIDATE = "/rest/validate",
  TELEMETRY = "/rest/telemetry",
  LOGGING_STATUS = "/loggingStatus",
  TRACE_LOGGING_STATUS = "/traceLoggingStatus",
  CLEAR_LOGS = "/clear",
  PUBLISH = "/publish",
  SEND = "/send",
  RECEIVE = "/receive",
  CLEAR_FEED = "/clear-feed",
  FILES = "/files",
  PROGRESS = "/progress",
  TIMELOG_INFO = "/timelog-info",
  SIMULATE = "/simulate",
  FORWARD = "/forward",
  PAUSE = "/pause",
  STOP = "/stop",
  RESUME = "/resume",
  REDIRECT_URI = "/",
  ONBOARD_URL = "/onboard-url",
  REONBOARD_URL = "/reonboard-url",
  RECONNECT = "/reconnect",
  OFFBOARD = "/offboard",
  ENVIRONMENT = "/environments",
}
