import { CommonActionType } from "./common.actions";
import { Action } from "../action";
import createReducer from "../createReducer";

export interface CommonState {
  isOpen: boolean;
  isValidModalOpen: boolean;
}

export const initialState: CommonState = {
  isOpen: false,
  isValidModalOpen: false,
};

export default createReducer(initialState, {
  [CommonActionType.TOGGLE_UPLOAD_MODAL_WINDOW](state: CommonState, action: Action<string>) {
    return { ...state, isOpen: action.payload };
  },
  [CommonActionType.TOGGLE_VALIDATION_MODAL_WINDOW](state: CommonState, action: Action<string>) {
    return { ...state, isValidModalOpen: action.payload };
  },
});
