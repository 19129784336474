import React, { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import IoLogo from "../../images/io-logo.png";

import ChangelogWindow from "../modal-windows/changelog-window/changelog-window.component";

import { sidebarRoutes } from "../../routes/routes";
import RoutePaths from "../../routes/route-paths";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      width: theme.appSidebar?.width,
      flexShrink: 0,
    },
    drawer: {
      width: theme.appSidebar?.width,
      backgroundColor: theme.palette?.secondary.main,
    },
    toolbar: theme.mixins?.toolbar,
    navLink: {
      color: theme.palette?.secondary.contrastText,
      textDecoration: "none",
      "&.active, &.active $listItemIcon": {
        color: theme.palette?.primary.main,
      },
    },
    listItemIcon: {
      color: theme.palette?.secondary.contrastText,
    },
    drawerContainer: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    appName: {
      color: theme.palette?.secondary.contrastText,
      fontSize: "1.5rem",
      paddingTop: "15px",
      paddingBottom: "10px",
      marginLeft: "16px",
    },
    menuList: {
      width: "100%",
      "& .MuiListItem-button:hover": {
        textDecoration: "none",
        backgroundColor: "rgba(0, 0, 0, 0.14)",
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      padding: "10px 0",
    },
    version: {
      color: "white",
      padding: 10,
      margin: "0 auto 0",
    },
    button: {
      cursor: "pointer",
      backgroundColor: "inherit",
      color: "white",
      border: "none",
      fontSize: "14px",
    },
  })
);

interface Props {
  isOnboarded: boolean;
  isEndUser: boolean;
}

const SidebarComponent: FunctionComponent<Props> = ({ isOnboarded, isEndUser }: Props) => {
  const classes = useStyles();
  const [showChangelog, setShowChangelog] = useState(false);

  const version = `Version: ${process.env.REACT_APP_VERSION ?? ""} (${
    process.env.REACT_APP_COMMIT_ID ? process.env.REACT_APP_COMMIT_ID : ""
  })`;

  return (
    <nav className={classes.navigation} {...{ "data-testid": "sidebar" }}>
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        variant="permanent"
        open={true}
      >
        <div className={classes.header}>
          <img src={IoLogo} alt="logo" style={{ width: "90px", marginLeft: "16px" }} />
          <Typography className={classes.appName} align="center">
            IO-Tool
          </Typography>
        </div>
        <div className={classes.drawerContainer}>
          <List className={classes.menuList}>
            {sidebarRoutes.map((route) => {
              const { name, path, onboarded } = route;

              if ((!isOnboarded && onboarded) || (path === RoutePaths.Simulation && isEndUser)) {
                return null;
              }

              return (
                <NavLink
                  key={name}
                  activeClassName="active"
                  to={`${path}`}
                  className={classes.navLink}
                  role="menu-link"
                >
                  <ListItem button={true}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItem>
                </NavLink>
              );
            })}
          </List>
        </div>
        <div className={classes.version}>
          <button onClick={() => setShowChangelog(true)} className={classes.button}>
            {version}
          </button>
        </div>
        <ChangelogWindow open={showChangelog} closeModal={() => setShowChangelog(false)} />
      </Drawer>
    </nav>
  );
};

export default SidebarComponent;
