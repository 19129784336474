type EndpointType = "application" | "machine";

export interface Endpoint {
  active: boolean;
  id: string;
  name: string;
  type: EndpointType;
  accepts: string[];
  sends: string[];
}

export const emptyEndpoint: Endpoint = {
  id: "",
  accepts: [],
  sends: [],
  type: "application",
  active: false,
  name: "",
};

export interface EndpointsState {
  allEndpoints: Endpoint[];
  receiverEndpoints: Endpoint[];
  sendFilesInProgress: boolean;
  publishFilesInProgress: boolean;
  reloadInProgress: boolean;
  fetchInProgress: boolean;
}

export const state: EndpointsState = {
  allEndpoints: [],
  receiverEndpoints: [],
  sendFilesInProgress: false,
  publishFilesInProgress: false,
  reloadInProgress: false,
  fetchInProgress: false,
};

export default state;
