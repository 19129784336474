import { createSelector } from "reselect";

import { AppState } from "../app.state";

export const getTelemetryState = (state: AppState) => state.telemetry;

export const getLoadTelemetryStatus = createSelector(
  getTelemetryState,
  (state) => state.loadTelemetryInProgress || false
);

export const getDownloadStatus = createSelector(
  getTelemetryState,
  (state) => state.downloadInProgress || false
);

export const getTelemetryData = createSelector(getTelemetryState, (state) => state.telemetry || []);
export const getTelemetryCaption = createSelector(getTelemetryState, (state) => state.caption || {});
export const getGeoJSON = createSelector(
  getTelemetryState,
  (state) => state.geoJSON || { features: [], type: "" }
);
export const getTelemetryStatus = createSelector(getTelemetryState, (state) => state.isInited || false);
export const getTelemetryOffset = createSelector(getTelemetryState, (state) => state.offset || 0);
export const getTotal = createSelector(getTelemetryState, (state) => state.total || 0);
