import { connect } from "react-redux";
import { Dispatch } from "redux";

import SettingsComponent, { ConnectedState, ConnectedDispatch } from "./settings.component";
import { AppState } from "../../store/app.state";
import {
  onboard,
  disconnect,
  reconnect,
  changeTraceLoggingStatus,
  reonboard,
  fetchEnvironments,
} from "../../store/global/global.actions";

import {
  getReonboardProgress,
  getDisconnectProgress,
  getExpireDays,
  getOnboardStatus,
  getCapabilities,
  getPushNotifications,
  getOnboardingStatus,
  isMqtt,
  getReconnectProgress,
  isQA,
  getStatusData,
  getEnvironmentLinks,
} from "../../store/global/global.selectors";

import {
  getUsername,
  getChangePasswordStatus,
  getChangePasswordError,
} from "../../store/authentication/authentication.selector";

const mapStateToProps = (state: AppState): ConnectedState => ({
  capabilities: getCapabilities(state),
  pushNotifications: getPushNotifications(state),
  reonboardStatus: getReonboardProgress(state),
  disconnectStatus: getDisconnectProgress(state),
  reconnectStatus: getReconnectProgress(state),
  changePasswordStatus: getChangePasswordStatus(state),
  expireDays: getExpireDays(state),
  isOnboarded: getOnboardStatus(state),
  onboarding: getOnboardingStatus(state),
  username: getUsername(state),
  serverErrorMessage: getChangePasswordError(state),
  isMqtt: isMqtt(state),
  isQA: isQA(state),
  statusData: getStatusData(state),
  links: getEnvironmentLinks(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  reonboard: () => dispatch(reonboard()),
  secureOnboarding: (qa: boolean, mqtt: boolean) => dispatch(onboard({ qa, mqtt })),
  disconnect: () => dispatch(disconnect()),
  reconnect: () => dispatch(reconnect()),
  fetchEnvironments: () => dispatch(fetchEnvironments()),
  changeTraceLoggingStatus: (isTraceLoggingEnabled: boolean) =>
    dispatch(changeTraceLoggingStatus(isTraceLoggingEnabled)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsComponent);
