import { connect } from "react-redux";

import SendToEndpointsWindow, {
  ConnectedProps,
  ConnectedDispatch,
} from "./send-to-endpoints-window.component";
import { AppState } from "../../../store/app.state";
import { Dispatch } from "redux";
import { publishFiles, sendFiles } from "../../../store/endpoints/endpoints.actions";

import {
  getPublishFilesProgress,
  getReceiverEndpoints,
  getSendFilesProgress,
} from "../../../store/endpoints/endpoints.selectors";

const mapStateToProps = (state: AppState): ConnectedProps => ({
  endpoints: getReceiverEndpoints(state),
  sendFilesProgress: getSendFilesProgress(state),
  publishProgress: getPublishFilesProgress(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  sendToEndpoints: (files: string[], endpoints: string[]) => dispatch(sendFiles(files, endpoints)),
  publishFiles: (files: string[], endpoints: string[]) => dispatch(publishFiles(files, endpoints)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendToEndpointsWindow);
