import React from "react";

import PaperComponent from "../../components/paper/paper.component";
import Logging from "../../components/logging/logging.container";

const LoggingComponent = () => {
  return (
    <PaperComponent>
      <Logging full={true} />
    </PaperComponent>
  );
};

export default LoggingComponent;
