import { loadInitial, LoadingStatus } from "../loading-status";
import { IValidationMessage, validationInitial } from "../validation/validation.store";

export interface IFileDto {
  id: string;
  filename: string;
  size: string;
  type: string;
  created?: Date;
  lastmodified?: Date;
  source?: string;
  rawType?: string;
  validation?: string;
  sourceName?: string;
}
export interface DataStorageState {
  files: IFileDto[];
  loadStatus: LoadingStatus;
  reloadInProgress: boolean;
  lastUploadedValidatedFile: IValidationMessage;
  lastValidatedFile: IValidationMessage;
  fileIsUploaded: boolean;
  editableFile: {
    isEditModeActivated: boolean;
    fileId: string;
    fileName: string;
  };
  receiveAllCancelled: boolean;
  receiveTodayCancelled: boolean;
  receiveAllProgress: boolean;
  receiveTodayProgress: boolean;
  clearFeedProgress: boolean;
  validationProgress: boolean;
}

const state: DataStorageState = {
  files: [],
  loadStatus: loadInitial(),
  reloadInProgress: false,
  lastUploadedValidatedFile: validationInitial,
  lastValidatedFile: validationInitial,
  fileIsUploaded: false,
  editableFile: {
    isEditModeActivated: false,
    fileId: "",
    fileName: "",
  },
  receiveAllCancelled: false,
  receiveTodayCancelled: false,
  receiveAllProgress: false,
  receiveTodayProgress: false,
  clearFeedProgress: false,
  validationProgress: false,
};

export default state;
