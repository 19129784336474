import { Action } from "../action";
import { IValidationMessage } from "./validation.store";

export enum ValidationActionType {
  VALIDATE_ALL_FILES = "VALIDATE_ALL_FILES",
  VALIDATE_ALL_FILES_SUCCESSFUL = "VALIDATE_ALL_FILES_SUCCESSFUL",
  VALIDATE_ALL_FILES_FAILURE = "VALIDATE_ALL_FILES_FAILURE",
}

export const validateAllFiles = (): Action<null> => ({
  type: ValidationActionType.VALIDATE_ALL_FILES,
  payload: null,
});

export const validateSuccessful = (messages: IValidationMessage[]): Action<IValidationMessage[]> => ({
  type: ValidationActionType.VALIDATE_ALL_FILES_SUCCESSFUL,
  payload: messages,
});

export const validateFailure = (errorMessage: string): Action<string> => ({
  type: ValidationActionType.VALIDATE_ALL_FILES_FAILURE,
  payload: errorMessage,
});
