import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "&.MuiAccordionSummary-root": {
      padding: "0",
    },
  },
  accordionWrapper: {},
  summary: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "black",
    fontSize: "14pt",
  },
}));

export default useStyles;
