import { IValidationMessage } from "../../../store/validation/validation.store";

export enum ValidationStatusType {
  NO_ERROR = "No Errors found",
  WARNING = "Warning",
  ERROR = "Error",
  FATAL = "Fatal",
}

export const priority: { [key: string]: number } = {
  [ValidationStatusType.NO_ERROR]: 0,
  [ValidationStatusType.WARNING]: 1,
  [ValidationStatusType.ERROR]: 2,
  [ValidationStatusType.FATAL]: 3,
};

export const getPriority = (validationMessage: IValidationMessage) => {
  const priorities = validationMessage.output.reduce((acc: any, message: string) => {
    switch (true) {
      case message.includes(ValidationStatusType.NO_ERROR):
        acc.push(priority[ValidationStatusType.NO_ERROR]);
        break;
      case message.includes(ValidationStatusType.WARNING):
        acc.push(priority[ValidationStatusType.WARNING]);
        break;
      case message.includes(ValidationStatusType.ERROR):
        acc.push(priority[ValidationStatusType.ERROR]);
        break;
      case message.includes(ValidationStatusType.FATAL):
        acc.push(priority[ValidationStatusType.FATAL]);
        break;
    }
    return acc;
  }, []);

  const highestPriority = priorities.sort((a: number, b: number) => b - a)[0];

  return highestPriority;
};
