import React, { FunctionComponent, ReactNode, MouseEventHandler } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";
import { ButtonSize, IButtonSize } from "../../../shared/button-style";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    height: "30px",
    color: "white",
    fontSize: "10pt",
    borderColor: "transparent",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: Colors.DarkGreen,
    },
    "&:focus": {
      outline: "none",
      border: "none",
    },
    "&[disabled]": {
      cursor: "auto",
      backgroundColor: Colors.Grey2,
      "&:hover": {
        backgroundColor: Colors.Grey2,
      },
    },
  },
  progressWrapper: {
    marginRight: "10px",
    alignSelf: "center",
  },
  progressBar: {
    color: Colors.Grey,
  },
}));

export interface Props {
  children: ReactNode;
  handler?: MouseEventHandler;
  typeName?: any;
  className?: string;
  size?: IButtonSize;
  disabled?: boolean;
  progress?: boolean;
  testId?: string;
}

const AppButton: FunctionComponent<Props> = ({
  handler,
  size = ButtonSize.NORMAL_BUTTON,
  children,
  className,
  disabled,
  typeName,
  progress,
  testId,
}) => {
  const classes = useStyles();
  return (
    <button
      onClick={handler}
      style={progress ? { width: size.width + 30 } : size}
      className={`${className} ${classes.button}`}
      disabled={disabled}
      type={typeName}
      {...(testId ? { "data-testid": testId } : {})}
    >
      {progress && (
        <div className={classes.progressWrapper}>
          <CircularProgress className={classes.progressBar} size={20} />
        </div>
      )}
      {children}
    </button>
  );
};

export default AppButton;
