export const FileTypes = {
  ISOXML: "ISOXML",
  BITMAP_IMAGE: "BMP",
  EFDI_TIMELOG: "EFDITIMELOG",
  EFDI_DIVICE_DESCRIPTION: "EFDIDEVICEDESCRIPTION",
  SHAPE_ZIP: "SHAPE",
  JPEG_IMAGE: "JPEG",
  PNG_IMAGE: "PNG",
  AVI_VIDEO: "AVI",
  MP4_VIDEO: "MP4",
  WMV_VIDEO: "WMV",
  PDF_DOCUMENT: "PDF",
  UNKNOWN: "UNKNOWN",
  GPS: "GPS",
};

export const TitlesByType = {
  [FileTypes.ISOXML]: "ISOXML",
  [FileTypes.BITMAP_IMAGE]: "Bitmap Image",
  [FileTypes.EFDI_TIMELOG]: "EFDI Timelog",
  [FileTypes.EFDI_DIVICE_DESCRIPTION]: "EFDI Device Description",
  [FileTypes.SHAPE_ZIP]: "Shape",
  [FileTypes.JPEG_IMAGE]: "JPEG Image",
  [FileTypes.PNG_IMAGE]: "PNG Image",
  [FileTypes.AVI_VIDEO]: "AVI Video",
  [FileTypes.MP4_VIDEO]: "MP4 Video",
  [FileTypes.WMV_VIDEO]: "WMV Video",
  [FileTypes.PDF_DOCUMENT]: "PDF Document",
  [FileTypes.UNKNOWN]: "Unknown",
  [FileTypes.GPS]: "GPS Info",
};
