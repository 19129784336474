import { Action } from "../action";
import { DataStorageActionType } from "./data-storage.actions";
import createReducer from "../createReducer";
import initialState, { DataStorageState, IFileDto } from "./data-storage.store";
import { loadFailed, loadSuccess, loadPending } from "../loading-status";
import { IValidationMessage } from "../validation/validation.store";

export default createReducer(initialState, {
  [DataStorageActionType.SET_FILES](state: DataStorageState, action: Action<[]>): DataStorageState {
    return {
      ...state,
      files: action.payload,
    };
  },
  [DataStorageActionType.DOWNLOAD_FILE](state: DataStorageState, _action: Action<any>): DataStorageState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.DOWNLOAD_FILE_SUCCESS](
    state: DataStorageState,
    _action: Action<any>
  ): DataStorageState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.DOWNLOAD_FILE_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.DELETE_FILE](state: DataStorageState, action: Action<any>): DataStorageState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.DELETE_FILE_SUCCESS](
    state: DataStorageState,
    action: Action<string[]>
  ): DataStorageState {
    const fileIds = action.payload;
    return {
      ...state,
      files: state.files.filter((item: IFileDto) => !fileIds.includes(item.id)),
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.DELETE_FILE_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.UPLOAD_FILE](state: DataStorageState, action: Action<any>): DataStorageState {
    return {
      ...state,
      fileIsUploaded: false,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.UPLOAD_FILE_SUCCESS](
    state: DataStorageState,
    action: Action<IFileDto>
  ): DataStorageState {
    return {
      ...state,
      files: [...state.files, action.payload],
      fileIsUploaded: true,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.UPLOAD_FILE_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      fileIsUploaded: false,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.RELOAD_FILES](state: DataStorageState): DataStorageState {
    return {
      ...state,
      reloadInProgress: true,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.RELOAD_FILES_SUCCESS](
    state: DataStorageState,
    action: Action<IFileDto[]>
  ): DataStorageState {
    const files = action.payload;
    return {
      ...state,
      files,
      reloadInProgress: false,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.RELOAD_FILES_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      reloadInProgress: false,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.VALIDATE_FILE](state: DataStorageState): DataStorageState {
    return {
      ...state,
      validationProgress: true,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.VALIDATE_FILE_SUCCESS](
    state: DataStorageState,
    action: Action<IValidationMessage>
  ): DataStorageState {
    return {
      ...state,
      lastValidatedFile: action.payload,
      validationProgress: false,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.VALIDATE_FILE_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      validationProgress: false,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.VALIDATE_UPLOADED_FILE](state: DataStorageState): DataStorageState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.VALIDATE_UPLOADED_FILE_SUCCESS](
    state: DataStorageState,
    action: Action<IValidationMessage>
  ): DataStorageState {
    return {
      ...state,
      lastUploadedValidatedFile: action.payload,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.VALIDATE_UPLOADED_FILE_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.TOGGLE_EDIT_MODE](state: DataStorageState, action: Action<any>): DataStorageState {
    const { isEditModeActivated, fileId, fileName } = action.payload;
    return {
      ...state,
      editableFile: { isEditModeActivated, fileId, fileName },
    };
  },
  [DataStorageActionType.CHANGE_FILE_NAME](state: DataStorageState): DataStorageState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.CHANGE_FILE_NAME_SUCCESS](state: DataStorageState): DataStorageState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.CHANGE_FILE_NAME_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.CHANGE_FILE_TYPE](state: DataStorageState): DataStorageState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.CHANGE_FILE_TYPE_SUCCESS](state: DataStorageState): DataStorageState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.CHANGE_FILE_TYPE_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.RECEIVE_ALL](state: DataStorageState): DataStorageState {
    return {
      ...state,
      receiveAllProgress: true,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.RECEIVE_ALL_SUCCESS](state: DataStorageState): DataStorageState {
    return {
      ...state,
      receiveAllProgress: false,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.RECEIVE_ALL_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      receiveAllProgress: false,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.SET_RECEIVE_ALL_CANCELLED](
    state: DataStorageState,
    { payload }: Action<boolean>
  ): DataStorageState {
    return {
      ...state,
      receiveAllCancelled: payload,
    };
  },
  [DataStorageActionType.RECEIVE_FROM_TODAY](state: DataStorageState): DataStorageState {
    return {
      ...state,
      receiveTodayProgress: true,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.RECEIVE_FROM_TODAY_SUCCESS](state: DataStorageState): DataStorageState {
    return {
      ...state,
      receiveTodayProgress: false,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.RECEIVE_FROM_TODAY_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      receiveTodayProgress: false,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.SET_RECEIVE_FROM_TODAY_CANCELLED](
    state: DataStorageState,
    { payload }: Action<boolean>
  ): DataStorageState {
    return {
      ...state,
      receiveTodayCancelled: payload,
    };
  },
  [DataStorageActionType.RECEIVE_ALL_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      receiveAllProgress: false,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.CLEAR_FEED](state: DataStorageState): DataStorageState {
    return {
      ...state,
      clearFeedProgress: true,
      loadStatus: loadPending(),
    };
  },
  [DataStorageActionType.CLEAR_FEED_SUCCESS](state: DataStorageState): DataStorageState {
    return {
      ...state,
      clearFeedProgress: false,
      loadStatus: loadSuccess(),
    };
  },
  [DataStorageActionType.CLEAR_FEED_FAILURE](
    state: DataStorageState,
    action: Action<string>
  ): DataStorageState {
    return {
      ...state,
      clearFeedProgress: false,
      loadStatus: loadFailed(action.payload),
    };
  },
  [DataStorageActionType.UPDATE_FILE_LIST](state: DataStorageState): DataStorageState {
    return {
      ...state,
      loadStatus: loadPending(),
    };
  },
});
