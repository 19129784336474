import { Action } from "../action";

export enum CommonActionType {
  TOGGLE_UPLOAD_MODAL_WINDOW = "TOGGLE_UPLOAD_MODAL_WINDOW",
  TOGGLE_VALIDATION_MODAL_WINDOW = "TOGGLE_VALIDATION_MODAL_WINDOW",
}

export const toggleUploadModalWindow = (isOpen: boolean): Action<boolean> => ({
  type: CommonActionType.TOGGLE_UPLOAD_MODAL_WINDOW,
  payload: isOpen,
});

export const toggleValidationModalWindow = (isValidModalOpen: boolean): Action<boolean> => ({
  type: CommonActionType.TOGGLE_VALIDATION_MODAL_WINDOW,
  payload: isValidModalOpen,
});
