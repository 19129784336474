/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState, useCallback } from "react";

import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { Stop as StopIcon } from "@material-ui/icons";

import { Device, Progress, SimulatorFile } from "../../store/simulation/simulation.store";
import Preloader from "../../components/preloader/preloader.component";
import SimulatorConfiguration from "./simulator-configuration.container";
import useStyles from "./simulation.styles";
import { SimulationSlider } from "./slider.component";
import { ConfigurationParams } from "./simulator-configuration.component";
import AppButton from "../../components/common/button/button.component";
import { ButtonSize } from "../../shared/button-style";
import PageWithLogging from "../../components/page/page.component";
import { Typography } from "@material-ui/core";
import DeviceInfoWindow from "../../components/modal-windows/device-info-window/device-info-window.component";
import { useLocation } from "react-router";

export interface ExportParams {
  timelog?: string;
  skip?: string;
  replaceTime?: string;
}

export interface ConnectedState {
  files: SimulatorFile[];
  filesByType: { [key: string]: SimulatorFile[] };
  getFilesStatus: boolean;
  isSimulationInProgress: boolean;
  progress: Progress | null;
  configuration: ConfigurationParams;
  exportProgress: boolean;
  devices: Device[];
}

export interface ConnectedDispatch {
  getFiles: () => any;
  startSimulation: (data: any) => void;
  stopSimulation: () => void;
  setConfiguration: (data: any) => void;
  reset: () => any;
  resume: () => any;
  pause: () => any;
  next: () => any;
  exportEfdi: (id: string, params: ExportParams, filename: string) => any;
}

interface SimulationProps extends ConnectedDispatch, ConnectedState {}

const SimulationComponent: FunctionComponent<SimulationProps> = (props: SimulationProps) => {
  const classes = useStyles();
  const { search } = useLocation();
  const {
    files,
    filesByType,
    getFilesStatus,
    isSimulationInProgress,
    progress,
    exportProgress,
    devices,
    setConfiguration,
    configuration,
  } = props;
  const [showDevices, setShowDevices] = useState<boolean>(false);
  const [activeDeviceIndex, setActiveDeviceIndex] = useState<number | null>(null);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  useEffect(() => {
    props.getFiles();
  }, []);

  useEffect(() => {
    switch (progress?.state) {
      case "running": {
        setIsPaused(false);
        setIsDone(false);
        break;
      }
      case "done":
        setIsDone(!progress.following);
        break;
      case "paused":
        setIsPaused(true);
        break;
    }
  }, [progress?.state]);

  useEffect(() => {
    setShowDevices(!!configuration.file);
  }, [configuration.file]);

  const toggleStartStop = () => {
    setIsDone(false);

    if (isSimulationInProgress) {
      setIsPaused(false);
      onDone();
    } else {
      props.startSimulation({
        ...configuration,
        timelog: configuration.timelog === "all" ? null : configuration.timelog,
      });
    }
  };

  const onDone = () => {
    setIsDone(false);
    props.stopSimulation();
    props.reset();
  };

  useEffect(() => {
    if (isDone) {
      onDone();
    }
  }, [isDone]);

  const onExport = () => {
    const { file, timelog, skipInitial: skip, replaceTime } = configuration;
    const { filename = "" } = files.find(({ id }) => id === file) || {};

    props.exportEfdi(
      file,
      {
        timelog: timelog === "all" ? "" : timelog || "",
        skip: `${skip ?? ""}`,
        replaceTime: replaceTime || "",
      },
      filename
    );
  };

  const closeModal = useCallback(() => setActiveDeviceIndex(null), []);

  return getFilesStatus ? (
    <Preloader />
  ) : (
    <PageWithLogging isCollapsible={true}>
      <SimulatorConfiguration
        files={filesByType}
        isSimulationInProgress={isSimulationInProgress}
        onUpdateConfiguration={(configs) => setConfiguration(configs)}
        configuration={{ ...configuration, file: search.split("?id=")[1] ?? configuration.file }}
        stopSimulation={props.stopSimulation}
      />
      <div className={classes.simulateControls}>
        <AppButton
          size={ButtonSize.TINY_BUTTON}
          disabled={!(configuration.file && configuration.timelog)}
          handler={onExport}
          className={classes.startButton}
          progress={exportProgress}
          testId="simulation-export"
        >
          Export
        </AppButton>
      </div>
      {showDevices &&
        devices.map(({ device, elements }, index) => (
          <div className={classes.deviceInfo} key={device}>
            <Typography>Device: {device}</Typography>
            {elements.length > 0 && (
              <AppButton size={ButtonSize.TINY_BUTTON} handler={() => setActiveDeviceIndex(index)}>
                Show DDI
              </AppButton>
            )}
          </div>
        ))}
      <SimulationSlider progress={progress} isSimulationInProgress={isSimulationInProgress} />
      <div className={classes.simulateControls}>
        {!isDone && (
          <>
            <AppButton
              size={ButtonSize.TINY_BUTTON}
              disabled={!isSimulationInProgress}
              handler={isPaused ? props.resume : props.pause}
              className={
                isSimulationInProgress
                  ? isPaused
                    ? `${classes.pauseButton} ${classes.resumeStarted}`
                    : `${classes.pauseButton} ${classes.pauseStarted}`
                  : classes.pauseButton
              }
              testId="simulation-pause-resume"
            >
              {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
            </AppButton>
            {progress?.following && (
              <AppButton
                size={ButtonSize.SMALL_BUTTON}
                disabled={!isSimulationInProgress}
                handler={props.next}
                className={
                  isSimulationInProgress ? `${classes.nextButton} ${classes.nextStarted}` : classes.nextButton
                }
                testId="simulation-next"
              >
                <div>
                  <FastForwardIcon />
                </div>
                {progress.following}
              </AppButton>
            )}
          </>
        )}
        <AppButton
          size={ButtonSize.TINY_BUTTON}
          disabled={!(configuration.file && configuration.timelog)}
          handler={toggleStartStop}
          className={
            isSimulationInProgress && !isDone
              ? `${classes.startButton} ${classes.startStarted}`
              : classes.startButton
          }
          testId="simulation-start-stop"
        >
          {isSimulationInProgress && !isDone ? <StopIcon /> : <PlayArrowIcon />}
        </AppButton>
      </div>
      {activeDeviceIndex !== null && (
        <DeviceInfoWindow
          open={activeDeviceIndex !== null}
          closeModal={closeModal}
          elements={devices[activeDeviceIndex].elements}
          device={devices[activeDeviceIndex].device}
        />
      )}
    </PageWithLogging>
  );
};

export default SimulationComponent;
