import { connect } from "react-redux";
import ReceiveControllBar, { ConnectedState, ConnectedDispatch } from "./receive-controll-bar.component";

import {
  receiveAllFiles,
  receiveFromToday,
  clearFeed,
  getReceiveAllFileProgress,
  getReceiveTodayProgress,
  getClearFeedProgress,
  setReceiveAllCancelled,
  setReceiveFromTodayCancelled,
} from "../../store/data-storage";

import { AppState } from "../../store/app.state";
import { Dispatch } from "redux";

const mapStateToProps = (state: AppState): ConnectedState => ({
  receiveAllProgress: getReceiveAllFileProgress(state),
  receiveTodayProgress: getReceiveTodayProgress(state),
  clearFeedProgress: getClearFeedProgress(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  receiveAllFiles: () => dispatch(receiveAllFiles()),
  receiveFromToday: () => dispatch(receiveFromToday()),
  clearFeed: () => dispatch(clearFeed()),
  cancelReceiveAll: () => dispatch(setReceiveAllCancelled(true)),
  cancelReceiveFromToday: () => dispatch(setReceiveFromTodayCancelled(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveControllBar);
