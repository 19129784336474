import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";

import { makeStyles, TableRow, TableCell, Checkbox } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import { Info as InfoIcon, CheckCircle } from "@material-ui/icons";

import TableComponent from "../../components/common/table/table.component";
import { Endpoint } from "../../store/endpoints/endpoints.store";
import { Colors } from "../../theme/colors";
import { EndpointsSections } from "./endpoints.component";

interface Props {
  section: EndpointsSections;
  endpoints: Endpoint[];
  selected: Endpoint[];
  toggleSelection: (id: string) => any;
}

const useStyles = makeStyles(() =>
  createStyles({
    editButton: {
      width: 30,
      height: 30,
      padding: 3,
      borderRadius: 0,
      color: Colors.Grey,
      backgroundColor: "transparent",
      border: "none",
      "&:hover": {
        backgroundColor: "transparent",
        color: Colors.DarkGrey,
      },
      "&:active, &:focus": {
        backgroundColor: Colors.LightGrey,
      },
    },
    activeCell: {
      color: Colors.Green2,
      padding: 3,
    },
  })
);

const EndpointsTable: FunctionComponent<Props> = ({
  section,
  endpoints = [],
  selected = [],
  ...props
}: Props) => {
  const classes = useStyles();
  const isReceiver = section === "filtered";

  return (
    <TableComponent
      tableHead={
        <TableRow>
          {isReceiver && <TableCell />}
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell align="center">Actions</TableCell>
          <TableCell>Active</TableCell>
        </TableRow>
      }
      empty={endpoints.length === 0}
      tableBody={endpoints.map(({ id, name, active, type }) => {
        const isSelected = selected.findIndex(({ id: selectedId }) => id === selectedId) !== -1;

        return (
          <TableRow
            key={id}
            {...(isReceiver
              ? {
                  hover: true,
                  role: "checkbox",
                  onClick: () => props.toggleSelection(id),
                  selected: isSelected,
                  "aria-checked": isSelected,
                }
              : {})}
          >
            {isReceiver && (
              <TableCell padding="checkbox">
                <Checkbox inputProps={{ "aria-labelledby": id }} checked={isSelected} />
              </TableCell>
            )}
            <TableCell component="th" scope="row">
              {name}
            </TableCell>
            <TableCell>{type}</TableCell>
            <TableCell align="center">
              <NavLink to={`/endpoints/${id}`} className={classes.editButton}>
                <InfoIcon />
              </NavLink>
            </TableCell>
            <TableCell align="center" className={classes.activeCell}>
              {active && <CheckCircle />}
            </TableCell>
          </TableRow>
        );
      })}
    />
  );
};

export default EndpointsTable;
