import { transformer } from "../../utils/files-transformer";
import { Action } from "../action";
import { IValidationMessage } from "../validation/validation.store";
import { IFileDto } from "./data-storage.store";

export enum DataStorageActionType {
  SET_FILES = "SET_FILES",

  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS",
  DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE",

  UPLOAD_FILE = "UPLOAD_FILE",
  UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE",

  DELETE_FILE = "DELETE_FILE",
  DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS",
  DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE",

  RELOAD_FILES = "RELOAD_FILES",
  RELOAD_FILES_SUCCESS = "RELOAD_FILES_SUCCESS",
  RELOAD_FILES_FAILURE = "RELOAD_FILES_FAILURE",

  VALIDATE_FILE = "VALIDATE_FILE",
  VALIDATE_FILE_SUCCESS = "VALIDATE_FILE_SUCCESS",
  VALIDATE_FILE_FAILURE = "VALIDATE_FILE_FAILURE",

  VALIDATE_UPLOADED_FILE = "VALIDATE_UPLOADED_FILE",
  VALIDATE_UPLOADED_FILE_SUCCESS = "VALIDATE_UPLOADED_FILE_SUCCESS",
  VALIDATE_UPLOADED_FILE_FAILURE = "VALIDATE_UPLOADED_FILE_FAILURE",

  TOGGLE_EDIT_MODE = "TOGGLE_EDIT_MODE",

  CHANGE_FILE_NAME = "CHANGE_FILE_NAME",
  CHANGE_FILE_NAME_SUCCESS = "CHANGE_FILE_NAME_SUCCESS",
  CHANGE_FILE_NAME_FAILURE = "CHANGE_FILE_NAME_FAILURE",

  CHANGE_FILE_TYPE = "CHANGE_FILE_TYPE",
  CHANGE_FILE_TYPE_SUCCESS = "CHANGE_FILE_TYPE_SUCCESS",
  CHANGE_FILE_TYPE_FAILURE = "CHANGE_FILE_TYPE_FAILURE",

  RECEIVE_ALL = "RECEIVE_ALL",
  RECEIVE_ALL_SUCCESS = "RECEIVE_ALL_SUCCESS",
  RECEIVE_ALL_FAILURE = "RECEIVE_ALL_FAILURE",
  SET_RECEIVE_ALL_CANCELLED = "SET_RECEIVE_ALL_CANCELLED",

  RECEIVE_FROM_TODAY = "RECEIVE_FROM_TODAY",
  RECEIVE_FROM_TODAY_SUCCESS = "RECEIVE_FROM_TODAY_SUCCESS",
  RECEIVE_FROM_TODAY_FAILURE = "RECEIVE_FROM_TODAY_FAILURE",
  SET_RECEIVE_FROM_TODAY_CANCELLED = "SET_RECEIVE_FROM_TODAY_CANCELLED",

  CLEAR_FEED = "CLEAR_FEED",
  CLEAR_FEED_SUCCESS = "CLEAR_FEED_SUCCESS",
  CLEAR_FEED_FAILURE = "CLEAR_FEED_FAILURE",

  UPDATE_FILE_LIST = "UPDATE_FILE_LIST",
  UPDATE_FILE_LIST_SUCCESS = "UPDATE_FILE_LIST_SUCCESS",
  UPDATE_FILE_LIST_FAILURE = "UPDATE_FILE_LIST_FAILURE",
}

export const setFiles = (files: IFileDto[]): Action<any> => ({
  type: DataStorageActionType.SET_FILES,
  payload: files.map(transformer),
});

export const downloadFile = (id: string, fileName: string): Action<any> => ({
  type: DataStorageActionType.DOWNLOAD_FILE,
  payload: { id, fileName },
});

export const downloadFileSuccess = () => ({
  type: DataStorageActionType.DOWNLOAD_FILE_SUCCESS,
  payload: null,
});

export const downloadFileFailure = (errorMessage: string): Action<string> => ({
  type: DataStorageActionType.DOWNLOAD_FILE_FAILURE,
  payload: errorMessage,
});

export const deleteFile = (ids: string[]): Action<string[]> => ({
  type: DataStorageActionType.DELETE_FILE,
  payload: ids,
});

export const deleteFileSuccess = (ids: string[]): Action<string[]> => ({
  type: DataStorageActionType.DELETE_FILE_SUCCESS,
  payload: ids,
});

export const deleteFileFailure = (errorMessage: string): Action<string> => ({
  type: DataStorageActionType.DELETE_FILE_FAILURE,
  payload: errorMessage,
});

export const uploadFile = (file: any): Action<any> => ({
  type: DataStorageActionType.UPLOAD_FILE,
  payload: file,
});

export const uploadFileSuccess = (file: IFileDto) => ({
  type: DataStorageActionType.UPLOAD_FILE_SUCCESS,
  payload: file,
});

export const uploadFileFailure = (errorMessage: string): Action<string> => ({
  type: DataStorageActionType.UPLOAD_FILE_FAILURE,
  payload: errorMessage,
});

export const reloadFiles = () => ({
  type: DataStorageActionType.RELOAD_FILES,
  payload: null,
});

export const reloadFilesSuccess = (reloadedFiles: IFileDto[]) => ({
  type: DataStorageActionType.RELOAD_FILES_SUCCESS,
  payload: reloadedFiles.map(transformer),
});

export const reloadFilesFailure = (errorMessage: string): Action<string> => ({
  type: DataStorageActionType.RELOAD_FILES_FAILURE,
  payload: errorMessage,
});

export const validateFile = (id: string) => ({
  type: DataStorageActionType.VALIDATE_FILE,
  payload: id,
});

export const validateFileSuccess = (validationLog: IValidationMessage) => ({
  type: DataStorageActionType.VALIDATE_FILE_SUCCESS,
  payload: validationLog,
});

export const validateFileFailure = (errorMessage: string) => ({
  type: DataStorageActionType.VALIDATE_FILE_FAILURE,
  payload: errorMessage,
});

export const validateUploadedFile = () => ({
  type: DataStorageActionType.VALIDATE_UPLOADED_FILE,
  payload: null,
});

export const validateUploadedFileSuccess = (validationLog: IValidationMessage) => ({
  type: DataStorageActionType.VALIDATE_UPLOADED_FILE_SUCCESS,
  payload: validationLog,
});

export const validateUploadedFileFailure = (errorMessage: string) => ({
  type: DataStorageActionType.VALIDATE_UPLOADED_FILE_FAILURE,
  payload: errorMessage,
});

export const toggleEditMode = (
  isEditModeActivated: boolean,
  fileId: string,
  fileName: string
): Action<any> => ({
  type: DataStorageActionType.TOGGLE_EDIT_MODE,
  payload: { isEditModeActivated, fileId, fileName },
});

export const changeFileName = (fileName: string, fileId: string) => ({
  type: DataStorageActionType.CHANGE_FILE_NAME,
  payload: { fileName, fileId },
});

export const changeFileNameSuccessful = () => ({
  type: DataStorageActionType.CHANGE_FILE_NAME_SUCCESS,
  payload: null,
});

export const changeFileNameFailure = (errorMessage: string) => ({
  type: DataStorageActionType.CHANGE_FILE_NAME_FAILURE,
  payload: errorMessage,
});

export const changeFileType = (fileId: string, fileType: string) => ({
  type: DataStorageActionType.CHANGE_FILE_TYPE,
  payload: { fileType, fileId },
});

export const changeFileTypeSuccessful = () => ({
  type: DataStorageActionType.CHANGE_FILE_TYPE_SUCCESS,
  payload: null,
});

export const changeFileTypeFailure = (errorMessage: string) => ({
  type: DataStorageActionType.CHANGE_FILE_TYPE_FAILURE,
  payload: errorMessage,
});

export const receiveAllFiles = () => ({
  type: DataStorageActionType.RECEIVE_ALL,
  payload: null,
});

export const receiveAllFilesSuccess = () => ({
  type: DataStorageActionType.RECEIVE_ALL_SUCCESS,
  payload: null,
});

export const receiveAllFilesFailure = (errorMessage: string) => ({
  type: DataStorageActionType.RECEIVE_ALL_FAILURE,
  payload: errorMessage,
});

export const setReceiveAllCancelled = (cancelled: boolean): Action<boolean> => ({
  type: DataStorageActionType.SET_RECEIVE_ALL_CANCELLED,
  payload: cancelled,
});

export const receiveFromToday = () => ({
  type: DataStorageActionType.RECEIVE_FROM_TODAY,
  payload: null,
});

export const receiveFromTodaySuccess = () => ({
  type: DataStorageActionType.RECEIVE_FROM_TODAY_SUCCESS,
  payload: null,
});

export const receiveFromTodayFailure = (errorMessage: string) => ({
  type: DataStorageActionType.RECEIVE_FROM_TODAY_FAILURE,
  payload: errorMessage,
});

export const setReceiveFromTodayCancelled = (cancelled: boolean): Action<boolean> => ({
  type: DataStorageActionType.SET_RECEIVE_FROM_TODAY_CANCELLED,
  payload: cancelled,
});

export const clearFeed = () => ({
  type: DataStorageActionType.CLEAR_FEED,
  payload: null,
});

export const clearFeedSuccess = () => ({
  type: DataStorageActionType.CLEAR_FEED_SUCCESS,
  payload: null,
});

export const clearFeedFailure = (errorMessage: string) => ({
  type: DataStorageActionType.CLEAR_FEED_FAILURE,
  payload: errorMessage,
});

export const updateFileList = () => ({
  type: DataStorageActionType.UPDATE_FILE_LIST,
  payload: null,
});
