import { connect } from "react-redux";

import MapComponent, { ConnectedState } from "./map.component";
import { AppState } from "../../store/app.state";
import { getGeoJSON, getTelemetryData } from "../../store/telemetry/telemetry.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  geoJsonData: getGeoJSON(state),
  telemetry: getTelemetryData(state),
});

export default connect(mapStateToProps, null)(MapComponent);
