import React, { FunctionComponent, useCallback } from "react";

import useStyles from "../upload-window/upload-window.styles";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

import TransitionsModal from "../../common/modal-winidow/modal-window.component";
import { ButtonSize } from "../../../shared/button-style";
import AppButton from "../../common/button/button.component";
import { MessageField } from "../../common/message-field/message-field.component";
import { IValidationMessage } from "../../../store/validation/validation.store";
import { getPriority } from "./validation.helper";

export interface ConnectedProps {
  lastValidatedFileLog: IValidationMessage;
}
interface Props extends ConnectedProps {
  open: boolean;
  closeModal: () => void;
}

const ValidationModalWindow: FunctionComponent<Props> = ({ closeModal, open, lastValidatedFileLog }) => {
  const classes = useStyles();

  const renderValidationIcon = useCallback(() => {
    const priority = getPriority(lastValidatedFileLog);

    switch (priority) {
      case 0: {
        return <CheckCircleIcon className={classes.checkIcon} />;
      }
      case 1: {
        return <ReportProblemIcon className={classes.warningIcon} />;
      }
      case 2: {
        return <ReportProblemIcon className={classes.errorIcon} />;
      }
      case 3: {
        return <ErrorIcon className={classes.fatalIcon} />;
      }
    }
  }, [classes, lastValidatedFileLog]);

  return (
    <TransitionsModal closeModal={closeModal} open={open} size="870px">
      <div className={classes.flexWrapper}>
        <div className={classes.header}>
          <Typography className={classes.title}>Validate</Typography>
          <div className={classes.statusIcon}>{renderValidationIcon()}</div>
        </div>

        <div>
          <Typography className={classes.contactDetails}>{lastValidatedFileLog.filename}</Typography>
        </div>
        <MessageField message={lastValidatedFileLog.output.join("\n") || ""} />
        <div className={classes.buttonWrapper}>
          <AppButton size={ButtonSize.TINY_BUTTON} handler={closeModal}>
            OK
          </AppButton>
        </div>
      </div>
    </TransitionsModal>
  );
};

export default ValidationModalWindow;
