import React from "react";
import { Switch } from "react-router";
import PrivateRoute from "./private-route";
import { sidebarRoutes, additionalRoutes } from "./routes";

export default (isLoggedIn: boolean) => {
  return (
    <Switch>
      {additionalRoutes.map((route) => (
        <PrivateRoute
          key={route.name}
          path={route.path}
          component={route.component}
          isLoggedIn={isLoggedIn}
        />
      ))}
      {sidebarRoutes.map((route) => (
        <PrivateRoute
          key={route.name}
          path={route.path}
          component={route.component}
          isLoggedIn={isLoggedIn}
        />
      ))}
    </Switch>
  );
};
