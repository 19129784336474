import { connect } from "react-redux";

import FilesTableComponent, { ConnectedDispatch, ConnectedState } from "./files-table.component";
import { Dispatch } from "redux";
import {
  changeFileName,
  downloadFile,
  getEditableFile,
  getValidationProgress,
  toggleEditMode,
  validateFile,
} from "../../store/data-storage";
import { AppState } from "../../store/app.state";
import { toggleValidationModalWindow } from "../../store/common/common.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  editableFile: getEditableFile(state),
  validationProgress: getValidationProgress(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  downloadFile: (id: string, fileName: string) => dispatch(downloadFile(id, fileName)),
  toggleEditMode: (isActivated: boolean, fileId: string, fileName: string) =>
    dispatch(toggleEditMode(isActivated, fileId, fileName)),
  changeFileName: (fileName: string, fileId: string) => dispatch(changeFileName(fileName, fileId)),
  validateFile: (id: string) => dispatch(validateFile(id)),
  toggleValidationModalWindow: (isValidModalOpen: boolean) =>
    dispatch(toggleValidationModalWindow(isValidModalOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesTableComponent);
