import axios, { AxiosError, AxiosInstance } from "axios";

import { backendUrl } from "./url-builder";

import { BackendApi, Capability, IStartSimulate, Subscription } from "./backend-api";
import { ExportParams } from "../pages/simulation/simulation.component";
import { Dispatch } from "redux";
import { responseHandler, requestHandler } from "./backend-exeption-handler";

export default class BackendClientV2 {
  static backendClient: BackendClientV2;

  static getInstance(dispatch?: Dispatch): BackendClientV2 {
    if (BackendClientV2.backendClient == null) {
      BackendClientV2.backendClient = new BackendClientV2(dispatch);
    }

    return this.backendClient;
  }

  private api: BackendApi;

  constructor(private dispatch?: Dispatch) {
    const axiosInstance = this.createAxiosInstance();
    this.api = new BackendApi(backendUrl, axiosInstance);
  }

  setToken = (token: string): void => {
    const axiosInstance: AxiosInstance = this.createAxiosInstance(`Bearer ${token}`);

    this.api = new BackendApi("", axiosInstance);
  };

  getUrl = async () => await this.api.getUrl();

  auth = async (state: string, code: string) => await this.api.auth(state, code);

  updateToken = async (refreshToken: string) => await this.api.updateToken(refreshToken);

  logout = async (idToken: string) => await this.api.logout(idToken);

  fetchLogs = async () => await this.api.getUserLogsEntries();

  fetchLoggingStatus = async () => await this.api.fetchLoggingStatus();

  fetchTraceLoggingStatus = async () => await this.api.fetchTraceLoggingStatus();

  changeLoggingStatus = async (enable: boolean) => await this.api.changeLoggingStatus(enable);

  changeTraceLoggingStatus = async (enable: boolean) => await this.api.changeTraceLoggingStatus(enable);

  downloadFile = async (id: string) => await this.api.downloadFile(id);

  renameFile = async (fileId: string, newName: string) => await this.api.renameFile(fileId, newName);

  changeFileType = async (fileId: string, newType: string) => await this.api.changeFileType(fileId, newType);

  downloadIsoxml = async (id: string) => await this.api.downloadIsoxml(id);

  getFileList = async () => await this.api.getFileList();

  exportEfdi = async (id: string, params: ExportParams) => await this.api.exportEfdi(id, params);

  uploadFile = async (file: any) => await this.api.uploadFile(file);

  deleteFiles = async (fileIds: string[]) => await this.api.deleteFiles(fileIds);

  validate = async (ids: string[]) =>
    await axios.all(
      ids.map((id: string) => {
        return this.api.validate(id);
      })
    );

  updateTelemetry = async (fileId: string, offset: number, limit?: number) =>
    await this.api.telemetry(fileId, offset, limit);

  justifyOnboarding = async ({ pathname, search }: Location) =>
    await this.api.justifyOnboard(pathname, search);

  clearLogs = async () => this.api.clearLogs();

  receiveFiles = async (recentOnly: boolean) => this.api.receiveFiles(recentOnly);

  clearFeed = async () => this.api.clearFeed();

  sendFiles = async (fileIds: string[], targets: string[]) => await this.api.sendFiles(fileIds, targets);

  publishFiles = async (fileIds: string[], targets: string[]) =>
    await this.api.publishFiles(fileIds, targets);

  setCapabilities = async (capabilities: Capability[], pushNotifications: number) =>
    await this.api.setCapabilities(capabilities, pushNotifications);

  getCapabilities = async () => await this.api.getCapabilities();

  getEndpoints = async () => await this.api.getEndpoints();

  updateEndpoints = async () => await this.api.updateEndpoints();

  getOnboardStatus = async () => await this.api.getOnboardStatus();

  onboard = async () => await this.api.onboard();

  getOnboardUrl = async (isMqtt: boolean, isQa: boolean) => await this.api.getOnboardUrl(isMqtt, isQa);

  getReonboardUrl = async () => await this.api.getReonboardUrl();

  reconnect = async () => await this.api.reconnect();

  offboard = async () => await this.api.offboard();

  setSubscriptions = async (subscriptions: Subscription[]) => await this.api.setSubscriptions(subscriptions);

  getSubscriptions = async () => await this.api.getSubscriptions();

  getListFormats = async () => await this.api.getListFormats();

  getSimulationProgress = async () => await this.api.getSimulationProgress();

  getSimulatorFileList = async () => await this.api.getSimulatorFileList();

  getTimeLogInfo = async (fileId: string) => await this.api.getTimeLogInfo(fileId);

  simulate = async (data: IStartSimulate) => await this.api.simulate(data);

  pause = async () => await this.api.pause();

  resume = async () => await this.api.resume();

  stop = async () => await this.api.stop();

  forward = async () => await this.api.forward();

  getEnvironment = async () => await this.api.getEnvironment();

  private createAxiosInstance(token?: string) {
    const axiosInstance = token ? axios.create({ headers: { authorization: token } }) : axios.create();

    axiosInstance.interceptors.request.use(
      (request: any) => {
        requestHandler(request, this.dispatch);
        return request;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        responseHandler(error, this.dispatch);
        return Promise.reject(error);
      }
    );
    return axiosInstance;
  }
}
