export interface IValidationMessage {
  filename: string;
  output: string[];
  id: string;
}

export const validationInitial = {
  filename: "",
  output: [""],
  id: "",
};

export interface ValidationState {
  validationLogs: IValidationMessage[];
  validationInProgress: boolean | undefined;
}

export const state: ValidationState = {
  validationLogs: [],
  validationInProgress: undefined,
};

export default state;
