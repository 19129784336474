import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "../../theme/colors";

// import { Colors } from "../../theme/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logging: {
      paddingTop: "50px",
    },
    controlColumn: {
      paddingRight: "100px",
    },
    controlRow: {
      display: "flex",
      alignItems: "center",
    },
    configurationLabel: {
      width: "150px",
    },
    configurationControl: {
      width: "300px",
    },
    simulateControls: {
      display: "flex",
      justifyContent: "flex-end",
    },
    deviceInfo: {
      display: "flex",
      alignItems: "center",
    },
    pauseStarted: {
      backgroundColor: Colors.Yellow,
      "&:hover": {
        backgroundColor: Colors.DarkYellow,
      },
    },
    nextStarted: {
      backgroundColor: Colors.Orange,
      "&:hover": {
        backgroundColor: Colors.DarkOrange,
      },
    },
    startStarted: {
      backgroundColor: Colors.Red,
      "&:hover": {
        backgroundColor: Colors.Red2,
      },
    },
    resumeStarted: {
      backgroundColor: Colors.Green,
      "&:hover": {
        backgroundColor: Colors.Green2,
      },
    },
    sharedStyle: {
      display: "flex",
      alignItems: "center",
    },
    disabled: {
      color: Colors.Grey,
    },
    sliderWrapper: {
      marginTop: "30px",
      marginBottom: "30px",
    },
    sliderInfoSection: {
      display: "flex",
      justifyContent: "space-between",
    },
    slider: {
      marginTop: "26px",
    },
    duration: {
      display: "flex",
      justifyContent: "space-between",
    },
    intervalInfo: {
      display: "flex",
      flexDirection: "column",
    },
    otherInfo: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "10px",
    },
    timelogTitle: {
      fontSize: "15px",
    },
    size: {
      fontSize: "15px",
    },
    checkBtn: { padding: 9 },
    startSimulation: {},
    endSimulation: {},
    pauseButton: {},
    startButton: {},
    nextButton: {},
  })
);

export default useStyles;
