import { APIError, Capability, LogEntry, Subscription } from "../../api/backend-api";

export interface GlobalState {
  fetchDataInProgress: boolean;
  saveCapabilitiesInProgress: boolean;
  saveSubscriptionsInProgress: boolean;
  reloadCapabilitiesInProgress: boolean;
  reloadSubscriptionsInProgress: boolean;
  onboardInProgress: boolean;
  reconnectInProgress: boolean;
  disconnectInProgress: boolean;
  clearLogsInProgress: boolean;

  changeLoggingStatusProgress: boolean;
  fetchLoggingStatusProgress: boolean;
  isLoggingEnabled: boolean;

  changeTraceLoggingStatusProgress: boolean;
  fetchTraceLoggingStatusProgress: boolean;
  traceLoggingEnabledTill: number;

  inited: boolean;

  dataIsFetched: boolean;
  onboardingIsJustified: boolean;
  lastError: APIError | null;

  capabilities: Capability[];
  subscriptions: Subscription[];
  logs: LogEntry[];
  earliestLogEntryTime: number;
  latestLogEntryTime: number;
  pushNotifications: number;

  expireDays: null | number;
  onboarded: boolean;
  onboarding: boolean;
  qa: boolean;
  mqtt: boolean;
  pendingEndpoints: boolean;
  accountId: string | undefined;
  endpointId: string | undefined;

  links: any;
}

const state: GlobalState = {
  fetchDataInProgress: false,
  saveCapabilitiesInProgress: false,
  saveSubscriptionsInProgress: false,
  reloadCapabilitiesInProgress: false,
  reloadSubscriptionsInProgress: false,
  onboardInProgress: false,
  reconnectInProgress: false,
  disconnectInProgress: false,
  clearLogsInProgress: false,

  changeLoggingStatusProgress: false,
  fetchLoggingStatusProgress: false,
  isLoggingEnabled: false,

  changeTraceLoggingStatusProgress: false,
  fetchTraceLoggingStatusProgress: false,
  traceLoggingEnabledTill: 0,

  inited: false,

  dataIsFetched: false,
  onboardingIsJustified: false,
  lastError: null,

  capabilities: [],
  subscriptions: [],
  logs: [],
  earliestLogEntryTime: 0,
  latestLogEntryTime: 0,
  pushNotifications: 0,

  expireDays: null,
  onboarded: false,
  onboarding: false,
  qa: false,
  mqtt: false,
  pendingEndpoints: false,
  accountId: undefined,
  endpointId: undefined,
  links: {},
};

export default state;
