import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import L from "leaflet";
import { Map, TileLayer } from "react-leaflet";
import Control from "react-leaflet-control";
import basicBg from "../../images/basic-map.png";
import satelliteBg from "../../images/satellite-map.png";

import { GeoJSON, TelemetryEntry } from "../../store/telemetry/telemetry.store";
import { connectTheDots, cutMarkers, MapData } from "../../utils/map-adjustments";
import Tooltip from "@material-ui/core/Tooltip";
import LocationIcon from "@material-ui/icons/MyLocation";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adjustButton: {
      margin: 0,
      padding: "3px",
      height: "34px",
      width: "34px",
    },
    mapView: {
      width: "38px",
      height: "38px",
      backgroundSize: "contain",
      borderRadius: 4,
      cursor: "pointer",
    },
  })
);

export interface ConnectedState {
  geoJsonData?: GeoJSON;
  telemetry?: TelemetryEntry[][];
}

// tslint:disable-next-line: no-empty-interface
export interface Props extends ConnectedState {}

const MapComponent: FunctionComponent<Props> = ({ geoJsonData, telemetry }) => {
  const classes = useStyles();
  const mapRef = useRef<any>();
  const [startPosition, setStartPosition] = useState<L.LatLngTuple>();
  const [geoLayer, setGeoLayer] = useState<L.GeoJSON>();
  const [cuttedJsonData, setCuttedJsonData] = useState<any>(geoJsonData);
  const [mapUrl, setMapUrl] = useState<string>(MapData.HYBRID_SATELLITE_MAP);
  const [mapLayerIsChanged, setMapLayerIsChanged] = useState<boolean>(false);

  const drawLine = useCallback((layer, map) => {
    const conectedCoords = connectTheDots(layer);
    L.polyline(conectedCoords).addTo(map);
  }, []);

  const initMap = useCallback(() => {
    const { current } = mapRef;
    const { leafletElement: map } = current;
    const layer = L.geoJSON(cuttedJsonData);
    drawLine(layer, map);
    layer.addTo(map);
    setGeoLayer(layer);
    const layerGroup = L.layerGroup();
    const { lat, lng } = layer.getBounds().getCenter();
    setStartPosition([lat, lng]);
    map.addLayer(layerGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearMap = useCallback((map) => {
    map.eachLayer((layer: any) => {
      const hasNoOptions = Object.keys(layer.options).length === 0;
      if (hasNoOptions) {
        map.removeLayer(layer);
      }
    });
  }, []);

  const updateMap = useCallback(() => {
    const { current } = mapRef;
    const { leafletElement: map } = current;
    if (!!cuttedJsonData && geoLayer) {
      geoLayer.clearLayers();
      clearMap(map);
      geoLayer.addData(cuttedJsonData);
      drawLine(geoLayer, map);
      geoLayer.addTo(map);
    }
  }, [cuttedJsonData, drawLine, geoLayer, clearMap]);

  const resetView = useCallback(() => {
    const { current } = mapRef;
    const { leafletElement: map } = current;
    if (!!geoLayer && !!map) {
      map.fitBounds(geoLayer.getBounds());
    }
  }, [geoLayer]);

  const changeMapView = useCallback(() => {
    if (mapLayerIsChanged) {
      setMapUrl(MapData.HYBRID_SATELLITE_MAP);
      setMapLayerIsChanged(false);
    } else {
      setMapUrl(MapData.BASIC_MAP);
      setMapLayerIsChanged(true);
    }
  }, [mapLayerIsChanged]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  useEffect(() => {
    if (geoJsonData) {
      setCuttedJsonData(cutMarkers(geoJsonData, telemetry));
    }
  }, [geoJsonData, telemetry]);

  return (
    <Map
      center={startPosition}
      zoom={MapData.MAX_ZOOM}
      id="map"
      style={{
        width: "100%",
        height: "400px",
      }}
      ref={mapRef}
    >
      <TileLayer url={mapUrl} />
      <Control position="topleft">
        <Tooltip title={MapData.ADJUST_ZOOM_TITLE}>
          <button onClick={resetView} className={classes.adjustButton}>
            <LocationIcon />
          </button>
        </Tooltip>
      </Control>
      <Control position="topright">
        <Tooltip title={MapData.MAP_TYPE_TITLE}>
          <div
            onClick={changeMapView}
            className={classes.mapView}
            style={{ backgroundImage: `url(${mapLayerIsChanged ? satelliteBg : basicBg})` }}
          />
        </Tooltip>
      </Control>
    </Map>
  );
};

export default MapComponent;
