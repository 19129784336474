import React, { FunctionComponent } from "react";

import { TableCell, TableRow, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { DdiElement } from "../../../store/simulation/simulation.store";
import TransitionsModal from "../../common/modal-winidow/modal-window.component";
import TableComponent from "../../common/table/table.component";
import AppButton from "../../common/button/button.component";
import { ButtonSize } from "../../../shared/button-style";

import Element from "./element.component";

interface Props {
  open: boolean;
  closeModal: () => any;
  elements: DdiElement[];
  device: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

const DeviceInfoWindow: FunctionComponent<Props> = (props: Props) => {
  const { open, elements, device } = props;
  const classes = useStyles();

  return (
    <TransitionsModal open={open} closeModal={props.closeModal}>
      <Typography variant="h6" style={{ textAlign: "center", paddingBottom: "10px" }}>
        {device}
      </Typography>
      <TableComponent
        fixed={false}
        tableHead={
          <TableRow>
            <TableCell />
            <TableCell style={{ fontWeight: "bold" }}>Element</TableCell>
          </TableRow>
        }
        tableBody={elements.map((element) => (
          <Element key={element.element} element={element} />
        ))}
      />
      <div className={classes.buttonsContainer}>
        <AppButton size={ButtonSize.TINY_BUTTON} handler={props.closeModal}>
          Close
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default DeviceInfoWindow;
