import React, { FunctionComponent } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import TransitionsModal from "../../common/modal-winidow/modal-window.component";
import AppButton from "../../common/button/button.component";
import { ButtonSize } from "../../../shared/button-style";
import { Colors } from "../../../theme/colors";
import { Link } from "react-router-dom";

interface Props {
  open: boolean;
  closeModal: () => any;
  traceInfo?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButton: {
      backgroundColor: Colors.Grey,
      "&:hover": {
        backgroundColor: Colors.DarkGrey,
      },
    },
    link: {
      textDecoration: "none",
      fontSize: "15px",
      color: Colors.Green,
      transition: "all 0.5s",
      display: "inline-block",
      lineHeight: "15px",

      "&::after": {
        content: "''",
        display: "block",
        width: 0,
        height: "2px",
        background: Colors.Green,
        transition: "width .3s",
      },
      "&:hover:after": {
        width: "100%",
      },
    },
  })
);

const TraceLoggingWindow: FunctionComponent<Props> = (props: Props) => {
  const { open, closeModal, traceInfo } = props;
  const classes = useStyles();

  const EXTERNAL_LINK =
    process.env.REACT_APP_SERVICE_URL && Object.keys(traceInfo).length
      ? encodeURI(`${process.env.REACT_APP_SERVICE_URL}${JSON.stringify(traceInfo)}`)
      : undefined;

  return (
    <TransitionsModal open={open} closeModal={closeModal}>
      <Typography variant="h6">Logging info</Typography>
      <Typography style={{ overflow: "auto" }}>{JSON.stringify(traceInfo)}</Typography>
      {EXTERNAL_LINK && (
        <Link className={classes.link} to={{ pathname: EXTERNAL_LINK }} target="_blank">
          Go to parser
        </Link>
      )}
      <div className={classes.buttonsContainer}>
        <AppButton size={ButtonSize.TINY_BUTTON} className={classes.cancelButton} handler={closeModal}>
          Close
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default TraceLoggingWindow;
