import { ConfigurationParams } from "../../pages/simulation/simulator-configuration.component";
import { LoadingStatus, loadInitial } from "../loading-status";

export interface SimulatorFile {
  id: string;
  filename: string;
  type: string;
}

export interface SimulatorTimelogEntry {
  id: string;
  device: string;
  count: number;
  from: string;
  until: string;
}

export interface Progress {
  following?: string;
  interval: number;
  name: string;
  position: {
    current: number;
    size: number;
  };
  replaceTime?: string;
  scale: number;
  simulation: {
    start: string;
    current: string;
    end: string;
  };
  state: string;
  timeLog: {
    start: string;
    current: string;
    end: string;
  };
  configuration: ConfigurationParams;
}

export interface DdiEntry {
  ddi: number;
  name: string;
}

export interface DdiElement {
  element: string;
  ddis: DdiEntry[];
}

export interface Device {
  device: string;
  elements: DdiElement[];
}

export interface SimulationState {
  getFilesInProgress: boolean;
  getTimelogsInProgress: boolean;
  exportInProgress: boolean;

  files: SimulatorFile[];
  timelogs: SimulatorTimelogEntry[];
  devices: Device[];

  loadStatus: LoadingStatus;
  simulationInProgress: boolean;
  progress: Progress | null;
  configuration: ConfigurationParams | null;
}

const state: SimulationState = {
  getFilesInProgress: false,
  getTimelogsInProgress: false,
  exportInProgress: false,

  files: [],
  timelogs: [],
  devices: [],

  simulationInProgress: false,
  loadStatus: loadInitial(),
  progress: null,
  configuration: null,
};

export default state;
