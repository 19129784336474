import { FunctionComponent } from "react";
import React from "react";
import { makeStyles, TextField } from "@material-ui/core";

export interface Props {
  message: string;
}

const useStyles = makeStyles(() => ({
  input: {
    display: "flex",
    alignItems: "flex-start",
    height: "390px",
    paddingBottom: 0,
    marginTop: 0,
  },
}));

export const MessageField: FunctionComponent<Props> = ({ message }) => {
  const classes = useStyles();
  return (
    <TextField
      id="outlined-multiline-static"
      multiline={true}
      disabled={true}
      rows={19}
      defaultValue={message}
      variant="outlined"
      InputProps={{
        className: classes.input,
      }}
    />
  );
};
