import { connect } from "react-redux";
import { Dispatch } from "redux";

import SubscriptionsComponent, { ConnectedState, ConnectedDispatch } from "./subscriptions.component";

import { AppState } from "../../store/app.state";

import { getSaveSubscriptionsProgress, getSubscriptions } from "../../store/global/global.selectors";
import { saveSubscriptions } from "../../store/global/global.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  subscriptions: getSubscriptions(state),
  saveSubscriptionsStatus: getSaveSubscriptionsProgress(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  save: (subscriptions: string[]) => dispatch(saveSubscriptions(subscriptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsComponent);
