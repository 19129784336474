export enum SuccessMessages {
  SHOW_SUCCESSFULL_DOWNLOAD_FILE = "File is downloaded successfully.",
  SHOW_SUCCESSFULL_DELTE_FILE = "Deleted successfully.",
  SHOW_SUCCESSFULL_UPLOAD_FILE = "File is uploaded successfully.",
  SHOW_SUCCESSFULL_SIMULATION = "Simulation is completed successfully.",
  SHOW_SUCCESSFULL_SEND_FILE_TO_ENPOINT = "Files were sent successfully.",
  SHOW_SUCCESSFUL_CHANGE_PASSWORD = "Password is changed successfully.",
  SHOW_SUCCESSFULL_RELOAD_FILES = "Files are successfully reloaded.",
  SHOW_SUCCESSFULL_SAVE_CAPABILITIES = "Capabilities are successfully saved.",
  SHOW_SUCCESSFULL_SAVE_SUBSCRIPTIONS = "Subscriptions are successfully saved.",
}

export enum FailureMessages {
  SHOW_FAILURE_DOWNLOAD_FILE = "File was not succesfully downloaded",
  SHOW_FAILURE_DELTE_FILE = "",
  SHOW_FAILURE_UPLOAD_FILE = "",
  SHOW_FAILURE_SIMULATION = "",
  SHOW_FAILURE_SEND_FILE_TO_ENPOINT = "",
  SHOW_FAILURE_CHANGE_PASSWORD = "",
  SHOW_FAILURE_SAVE_CAPABILITIES = "Capabilities were not saved",
  SHOW_FAILURE_SAVE_SUBSCRIPTIONS = "Subscriptions were not saved",
}
