import React, { FunctionComponent } from "react";
import { Provider } from "react-redux";
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";

import { store } from "./store";
import AppContainer from "./components/app/app.container";
import { history } from "./store/configureStore";

const logger = (createLogger as any)();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let middleware = applyMiddleware(logger);

if (process.env.NODE_ENV === "development") {
  middleware = composeWithDevTools(middleware);
}

const ReduxRoot: FunctionComponent<{}> = () => {
  return (
    <Provider store={store}>
      <AppContainer history={history} />
    </Provider>
  );
};

export default ReduxRoot;
