import { createSelector } from "reselect";

import { AppState } from "../app.state";

export const getDataStorageState = (state: AppState) => state.dataStorage;
export const fileIsUploadedStatus = (state: AppState) => state.dataStorage.fileIsUploaded;
export const getUploadedValidatedFileLog = (state: AppState) => state.dataStorage.lastUploadedValidatedFile;
export const getLastValidatedFileLog = (state: AppState) => state.dataStorage.lastValidatedFile;
export const getEditableFile = (state: AppState) => state.dataStorage.editableFile;
export const getFiles = createSelector(getDataStorageState, (state) => state.files || []);

export const getFileById = (state: AppState, fileId: string) => {
  const file = state.dataStorage.files.find(({ id }) => id === fileId);

  const emptyFile = {
    id: fileId,
    type: "",
    filename: "",
    size: "0",
  };

  return file || emptyFile;
};

export const getLastUploadedFile = createSelector(
  getDataStorageState,
  (state) => state.files[state.files.length - 1] || []
);

export const getReloadProgress = createSelector(
  getDataStorageState,
  (state) => state.reloadInProgress || false
);

export const getReceiveAllFileProgress = createSelector(
  getDataStorageState,
  (state) => state.receiveAllProgress || false
);

export const getReceiveTodayProgress = createSelector(
  getDataStorageState,
  (state) => state.receiveTodayProgress || false
);

export const getClearFeedProgress = createSelector(
  getDataStorageState,
  (state) => state.clearFeedProgress || false
);

export const getValidationProgress = createSelector(
  getDataStorageState,
  (state) => state.validationProgress || false
);

export const isReceiveAllCancelled = createSelector(
  getDataStorageState,
  (state) => state.receiveAllCancelled || false
);

export const isReceiveTodayCancelled = createSelector(
  getDataStorageState,
  (state) => state.receiveTodayCancelled || false
);
