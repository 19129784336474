import { Action } from "./action";
import { AuthenticationActionType } from "./authentication/authentication.actions";

export default function createReducer(initialState: object, handlers: any) {
  return function reducer(state: object = initialState, action: Action<any>) {
    if (action.type === AuthenticationActionType.LOGOUT_SUCCESSFUL) {
      state = initialState;
    }
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}
