import debug from "debug";

export const DEBUG_BASE = "io-tool";

enum COLORS {
  trace = "lightblue",
  info = "blue",
  warn = "yellow",
  error = "red",
}

class Logger {
  static generateMessage(color: string, messages: any[], source?: string) {
    const namespace = `${DEBUG_BASE}${source ? `:${source}` : ``}`;
    const createDebug = debug(namespace) as any;

    createDebug.color = color;
    createDebug(...messages);
  }

  trace = (source: string) => (...messages: any[]) => {
    return Logger.generateMessage(COLORS.trace, messages, source);
  };

  info = (source: string) => (...messages: any[]) => {
    return Logger.generateMessage(COLORS.info, messages, source);
  };

  warn = (source: string) => (...messages: any[]) => {
    return Logger.generateMessage(COLORS.warn, messages, source);
  };

  error = (source: string) => (...messages: any[]) => {
    return Logger.generateMessage(COLORS.error, messages, source);
  };
}

export default new Logger();
