import { call, put, select, takeLatest } from "redux-saga/effects";

import wsClient from "../../api/ws-client";
import logger from "../../logger";
import { MethodsName, Roles, WsMethods } from "../../utils/ws-methods";
import { setLastError } from "../global/global.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import BackendClient from "../../api/backend-client";
import ApiMethods from "../../api/api-methods";

import {
  GetTelemetryAction,
  initTelemetryFailure,
  initTelemetrySuccessful,
  updateTelemetryFailure,
  updateTelemetrySuccessful,
  setTelemetry,
  TelemetryActionType,
  loadTelemetryFailure,
  loadTelemetrySuccessful,
  DownloadTelemetryAction,
  downloadSuccessful,
  downloadFailure,
} from "./telemetry.actions";

import { GeoJSON, TelemetryEntry } from "./telemetry.store";
import { store } from "../index";
import { getPushNotifications } from "../global/global.selectors";
import { getTelemetryOffset } from "./telemetry.selectors";
import { Action } from "../action";
import { getFileById } from "../data-storage/data-storage.selectors";
import { SuccessMessages } from "../../utils/notifications.messages";
import { Dispatch } from "redux";

const logError = logger.error("telemetry.saga");
const logInfo = logger.info("telemetry.saga");

let backendClient: BackendClient;

const appendTelemetryData = ({
  telemetry,
  geoJSON,
  offset,
}: {
  telemetry: TelemetryEntry[][];
  geoJSON: GeoJSON;
  offset: number;
}) => store.dispatch(setTelemetry(telemetry, geoJSON, offset));

function* initTelemetry({ payload: { fileId, rows } }: GetTelemetryAction) {
  try {
    yield put(setLastError({}));

    const data = yield call([backendClient, ApiMethods.UpdateTelemetry], fileId, -rows, rows);

    const { telemetry, caption, geoJSON, total, offset } = data;
    logInfo(MethodsName.SET_TELEMETRY_LISTENER, data);

    const pushNotifications = yield select(getPushNotifications);

    if (pushNotifications > 0) {
      wsClient.subscribe(`${Roles.TELEMETRY}.${WsMethods.UPDATE}`, appendTelemetryData);
    }

    yield put(setTelemetry(telemetry, geoJSON, offset));
    yield put(initTelemetrySuccessful(caption, total));
  } catch (e) {
    yield put(initTelemetryFailure());
    yield put(showFailedRequest(e.message));
    yield put(setLastError(e));
    logError("telemetry didn't started");
  }
}

function* updateTelemetry({ payload: { fileId } }: Action<{ fileId: string }>) {
  try {
    const offset = yield select(getTelemetryOffset);

    const { geoJSON, telemetry, offset: newOffset } = yield call(
      [backendClient, ApiMethods.UpdateTelemetry],
      fileId,
      offset
    );

    yield put(setTelemetry(telemetry, geoJSON, newOffset));
    yield put(updateTelemetrySuccessful());
  } catch (e) {
    yield put(updateTelemetryFailure());
    yield put(showFailedRequest(e.message));
    yield put(setLastError(e));
    logError("telemetry wasn't updated");
  }
}

function* loadTelemetry({ payload: { fileId, rows } }: GetTelemetryAction) {
  try {
    const { geoJSON, telemetry, offset } = yield call(
      [backendClient, ApiMethods.UpdateTelemetry],
      fileId,
      -rows,
      rows
    );
    yield put(setTelemetry(telemetry, geoJSON, offset));
    yield put(loadTelemetrySuccessful());
  } catch (e) {
    yield put(loadTelemetryFailure());
    yield put(showFailedRequest(e.message));
    yield put(setLastError(e));
    logError("telemetry didn't re-loaded");
  }
}

function* download({ payload: { isIsoxml, id } }: DownloadTelemetryAction) {
  try {
    const { filename } = yield select(getFileById, id);

    if (isIsoxml) {
      const { file } = yield call([backendClient, ApiMethods.DownloadIsoxml], id);
      saveAs(new Blob([file]), `${filename}`);
    } else {
      const { file } = yield call([backendClient, ApiMethods.DownloadFile], id);
      saveAs(new Blob([file]), `${filename}`);
    }

    yield put(downloadSuccessful());
    yield put(showSuccessfulRequest(SuccessMessages.SHOW_SUCCESSFULL_DOWNLOAD_FILE));
  } catch (e) {
    yield put(setLastError(e));
    const message = `Could not download file ${e?.message || ""}`;
    yield put(downloadFailure());
    yield put(showFailedRequest(message));
    logError(message);
  }
}

export default function* telemetrySaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);

  yield takeLatest(TelemetryActionType.INIT_TELEMETRY, initTelemetry);
  yield takeLatest(TelemetryActionType.UPDATE_TELEMETRY, updateTelemetry);
  yield takeLatest(TelemetryActionType.LOAD_TELEMETRY, loadTelemetry);
  yield takeLatest(TelemetryActionType.DOWNLOAD, download);
}
