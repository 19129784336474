import { connect } from "react-redux";
import { Dispatch } from "redux";

import SimulationComponent, { ConnectedState, ConnectedDispatch, ExportParams } from "./simulation.component";
import { AppState } from "../../store/app.state";

import {
  exportEfdi,
  forwardSimulation,
  getFiles,
  pauseSimulation,
  reset,
  resumeSimulation,
  setConfiguration,
  startSimulation,
  stopSimulation,
} from "../../store/simulation/simulation.actions";

import {
  exportProgress,
  getConfiguration,
  getProgress,
  getSimulationDevices,
  getSimulationFiles,
  getSimulationFilesByType,
  getSimulationFilesStatus,
  isSimulationInProgress,
} from "../../store/simulation/simulation.selectors";

import { ConfigurationParams } from "./simulator-configuration.component";

const mapStateToProps = (state: AppState): ConnectedState => ({
  files: getSimulationFiles(state),
  filesByType: getSimulationFilesByType(state),
  getFilesStatus: getSimulationFilesStatus(state),
  isSimulationInProgress: isSimulationInProgress(state),
  progress: getProgress(state),
  configuration: getConfiguration(state),
  exportProgress: exportProgress(state),
  devices: getSimulationDevices(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  getFiles: () => dispatch(getFiles()),
  startSimulation: (configuration: ConfigurationParams) => dispatch(startSimulation(configuration)),
  stopSimulation: () => dispatch(stopSimulation()),
  setConfiguration: (configuration: ConfigurationParams) => dispatch(setConfiguration(configuration)),
  reset: () => dispatch(reset()),
  resume: () => dispatch(resumeSimulation()),
  pause: () => dispatch(pauseSimulation()),
  next: () => dispatch(forwardSimulation()),
  exportEfdi: (id: string, params: ExportParams, filename: string) =>
    dispatch(exportEfdi(id, params, filename)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimulationComponent);
