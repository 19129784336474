import { createSelector } from "reselect";

import { AppState } from "../app.state";
import { StatusData } from "../../api/backend-api";

export const getGlobalState = (state: AppState) => state.global;

export const getLogs = (state: AppState, limit?: number) =>
  (limit !== undefined ? state.global.logs.slice(0, limit) : state.global.logs) || [];

export const getEarliestLogEntryTime = createSelector(
  getGlobalState,
  (state) => state.earliestLogEntryTime || 0
);

export const getLatestLogEntryTime = createSelector(getGlobalState, (state) => state.latestLogEntryTime || 0);
export const getDataStatus = createSelector(getGlobalState, (state) => state.dataIsFetched || false);
export const getFetchProgress = createSelector(getGlobalState, (state) => state.fetchDataInProgress || false);

export const getOnboardingJustificationStatus = createSelector(
  getGlobalState,
  (state) => state.onboardingIsJustified || false
);

export const getSaveCapabilitiesProgress = createSelector(
  getGlobalState,
  (state) => state.saveCapabilitiesInProgress || false
);

export const getSaveSubscriptionsProgress = createSelector(
  getGlobalState,
  (state) => state.saveSubscriptionsInProgress || false
);

export const getReloadCapabilitiesProgress = createSelector(
  getGlobalState,
  (state) => state.reloadCapabilitiesInProgress || false
);

export const getReloadSubscriptionsProgress = createSelector(
  getGlobalState,
  (state) => state.reloadSubscriptionsInProgress || false
);

export const getReonboardProgress = createSelector(
  getGlobalState,
  (state) => state.onboardInProgress || false
);

export const getReconnectProgress = createSelector(
  getGlobalState,
  (state) => state.reconnectInProgress || false
);

export const getDisconnectProgress = createSelector(
  getGlobalState,
  (state) => state.disconnectInProgress || false
);

export const getClearLogsInProgress = createSelector(
  getGlobalState,
  (state) => state.clearLogsInProgress || false
);

export const getIsLoggingStatusEnabled = createSelector(
  getGlobalState,
  (state) => state.isLoggingEnabled || false
);

export const getTraceLoggingStatusEnabledTill = createSelector(
  getGlobalState,
  (state) => state.traceLoggingEnabledTill || 0
);

export const getLastError = createSelector(getGlobalState, (state) => state.lastError || { message: "" });
export const getCapabilities = createSelector(getGlobalState, (state) => state.capabilities || []);
export const getSubscriptions = createSelector(getGlobalState, (state) => state.subscriptions || []);
export const getPushNotifications = createSelector(getGlobalState, (state) => state.pushNotifications || 0);
export const getExpireDays = createSelector(getGlobalState, (state) => state.expireDays || null);
export const getOnboardStatus = createSelector(getGlobalState, (state) => state.onboarded || false);
export const getOnboardingStatus = createSelector(getGlobalState, (state) => state.onboarding || false);
export const isMqtt = createSelector(getGlobalState, (state) => state.mqtt || false);
export const isQA = createSelector(getGlobalState, (state) => state.qa || false);
export const getEnvironmentLinks = createSelector(getGlobalState, (state) => state.links || {});

export const getStatusData = createSelector(
  getGlobalState,
  (state): StatusData =>
    ({
      expireDays: state.expireDays,
      onboarded: state.onboarded,
      onboarding: state.onboarding,
      qa: state.qa,
      mqtt: state.mqtt,
      pendingEndpoints: state.pendingEndpoints,
      accountId: state.accountId,
      endpointId: state.endpointId,
    } || {})
);
