import createReducer from "../createReducer";
import { Action } from "../action";
import { NotificationMessage, NotificationsActionType } from "./notifications.actions";

export interface NotificationState {
  notification: NotificationMessage | undefined;
}

export const initialState: NotificationState = {
  notification: undefined,
};

export default createReducer(initialState, {
  [NotificationsActionType.SHOW_FAILED_REQUEST](
    state: NotificationState,
    action: Action<NotificationMessage>
  ): NotificationState {
    return { ...state, notification: action.payload };
  },
  [NotificationsActionType.SHOW_NOT_FOUND_REQUEST](
    state: NotificationState,
    action: Action<NotificationMessage>
  ): NotificationState {
    return { ...state, notification: action.payload };
  },
  [NotificationsActionType.SHOW_SUCCESSFUL_REQUEST](
    state: NotificationState,
    action: Action<NotificationMessage>
  ): NotificationState {
    return { ...state, notification: action.payload };
  },
});
