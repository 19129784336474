import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { parse } from "querystringify";
import Preloader from "../../components/preloader/preloader.component";
import AuthService from "../../shared/services/auth.service";
import { login } from "../../store/authentication/authentication.actions";

const CallbackComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = AuthService.getInstance().isLoggedIn;

  useEffect(() => {
    if (history.location) {
      const params: any = parse(history.location.search);

      dispatch(login(params.code, params.state));
    }
  }, [dispatch, history, isLoggedIn]);

  return isLoggedIn ? null : <Preloader />;
};

export default CallbackComponent;
