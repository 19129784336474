import { AxiosError } from "axios";
import { Dispatch } from "redux";

import { logout, refreshToken } from "../store/authentication/authentication.actions";
import AuthService from "../shared/services/auth.service";

export const responseHandler = (error: AxiosError, dispatch?: Dispatch) => {
  const { response } = error;
  if (!response) {
    return;
  }

  if (dispatch) {
    if (response.status === 401) {
      if (
        response.request.responseURL.includes("refresh") ||
        response.request.responseURL.includes("logout")
      ) {
        dispatch(logout(true));
      } else {
        dispatch(refreshToken());
      }
    }
  }
};

export const requestHandler = (request: any, dispatch?: Dispatch) => {
  if (!request) {
    return;
  }

  if (dispatch) {
    if (
      !request.url.includes("/rest/auth") &&
      !request.url.includes("/rest/agrirouter/onboarding/callback")
    ) {
      const isAccessTokenExpired = AuthService.getInstance().isAccessTokenExpired();

      if (isAccessTokenExpired) {
        dispatch(refreshToken());
      }
    }
  }
};
