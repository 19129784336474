import React, { useState, useRef, useEffect, useCallback } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Telegram as TelegramIcon, Autorenew, Publish as PublishIcon } from "@material-ui/icons";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";

import PageWithLogging from "../../components/page/page.component";
import AppButton from "../../components/common/button/button.component";
import UploadModalWindowContainer from "../../components/modal-windows/upload-window/upload-window.container";
import DeleteConfirmationWindowContainer from "../../components/modal-windows/delete-confirmation-window/delete-confirmation-window.container";
import { AccordionComponent } from "../../components/common/accordion/accordion.component";
import ValidationModalWindowContainer from "../../components/modal-windows/validation-window/validation-window.container";

import { ButtonSize } from "../../shared/button-style";
import { Colors } from "../../theme/colors";
import { FileTypes, TitlesByType } from "../../shared/accordion-titles";

import { IFileDto } from "../../store/data-storage/data-storage.store";
import FilesTableContainer from "../../components/files-table/files-table.container";
import SendToEndpointsWindow from "../../components/modal-windows/send-to-endpoints-window/send-to-endpoints-window.container";

import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";

import PaperComponent from "../../components/paper/paper.component";
import ReceiveControllBarContainer from "../../components/receive-controll-bar/receive-controll-bar.container";

export interface ConnectedState {
  files: IFileDto[];
  filesReloadProgress: boolean;
  sendFilesProgress: boolean;
  publishFilesProgress: boolean;
  progressValidationStatus: boolean;
  isOpen: boolean;
  isValidationOpen: boolean;
  isOnboarded: boolean;
  isLoggedIn: boolean;
}

export interface ConnectedDispatch {
  reloadFiles: () => void;
  uploadFile: (file: any) => any;
  fetchFiles: () => any;
  toggleUploadWindow: (isOpen: boolean) => void;
  toggleValidationModalWindow: (isValidModalOpen: boolean) => void;
}

export interface Props extends ConnectedState, ConnectedDispatch {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzoneArea: {
      minHeight: "0px",
    },
    dropzoneText: {
      marginBottom: "6px",
      marginTop: "6px",
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    dropzoneIcon: {
      width: "25px",
      height: "25px",
    },
    controlsBar: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 10px",
    },
    buttonIcon: {
      marginRight: "10px",
    },
    headerControls: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row-reverse",
    },
    searchField: {
      flexGrow: 1,
      margin: "0 10px",
    },
    preloaderWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    reloadingTitle: {
      fontSize: "16pt",
      justifyContent: "center",
      alignItems: "center",
      color: Colors.Grey,
    },
    noDataTitle: {
      display: "flex",
      justifyContent: "center",
      color: Colors.Grey,
    },
    deleteSelectedBtn: {
      backgroundColor: Colors.Red,
      "&:hover": {
        backgroundColor: Colors.Red2,
      },
    },
  })
);

const MAX_FILE_SIZE = 15 * 1024 * 1024;

const DataStorageComponent = ({
  files = [],
  reloadFiles,
  filesReloadProgress,
  sendFilesProgress,
  publishFilesProgress,
  progressValidationStatus,
  uploadFile,
  isOpen,
  toggleUploadWindow,
  isValidationOpen,
  toggleValidationModalWindow,
  isOnboarded,
  fetchFiles,
  isLoggedIn,
}: Props) => {
  const classes = useStyles();
  const MIN_DELETABLE_FILES = 1;
  const [openSend, setOpenSend] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [activeFiles, setActiveFiles] = useState<IFileDto[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<FileObject[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<IFileDto[]>([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [validatingFileId, setValidatingFileId] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      fetchFiles();
    }
  }, [fetchFiles, isLoggedIn]);

  const closeWindow = () => {
    toggleUploadWindow(false);
  };

  const handleFileAdd = (newFiles: FileObject[]) => {
    newFiles.forEach((file) => {
      uploadFile({
        files: [file.file],
      });
    });
    setUploadFiles([]);
  };

  const updateSelectedFiles = (e: any, id: string, selected: boolean) => {
    if (selected && files) {
      const selectedValue = files.find((item: IFileDto) => item.id === id);
      if (selectedValue) {
        setActiveFiles([...activeFiles, selectedValue]);
      }
    } else {
      setActiveFiles(activeFiles.filter(({ id: selectedId }) => selectedId !== id));
    }
  };

  const rememberValidatingFile = (id: string) => {
    setValidatingFileId(id);
  };

  const closeValidationDialog = () => {
    toggleValidationModalWindow(false);
    setValidatingFileId("");
  };

  const reloadAllFiles = useCallback(() => reloadFiles(), [reloadFiles]);
  const openSendDialog = useCallback(() => setOpenSend(true), []);
  const closeSendDialog = useCallback(() => setOpenSend(false), []);
  const openPublishDialog = useCallback(() => setOpenPublish(true), []);
  const closePublishDialog = useCallback(() => setOpenPublish(false), []);
  const closeConfirmationDialog = useCallback(() => setIsConfirmationOpen(false), []);
  const deleteSelectedFiles = useCallback(() => setIsConfirmationOpen(true), []);

  const clearSelected = (ids: string[]) => {
    setActiveFiles(activeFiles.filter((select: IFileDto) => !ids.includes(select.id)));
  };

  const filterFiles = (disaplyedFiles: IFileDto[], type: string) =>
    disaplyedFiles.filter((file: IFileDto) => type.toLowerCase() === file.type.toLowerCase());

  const displayedFiles = () =>
    Object.values(FileTypes).map((type: string) =>
      searchTerm.length !== 0 ? filterFiles(searchResults, type) : filterFiles(files, type)
    );

  const handleSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const searchedResults = useCallback(
    (searchedFile: string) => {
      const searchedFiles: IFileDto[] = [];
      files.map(
        (item: IFileDto) =>
          !!item &&
          !!item.filename &&
          item.filename.toLowerCase().includes(searchedFile.toLowerCase()) &&
          searchedFiles.push(item)
      );
      return searchedFiles;
    },
    [files]
  );

  useEffect(() => {
    setSearchResults(searchedResults(searchTerm));
  }, [searchTerm, searchedResults]);

  const prevSendFilesInProgress = useRef(false);
  const prevPublishFilesInProgress = useRef(false);

  useEffect(() => {
    if (prevSendFilesInProgress.current !== sendFilesProgress && !sendFilesProgress && openSend) {
      setOpenSend(false);
    }

    prevSendFilesInProgress.current = sendFilesProgress;
  }, [openSend, sendFilesProgress]);

  useEffect(() => {
    if (prevPublishFilesInProgress.current !== publishFilesProgress && !publishFilesProgress && openPublish) {
      setOpenPublish(false);
    }

    prevPublishFilesInProgress.current = publishFilesProgress;
  }, [openPublish, publishFilesProgress]);

  const selectAllHandler = () => {
    if (!isAllSelected) {
      setActiveFiles(files);
      setIsAllSelected(true);
    } else {
      setActiveFiles([]);
      setIsAllSelected(false);
    }
  };

  useEffect(() => {
    setIsAllSelected(activeFiles.length === files.length && files.length !== 0);
  }, [activeFiles, files.length]);

  return (
    <PageWithLogging isCollapsible={true}>
      <DropzoneAreaBase
        showAlerts={["error"]}
        fileObjects={uploadFiles}
        onAdd={handleFileAdd}
        showPreviews={false}
        showPreviewsInDropzone={false}
        filesLimit={10}
        maxFileSize={MAX_FILE_SIZE}
        dropzoneText="To upload, drag and drop here or click to choose [max. 15 MB per file]"
        classes={{
          root: classes.dropzoneArea,
          text: classes.dropzoneText,
          icon: classes.dropzoneIcon,
        }}
        alertSnackbarProps={{ anchorOrigin: { vertical: "top", horizontal: "right" } }}
      />
      <TextField
        className={classes.searchField}
        id="standard-basic"
        label="Search..."
        onChange={handleSearchTerm}
        value={searchTerm}
      />
      {progressValidationStatus ? (
        <PaperComponent>
          <div className={classes.preloaderWrapper}>
            <CircularProgress size={60} />
            <Typography className={classes.reloadingTitle}>Reloading...</Typography>
          </div>
        </PaperComponent>
      ) : (
        <PaperComponent>
          <div className={classes.headerControls}>
            <AppButton
              typeName="button"
              handler={reloadAllFiles}
              size={ButtonSize.TINY_BUTTON}
              disabled={filesReloadProgress}
            >
              <div className={classes.buttonIcon}>
                <Autorenew />
              </div>
              Reload
            </AppButton>
            {isOnboarded && <ReceiveControllBarContainer />}
          </div>
          {searchTerm.length !== 0 && searchResults.length === 0 ? (
            <Typography variant="h5">NO RESULTS</Typography>
          ) : !!files && files.length === 0 ? (
            <div className={classes.noDataTitle}>
              <Typography variant="h5">No data</Typography>
            </div>
          ) : (
            displayedFiles().map((item: IFileDto[], index: number) => {
              const accordionTitle = Object.values(TitlesByType)[index];
              const fileTitle = Object.values(FileTypes)[index];

              return (
                item.length !== 0 && (
                  <AccordionComponent
                    key={index}
                    title={`${accordionTitle} (${item.length})`}
                    panelId={accordionTitle}
                    defaultExpanded={true}
                  >
                    <FilesTableContainer
                      files={item}
                      panelName={fileTitle}
                      onUpdateSelected={updateSelectedFiles}
                      onClearSelected={clearSelected}
                      onRememberValidatingFile={rememberValidatingFile}
                      validatingFile={validatingFileId}
                      allSelectedFiles={activeFiles.map((file: IFileDto) => file.id)}
                    />
                  </AccordionComponent>
                )
              );
            })
          )}
          <div className={classes.controlsBar}>
            <div>
              <AppButton
                size={ButtonSize.SMALL_BUTTON}
                handler={selectAllHandler}
                disabled={files.length === 0}
              >
                <div className={classes.buttonIcon}>
                  <PlaylistAddCheckIcon />
                </div>
                <Typography style={{ fontSize: "10pt" }}>{`${
                  isAllSelected ? "Deselect" : "Select"
                } All`}</Typography>
              </AppButton>
              <AppButton
                size={ButtonSize.MEDIUM_BUTTON}
                disabled={activeFiles.length < MIN_DELETABLE_FILES}
                handler={deleteSelectedFiles}
                className={classes.deleteSelectedBtn}
              >
                <div className={classes.buttonIcon}>
                  <DeleteForeverIcon />
                </div>
                Delete selected
              </AppButton>
            </div>
            {isOnboarded && (
              <div>
                <AppButton
                  size={ButtonSize.MEDIUM_BUTTON}
                  disabled={activeFiles.length === 0}
                  handler={openPublishDialog}
                >
                  <div className={classes.buttonIcon}>
                    <PublishIcon />
                  </div>
                  Publish
                </AppButton>
                <AppButton
                  size={ButtonSize.MEDIUM_BUTTON}
                  disabled={activeFiles.length === 0}
                  handler={openSendDialog}
                >
                  <div className={classes.buttonIcon}>
                    <TelegramIcon />
                  </div>
                  Send to endpoint
                </AppButton>
              </div>
            )}
          </div>
          {isOpen && <UploadModalWindowContainer closeModal={closeWindow} open={isOpen} />}
          {openSend && (
            <SendToEndpointsWindow
              publishMode={false}
              open={openSend}
              closeModal={closeSendDialog}
              files={activeFiles}
            />
          )}
          {openPublish && (
            <SendToEndpointsWindow
              publishMode={true}
              open={openPublish}
              closeModal={closePublishDialog}
              files={activeFiles}
            />
          )}
          {isConfirmationOpen && (
            <DeleteConfirmationWindowContainer
              closeModal={closeConfirmationDialog}
              deletedFileIds={activeFiles.map((item) => item.id)}
              open={isConfirmationOpen}
              onClearSelected={clearSelected}
            />
          )}
          {isValidationOpen && (
            <ValidationModalWindowContainer closeModal={closeValidationDialog} open={isValidationOpen} />
          )}
        </PaperComponent>
      )}
    </PageWithLogging>
  );
};

export default DataStorageComponent;
