import React, { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import withAuthService from "../shared/services/with-auth.service";

interface Props extends RouteProps {
  isLoggedIn: boolean;
}

const PublicRoute: FunctionComponent<Props> = ({ component: Component, isLoggedIn, ...rest }) => {
  return isLoggedIn ? <Redirect to={"/"} /> : <Route {...rest} component={Component} />;
};

export default withAuthService(PublicRoute);
