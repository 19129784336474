import { Action } from "../action";

export interface AuthParams {
  code: string;
  state: string;
}

export enum AuthenticationActionType {
  LOGIN = "LOGIN",
  LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL",
  LOGIN_FAILURE = "LOGIN_FAILURE",

  LOGOUT = "LOGOUT",
  LOGOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL",
  LOGOUT_FAILURE = "LOGOUT_FAILURE",

  RELOGIN = "RELOGIN",
  RELOGIN_SUCCESSFULL = "RELOGIN_SUCCESSFULL",

  SCHEDULED_TOKEN_REFRESH = "SCHEDULED_TOKEN_REFRESH",
  REFRESH_TOKEN = "REFRESH_TOKEN",
}

export const login = (code: string, state: string): Action<{ code: string; state: string }> => ({
  type: AuthenticationActionType.LOGIN,
  payload: { code, state },
});

export const loginSuccessful = (): Action<null> => ({
  type: AuthenticationActionType.LOGIN_SUCCESSFUL,
  payload: null,
});

export const loginFailure = (errorMessage: string): Action<string> => ({
  type: AuthenticationActionType.LOGIN_FAILURE,
  payload: errorMessage,
});

export const logout = (internally: boolean = false): Action<boolean> => ({
  type: AuthenticationActionType.LOGOUT,
  payload: internally,
});

export const logoutSuccessful = (): Action<null> => ({
  type: AuthenticationActionType.LOGOUT_SUCCESSFUL,
  payload: null,
});

export const logoutFailure = (errorMessage: string): Action<string> => ({
  type: AuthenticationActionType.LOGOUT_FAILURE,
  payload: errorMessage,
});

export const relogin = (): Action<null> => ({
  type: AuthenticationActionType.RELOGIN,
  payload: null,
});

export const reloginSuccessfull = (): Action<null> => ({
  type: AuthenticationActionType.RELOGIN_SUCCESSFULL,
  payload: null,
});

export const scheduledTokenRefresh = (): Action<null> => ({
  type: AuthenticationActionType.SCHEDULED_TOKEN_REFRESH,
  payload: null,
});

export const refreshToken = (): Action<null> => ({
  type: AuthenticationActionType.REFRESH_TOKEN,
  payload: null,
});
