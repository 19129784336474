import createReducer from "../createReducer";
import { InitTelemetrySuccessfulAction, SetTelemetryAction, TelemetryActionType } from "./telemetry.actions";
import initialState, { GeoJSON, TelemetryState } from "./telemetry.store";

export default createReducer(initialState, {
  [TelemetryActionType.INIT_TELEMETRY](state: TelemetryState): TelemetryState {
    return { ...state, loadTelemetryInProgress: true, telemetry: [], geoJSON: null };
  },
  [TelemetryActionType.INIT_TELEMETRY_SUCCESSFUL](
    state: TelemetryState,
    { payload: { caption, total } }: InitTelemetrySuccessfulAction
  ): TelemetryState {
    return { ...state, caption, total, loadTelemetryInProgress: false, isInited: true };
  },
  [TelemetryActionType.INIT_TELEMETRY_FAILURE](state: TelemetryState): TelemetryState {
    return { ...state, loadTelemetryInProgress: false };
  },
  [TelemetryActionType.SET_TELEMETRY](
    state: TelemetryState,
    { payload: { telemetry, geoJSON, offset } }: SetTelemetryAction
  ): TelemetryState {
    let updatedGeoJSON: GeoJSON = { features: [], type: "" };
    if (!state.geoJSON) {
      updatedGeoJSON = geoJSON;
    } else {
      updatedGeoJSON = {
        features: [
          {
            geometry: {
              coordinates: [
                ...state.geoJSON.features[0].geometry.coordinates,
                ...geoJSON.features[0].geometry.coordinates,
              ],
              type: geoJSON.features[0].geometry.type,
            },
            type: geoJSON.features[0].type,
          },
          ...state.geoJSON.features.slice(1),
          ...geoJSON.features.slice(1),
        ],
        type: geoJSON.type,
      };
    }

    return {
      ...state,
      telemetry: [...state.telemetry, ...telemetry].reverse(),
      geoJSON: updatedGeoJSON,
      offset: state.offset + offset,
    };
  },
  [TelemetryActionType.UPDATE_TELEMETRY](state: TelemetryState): TelemetryState {
    return { ...state, loadTelemetryInProgress: true };
  },
  [TelemetryActionType.UPDATE_TELEMETRY_SUCCESSFUL](state: TelemetryState): TelemetryState {
    return { ...state, loadTelemetryInProgress: false };
  },
  [TelemetryActionType.UPDATE_TELEMETRY_FAILURE](state: TelemetryState): TelemetryState {
    return { ...state, loadTelemetryInProgress: false };
  },
  [TelemetryActionType.LOAD_TELEMETRY](state: TelemetryState): TelemetryState {
    return { ...state, loadTelemetryInProgress: true, telemetry: [], geoJSON: null };
  },
  [TelemetryActionType.LOAD_TELEMETRY_SUCCESSFUL](state: TelemetryState): TelemetryState {
    return { ...state, loadTelemetryInProgress: false };
  },
  [TelemetryActionType.LOAD_TELEMETRY_FAILURE](state: TelemetryState): TelemetryState {
    return { ...state, loadTelemetryInProgress: false };
  },
  [TelemetryActionType.DOWNLOAD](state: TelemetryState): TelemetryState {
    return { ...state, downloadInProgress: true };
  },
  [TelemetryActionType.DOWNLOAD_SUCCESSFUL](state: TelemetryState): TelemetryState {
    return { ...state, downloadInProgress: false };
  },
  [TelemetryActionType.DOWNLOAD_FAILURE](state: TelemetryState): TelemetryState {
    return { ...state, downloadInProgress: false };
  },
  [TelemetryActionType.RESET_TELEMETRY](state: TelemetryState): TelemetryState {
    return initialState;
  },
});
