import { saveAs } from "file-saver";
import { call, put, takeLatest, takeLeading, takeEvery, select } from "redux-saga/effects";

import { Action } from "../action";
import BackendClient from "../../api/backend-client";
import ApiMethods from "../../api/api-methods";
import logger from "../../logger";
import { store } from "../index";

import {
  downloadFileFailure,
  downloadFileSuccess,
  DataStorageActionType,
  deleteFileSuccess,
  deleteFileFailure,
  uploadFileSuccess,
  uploadFileFailure,
  reloadFilesFailure,
  reloadFilesSuccess,
  validateFileSuccess,
  changeFileNameFailure,
  changeFileNameSuccessful,
  clearFeedFailure,
  receiveAllFiles,
  receiveAllFilesSuccess,
  receiveAllFilesFailure,
  receiveFromTodaySuccess,
  receiveFromToday,
  receiveFromTodayFailure,
  clearFeedSuccess,
  validateUploadedFileSuccess,
  validateUploadedFile,
  setReceiveAllCancelled,
  setReceiveFromTodayCancelled,
  changeFileTypeSuccessful,
} from "./data-storage.actions";

import { Roles, MethodsName, WsMethods } from "../../utils/ws-methods";
import { IFileDto } from "./data-storage.store";
import { generatedId } from "../../utils/file-id-generator";
import { setLastError } from "../global/global.actions";
import { validateAllFiles } from "../validation/validation.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { SuccessMessages } from "../../utils/notifications.messages";
import { toggleUploadModalWindow, toggleValidationModalWindow } from "../common/common.actions";

import { getLastUploadedFile } from "./data-storage.selectors";

import { Dispatch } from "redux";
import wsClient from "../../api/ws-client";

const logError = logger.error("data-storage.saga");
const logInfo = logger.info("data-storage.saga");
let backendClient: BackendClient;

export function* downloadFile(action: Action<any>) {
  try {
    const { id, fileName } = action.payload;
    const { file } = yield call([backendClient, ApiMethods.DownloadFile], id);
    saveAs(new Blob([file]), `${fileName}`);
    yield put(downloadFileSuccess());
    yield put(showSuccessfulRequest(SuccessMessages.SHOW_SUCCESSFULL_DOWNLOAD_FILE));
  } catch (e) {
    yield put(setLastError(e));
    const message = `Could not download file ${e?.message || ""}`;
    yield put(downloadFileFailure(message));
    yield put(showFailedRequest(message));
    logError(message);
  }
}

export function* deleteFile(action: Action<string[]>) {
  try {
    const id = action.payload;

    yield call([backendClient, ApiMethods.DeleteFiles], id);
    yield put(deleteFileSuccess(id));
    yield put(showSuccessfulRequest(SuccessMessages.SHOW_SUCCESSFULL_DELTE_FILE));
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    const message = `Could not delete file ${e?.message || ""}`;
    yield put(deleteFileFailure(message));
    logError(message);
  }
}

export function* uploadFile(action: Action<any>) {
  try {
    const file = action.payload.files[0];

    const uploadedFile: IFileDto = {
      id: generatedId(),
      filename: file.name,
      size: file.size,
      type: file.type,
      lastmodified: file.lastModified,
    };

    yield call([backendClient, ApiMethods.UploadFile], file);
    yield put(uploadFileSuccess(uploadedFile));
    yield call(reloadFiles);
    yield put(validateUploadedFile());
    yield put(showSuccessfulRequest(SuccessMessages.SHOW_SUCCESSFULL_UPLOAD_FILE));
  } catch (e) {
    yield put(setLastError(e));
    const message = `Could not upload file ${e?.message || ""}`;
    yield put(showFailedRequest(e.message));
    yield put(uploadFileFailure(message));
    logError(message);
  }
}

export function* reloadFiles(): any {
  try {
    const reloadedFiles = yield call([backendClient, ApiMethods.GetFileList]);
    yield put(reloadFilesSuccess(reloadedFiles));
    logInfo(`${Roles.FILE}.${MethodsName.LIST_FILES}`, reloadedFiles);
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    const message = `Could not reload file ${e?.message || ""}`;
    yield put(reloadFilesFailure(message));
    logError(message);
  }
}

export function* hardReload() {
  try {
    yield call(reloadFiles);
    yield put(validateAllFiles());
    yield put(showSuccessfulRequest(SuccessMessages.SHOW_SUCCESSFULL_RELOAD_FILES));
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    const message = `Could not reload file ${e?.message || ""}`;
    yield put(reloadFilesFailure(message));
    logError(message);
  }
}

export function* validateFile(action: Action<any>): any {
  try {
    const id = action.payload;
    const validatedFileLog = yield call([backendClient, ApiMethods.Validate], [id]);
    yield put(validateFileSuccess(validatedFileLog[0]));
    yield put(toggleValidationModalWindow(true));
    yield call(reloadFiles);
  } catch (e) {
    yield put(showFailedRequest(e.message));
    yield put(reloadFilesFailure("Could not validate file " + e.message));
  }
}

export function* startValidateUploadedFile(): any {
  try {
    const files = yield call([backendClient, ApiMethods.GetFileList]);

    if (files.length !== 0) {
      const validatedFileLog = yield call([backendClient, ApiMethods.Validate], [files[files.length - 1].id]);
      yield put(validateUploadedFileSuccess(validatedFileLog));
      const lastUploadedFile = yield select(getLastUploadedFile);

      if (lastUploadedFile.id === validatedFileLog[0].id) {
        yield put(toggleUploadModalWindow(true));
      }

      yield call(reloadFiles);
    }
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    yield put(reloadFilesFailure("Could not validate file " + e.message));
  }
}

function* changeFileName(action: Action<any>) {
  try {
    const { fileName, fileId } = action.payload;

    yield call([backendClient, ApiMethods.RenameFile], fileId, fileName);
    yield put(changeFileNameSuccessful());
    yield call(reloadFiles);
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    yield put(changeFileNameFailure("Could not change file name " + e.message));
  }
}

function* changeFileType(action: Action<any>) {
  try {
    const { fileId, fileType } = action.payload;

    yield call([backendClient, ApiMethods.ChangeFileType], fileId, fileType);
    yield put(changeFileTypeSuccessful());
    yield call(reloadFiles);
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    yield put(changeFileNameFailure("Could not change file name " + e.message));
  }
}

const fetchMoreFiles = ({ moreMessagesAvailable, received }: any) => {
  const { receiveAllCancelled } = store.getState().dataStorage;

  if (received > 0) {
    if (!receiveAllCancelled && moreMessagesAvailable) {
      store.dispatch(receiveAllFiles());
    }
  }

  if (receiveAllCancelled) {
    store.dispatch(setReceiveAllCancelled(false));
  }
};

export function* receiveAll(): any {
  try {
    yield call([backendClient, ApiMethods.ReceiveFiles], false);
    yield put(receiveAllFilesSuccess());

    wsClient.subscribe(`${Roles.AGRIROUTER}.${WsMethods.GET_FEED}`, fetchMoreFiles);
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    const message = `Could not receive all files ${e?.message || ""}`;
    yield put(receiveAllFilesFailure(message));
    logError(message);
  }
}

const fetchMoreFilesForToday = ({ moreMessagesAvailable, received }: any) => {
  const { receiveTodayCancelled } = store.getState().dataStorage;

  if (received > 0) {
    if (!receiveTodayCancelled && moreMessagesAvailable) {
      store.dispatch(receiveFromToday());
    }
  }

  if (receiveTodayCancelled) {
    store.dispatch(setReceiveFromTodayCancelled(false));
  }
};

export function* receiveFilesFromToday(): any {
  try {
    yield call([backendClient, ApiMethods.ReceiveFiles], true);
    yield put(receiveFromTodaySuccess());

    wsClient.subscribe(`${Roles.AGRIROUTER}.${WsMethods.GET_FEED}`, fetchMoreFilesForToday);
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    const message = `Could not receive file from today ${e?.message || ""}`;
    yield put(receiveFromTodayFailure(message));
    logError(message);
  }
}

export function* clearFeeds() {
  try {
    yield call([backendClient, ApiMethods.ClearFeed]);
    yield put(clearFeedSuccess());
  } catch (e) {
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    const message = `Could not clear feed ${e?.message || ""}`;
    yield put(clearFeedFailure(message));
    logError(message);
  }
}

export default function* dataStorageSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);

  yield takeLatest(DataStorageActionType.DOWNLOAD_FILE, downloadFile);
  yield takeLeading(DataStorageActionType.DELETE_FILE, deleteFile);
  yield takeEvery(DataStorageActionType.UPLOAD_FILE, uploadFile);
  yield takeLeading(DataStorageActionType.RELOAD_FILES, hardReload);
  yield takeLeading(DataStorageActionType.VALIDATE_FILE, validateFile);
  yield takeLeading(DataStorageActionType.VALIDATE_UPLOADED_FILE, startValidateUploadedFile);
  yield takeLeading(DataStorageActionType.CHANGE_FILE_NAME, changeFileName);
  yield takeLeading(DataStorageActionType.CHANGE_FILE_TYPE, changeFileType);
  yield takeLeading(DataStorageActionType.RECEIVE_ALL, receiveAll);
  yield takeEvery(DataStorageActionType.RECEIVE_FROM_TODAY, receiveFilesFromToday);
  yield takeLeading(DataStorageActionType.CLEAR_FEED, clearFeeds);
  yield takeLeading(DataStorageActionType.UPDATE_FILE_LIST, reloadFiles);
}
