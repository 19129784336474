import React, { FunctionComponent, useMemo, useState, useCallback } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import TransitionsModal from "../../common/modal-winidow/modal-window.component";
import { IFileDto } from "../../../store/data-storage/data-storage.store";
import { Endpoint } from "../../../store/endpoints/endpoints.store";
import { AccordionComponent } from "../../common/accordion/accordion.component";
import FilesTableContainer from "../../files-table/files-table.component";
import AppButton from "../../common/button/button.component";
import { ButtonSize } from "../../../shared/button-style";
import { FileTypes, TitlesByType } from "../../../shared/accordion-titles";
import { Colors } from "../../../theme/colors";

import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { formatUnsupportedTypesMessage } from "../../../utils/unsupported-types-message-formatter";

export interface OwnProps {
  open: boolean;
  closeModal: () => void;
  processFiles: (files: IFileDto[]) => void;
  endpoints: Endpoint[];
  publishMode: boolean;
}

export interface ConnectedProps {
  files: IFileDto[];
  filesByType: { [key: string]: any };
  sendFilesProgress: boolean;
  publishFilesProgress: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButton: {
      backgroundColor: Colors.Grey,
      "&:hover": {
        backgroundColor: Colors.DarkGrey,
      },
    },
    warning: {
      borderRadius: 4,
      backgroundColor: Colors.Orange,
      color: Colors.White,
      padding: 8,
      display: "flex",
      maxHeight: 50,
    },
    textWrapper: {
      lineHeight: "20px",
    },
  })
);

const UNSUPPORTED_TYPES = [FileTypes.EFDI_TIMELOG, FileTypes.UNKNOWN];

interface Props extends OwnProps, ConnectedProps {}

const SendFilesWindow: FunctionComponent<Props> = (props: Props) => {
  const {
    publishMode,
    open,
    endpoints = [],
    files = [],
    filesByType,
    sendFilesProgress,
    publishFilesProgress,
  } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState<IFileDto[]>([]);
  const [started, setStarted] = useState(false);
  const endpointsNames = useMemo(() => endpoints.map(({ name }) => name), [endpoints]);

  const toggleSelection = useCallback(
    (id: string) => {
      const selectedIndex = selected.findIndex(({ id: selectedId }) => id === selectedId);

      if (selectedIndex === -1) {
        const selectedValue = files.find((item: IFileDto) => item.id === id);

        if (selectedValue) {
          setSelected([...selected, selectedValue]);
        }
      } else {
        setSelected(selected.filter(({ id: selectedId }) => selectedId !== id));
      }
    },
    [selected, files]
  );

  const send = useCallback(() => {
    setStarted(true);
    props.processFiles(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const areFilesHaveUnsupportedTypes = useMemo(
    () =>
      selected.some(
        (file: IFileDto) => file.type === FileTypes.UNKNOWN || file.type === FileTypes.EFDI_TIMELOG
      ),
    [selected]
  );

  const unsupportedTypes = useMemo(
    () =>
      Array.from(
        new Set(selected.map(({ type }) => type).filter((type) => UNSUPPORTED_TYPES.includes(type)))
      ),
    [selected]
  );

  return (
    <TransitionsModal closeModal={props.closeModal} open={open} size="800px">
      <Typography variant="h6">{`${
        publishMode ? "Publish files" : `Send files to ${endpointsNames.join(", ")}`
      } `}</Typography>
      {areFilesHaveUnsupportedTypes && (
        <div className={classes.warning}>
          <div style={{ marginRight: 6, display: "flex" }}>
            <ReportProblemIcon />
          </div>
          <div
            className={classes.textWrapper}
            dangerouslySetInnerHTML={{
              __html: formatUnsupportedTypesMessage(selected, unsupportedTypes, publishMode),
            }}
          />
        </div>
      )}
      {Object.keys(filesByType).map((type: string) => {
        return (
          filesByType[type].length !== 0 && (
            <AccordionComponent
              key={type}
              title={`${TitlesByType[type]} (${filesByType[type].length})`}
              panelId={TitlesByType[type]}
              defaultExpanded={true}
            >
              <FilesTableContainer
                files={filesByType[type]}
                panelName={TitlesByType[type]}
                onUpdateSelected={(e: any, id: string) => toggleSelection(id)}
                hasControls={false}
                allSelectedFiles={selected.map((file: IFileDto) => file.id)}
              />
            </AccordionComponent>
          )
        );
      })}
      <div className={classes.buttonsContainer}>
        <AppButton size={ButtonSize.TINY_BUTTON} className={classes.cancelButton} handler={props.closeModal}>
          Cancel
        </AppButton>
        <AppButton
          size={ButtonSize.TINY_BUTTON}
          disabled={selected.length === 0 || started || areFilesHaveUnsupportedTypes}
          handler={send}
          progress={publishMode ? publishFilesProgress : sendFilesProgress}
        >
          {publishMode ? "Publish" : "Send"}
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default SendFilesWindow;
