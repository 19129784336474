import createReducer from "../createReducer";
import { Action } from "../action";

import { AuthenticationActionType } from "./authentication.actions";
import initialState, { AuthenticationState, IUser } from "./authentication.store";
import { loadFailed, loadPending, loadSuccess } from "../loading-status";

export default createReducer(initialState, {
  [AuthenticationActionType.LOGIN](state: AuthenticationState): AuthenticationState {
    return { ...state, loginInProgress: true, loadStatus: loadPending() };
  },
  [AuthenticationActionType.LOGIN_FAILURE](
    state: AuthenticationState,
    action: Action<string>
  ): AuthenticationState {
    return { ...state, loginInProgress: false, loadStatus: loadFailed(action.payload) };
  },
  [AuthenticationActionType.LOGIN_SUCCESSFUL](state: AuthenticationState): AuthenticationState {
    return { ...state, isAuthenticated: true, loginInProgress: false, loadStatus: loadSuccess() };
  },
  [AuthenticationActionType.RELOGIN](state: AuthenticationState): AuthenticationState {
    return { ...state, isAuthenticated: false };
  },
  [AuthenticationActionType.RELOGIN_SUCCESSFULL](
    state: AuthenticationState,
    { payload }: Action<IUser>
  ): AuthenticationState {
    return { ...state, isAuthenticated: true, user: payload || {} };
  },
});
