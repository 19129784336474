import React, { FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import withAuthService from "../shared/services/with-auth.service";
import RoutePaths from "./route-paths";

interface Props extends RouteProps {
  isLoggedIn: boolean;
}

const PrivateRoute: FunctionComponent<Props> = ({ component: Component, isLoggedIn, ...rest }) => {
  if (!isLoggedIn) {
    return <Redirect to={RoutePaths.Login} />;
  }

  return <Route {...rest} component={Component} />;
};

export default withAuthService(PrivateRoute);
