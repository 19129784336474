import React, { FunctionComponent, useState, useCallback, useEffect, useMemo } from "react";
import moment from "moment";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Popper,
  Paper,
} from "@material-ui/core";

import PageviewIcon from "@material-ui/icons/Pageview";
import { ReactComponent as Details } from "../../images/details.svg";

import {
  Cancel as ClearIcon,
  CancelOutlined as CancelOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  FilterList as FilterListIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";

import TableComponent from "../common/table/table.component";
import { Colors } from "../../theme/colors";
import { OwnProps } from "./logging.container";
import { FILTER_FORMAT, LOG_ENTRY_FORMAT } from "../../shared/time-formats";
import AppButton from "../common/button/button.component";
import { ButtonSize } from "../../shared/button-style";
import TraceLoggingWindow from "../modal-windows/trace-logging-window/trace-logging-window.component";
import { LogEntry, LogEntryType } from "../../api/backend-api";

export interface ConnectedState {
  entries: LogEntry[];
  timeFrom: number;
  timeTo: number;
  traceLoggingStatusEnabledTill: number;
}

export interface ConnectedDispatch {
  clearLogs: () => any;
}

interface LoggingProps extends ConnectedState, ConnectedDispatch, OwnProps {
  isBottomFixed?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCell: {
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    cellContainer: {
      display: "flex",
      alignItems: "center",

      "&>span": {
        marginRight: "20px",
      },
      padding: "0px",
    },
    clearIcon: {
      color: Colors.LightGrey,
      fontSize: "1em",
    },
    typeFilter: {
      "&>div": { paddingTop: "5px", paddingBottom: "5px" },
    },
    timeFilterOptions: {
      padding: "10px",
      zIndex: 9999,
    },
    fixedLabel: {
      width: "60px",
    },
    smallContainer: {
      paddingBottom: 1,

      "& .MuiPaper-root": {
        height: 220,
      },
    },
    viewLogIcon: {
      color: `${Colors.Grey}`,
      fontSize: "25px",
      cursor: "pointer",
      "&:hover": {
        color: `${Colors.DarkGrey}`,
      },
    },
    visibleAdornment: {
      display: "flex",
    },
    hiddenAdornment: {
      visibility: "hidden",
    },
  })
);

const getIcon = (type: string) => {
  switch (type) {
    case LogEntryType.ERROR:
      return <CancelOutlinedIcon color="error" />;
    case LogEntryType.INFO:
      return <InfoOutlinedIcon color="primary" />;
    case LogEntryType.TRACE:
      return <Details style={{ width: 24, height: 24, color: Colors.Grey }} />;
    default:
      return null;
  }
};

const ROWS_PER_PAGE = [5, 10, 20, 50];

const Logging: FunctionComponent<LoggingProps> = (props: LoggingProps) => {
  const { entries = [], full, timeFrom, timeTo, traceLoggingStatusEnabledTill } = props;
  const classes = useStyles();

  const [filteredEntries, setFilteredEntries] = useState<LogEntry[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[ROWS_PER_PAGE.length - 1]);
  const [searchTerm, setSearchTerm] = useState("");
  const [typeForFilter, setTypeForFilter] = useState<string>("");
  const [timeFilterAnchorEl, setTimeFilterAnchorEl] = React.useState<null | HTMLElement>(null);
  const [defaultTimeFrom, setDefaultTimeFrom] = useState("");
  const [defaultTimeTo, setDefaultTimeTo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [openLogging, setLoggingOpen] = useState(false);
  const [traceMsg, setTraceMsg] = useState("");

  const isTraceLoggingStatusEnabled = useMemo(
    () => traceLoggingStatusEnabledTill - new Date().getTime() > 0,
    [traceLoggingStatusEnabledTill]
  );

  const onChangePage = useCallback((event: unknown, newPage: number) => setPage(newPage), []);

  const setTimeToValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = moment(event.target.value);
      setTo(value.isSameOrBefore(defaultTimeTo) ? event.target.value : defaultTimeTo);
    },
    [defaultTimeTo]
  );

  const setTimeFromValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = moment(event.target.value);
      setFrom(value.isSameOrAfter(defaultTimeFrom) ? event.target.value : defaultTimeFrom);
    },
    [defaultTimeFrom]
  );

  const onChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const toggleTimeFilterOptions = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      setTimeFilterAnchorEl(timeFilterAnchorEl ? null : event.currentTarget),
    [timeFilterAnchorEl]
  );

  const clearSearchTerm = useCallback(() => setSearchTerm(""), []);

  const handleTypeForFilter = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeForFilter(event.target.value as string);
  }, []);

  // tslint:disable-next-line: no-shadowed-variable
  const filterBySearchTerm = (text: string, searchTerm: string) => {
    if (searchTerm) {
      return text?.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return true;
  };

  const filterByType = (type: string, selectedType: string) => {
    if (selectedType) {
      return type === selectedType;
    }

    return true;
  };

  const filterByTimeFrom = (time: string, limit: string) => {
    if (limit) {
      return moment(time).set({ second: 0, millisecond: 0 }).isSameOrAfter(limit);
    }

    return true;
  };

  const filterByTimeTo = (time: string, limit: string) => {
    if (limit) {
      return moment(time).set({ second: 0, millisecond: 0 }).isSameOrBefore(limit);
    }

    return true;
  };

  const openLoggingModal = useCallback((message: any) => {
    setLoggingOpen(true);
    setTraceMsg(message);
  }, []);

  const closeLoggingModal = useCallback(() => {
    setLoggingOpen(false);
  }, []);

  useEffect(() => {
    setFilteredEntries(
      entries.filter(
        ({ text, type, time }: LogEntry) =>
          filterBySearchTerm(text, searchTerm) &&
          filterByType(type, typeForFilter) &&
          filterByTimeFrom(time, from) &&
          filterByTimeTo(time, to)
      )
    );
  }, [entries, typeForFilter, searchTerm, from, to]);

  useEffect(() => {
    const formattedTimeFrom = moment(timeFrom).format(FILTER_FORMAT);
    const formattedTimeTo = moment(timeTo).format(FILTER_FORMAT);
    setDefaultTimeFrom(formattedTimeFrom);
    setDefaultTimeTo(formattedTimeTo);
    setFrom(formattedTimeFrom);
    setTo(formattedTimeTo);
  }, [timeFrom, timeTo]);

  return (
    <div {...(!full ? { className: classes.smallContainer } : {})}>
      <TableComponent
        stickyHeader={true}
        tableHead={
          <TableRow>
            <TableCell>
              {full && (
                <Select
                  value={typeForFilter}
                  onChange={handleTypeForFilter}
                  variant="outlined"
                  displayEmpty={true}
                  className={classes.typeFilter}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {[LogEntryType.ERROR, LogEntryType.INFO].map((type: string) => (
                    <MenuItem key={type} value={type}>
                      {getIcon(type)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </TableCell>
            <TableCell className={classes.headerCell}>
              <div className={classes.cellContainer}>
                <span>Timestamp</span>
                {full && (
                  <>
                    <IconButton
                      aria-label="toggle time filtering options"
                      onClick={toggleTimeFilterOptions}
                      size="small"
                    >
                      <FilterListIcon />
                    </IconButton>
                    <Popper
                      style={{ zIndex: 9999 }}
                      anchorEl={timeFilterAnchorEl}
                      open={!!timeFilterAnchorEl}
                      placement="bottom-start"
                    >
                      <Paper className={classes.timeFilterOptions}>
                        <div className={classes.cellContainer}>
                          <span className={classes.fixedLabel}>From</span>
                          <TextField
                            type="datetime-local"
                            value={from}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: defaultTimeFrom,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ minWidth: "24px" }}>
                                  {from !== defaultTimeFrom && (
                                    <IconButton
                                      aria-label="clear time from"
                                      onClick={() => setFrom(defaultTimeFrom)}
                                      onMouseDown={() => setFrom(defaultTimeFrom)}
                                      size="small"
                                    >
                                      <ClearIcon className={classes.clearIcon} />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            onChange={setTimeFromValue}
                          />
                        </div>
                        <div className={classes.cellContainer}>
                          <span className={classes.fixedLabel}>To</span>
                          <TextField
                            type="datetime-local"
                            value={to}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ max: defaultTimeTo }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ minWidth: "24px" }}>
                                  {to !== defaultTimeTo && (
                                    <IconButton
                                      aria-label="clear time to"
                                      onClick={() => setTo(defaultTimeTo)}
                                      onMouseDown={() => setTo(defaultTimeTo)}
                                      size="small"
                                    >
                                      <ClearIcon className={classes.clearIcon} />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            onChange={setTimeToValue}
                          />
                        </div>
                      </Paper>
                    </Popper>
                  </>
                )}
              </div>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <div className={classes.cellContainer} style={{ justifyContent: "space-between" }}>
                <span>Message</span>
                {full && (
                  <TextField
                    size="small"
                    variant="outlined"
                    value={searchTerm}
                    placeholder={"Search..."}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={searchTerm ? classes.visibleAdornment : classes.hiddenAdornment}
                        >
                          <IconButton
                            aria-label="clear search term"
                            onClick={clearSearchTerm}
                            onMouseDown={clearSearchTerm}
                            size="small"
                          >
                            <ClearIcon className={classes.clearIcon} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                <AppButton
                  handler={props.clearLogs}
                  size={ButtonSize.TINY_BUTTON}
                  disabled={filteredEntries.length === 0}
                >
                  <DeleteIcon />
                  Clear
                </AppButton>
              </div>
            </TableCell>
          </TableRow>
        }
        tableBody={filteredEntries
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(({ time, text, type, traceInfo }: LogEntry) => (
            <TableRow key={time}>
              <TableCell>{getIcon(type)}</TableCell>
              <TableCell
                style={{
                  display: "table-cell",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: 6 }}>{moment(time).format(LOG_ENTRY_FORMAT)}</p>
                  {isTraceLoggingStatusEnabled && traceInfo && (
                    <PageviewIcon
                      className={classes.viewLogIcon}
                      onClick={() => openLoggingModal(traceInfo)}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell>{text}</TableCell>
            </TableRow>
          ))}
        empty={filteredEntries.length === 0}
      />
      {full && (
        <TablePagination
          component="div"
          rowsPerPageOptions={ROWS_PER_PAGE}
          count={filteredEntries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
      {openLogging && (
        <TraceLoggingWindow open={openLogging} closeModal={closeLoggingModal} traceInfo={traceMsg} />
      )}
    </div>
  );
};

export default Logging;
