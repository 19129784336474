export interface TelemetryEntry {
  unit: string;
  value: string | number | { lat: number; long: number };
}

export interface Feature {
  geometry: {
    coordinates: number[];
    type: string;
  };
  type: string;
}

export interface GeoJSON {
  features: Feature[];
  type: string;
}

export interface CaptionElement {
  label: string;
  span?: number;
}

export interface TelemetryCaption {
  device: CaptionElement[];
  deviceelement: CaptionElement[];
  logvalues: CaptionElement[];
}

export interface TelemetryState {
  downloadInProgress: boolean;
  loadTelemetryInProgress: boolean;
  telemetry: TelemetryEntry[][];
  geoJSON: GeoJSON | null;
  caption: TelemetryCaption;
  total: number;
  offset: number;
  isInited: boolean;
}

export const state: TelemetryState = {
  downloadInProgress: false,
  loadTelemetryInProgress: false,
  telemetry: [],
  geoJSON: null,
  caption: { device: [], deviceelement: [], logvalues: [] },
  total: 0,
  isInited: false,
  offset: 0,
};

export default state;
