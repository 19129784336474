import React, { FunctionComponent } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import TransitionsModal from "../../common/modal-winidow/modal-window.component";
import AppButton from "../../../components/common/button/button.component";
import { ButtonSize } from "../../../shared/button-style";
import { Colors } from "../../../theme/colors";

export interface ConnectedDispatch {
  deleteFiles: (ids: string[]) => void;
}

export interface OwnProps extends ConnectedDispatch {
  open: boolean;
  closeModal: () => void;
  deletedFileIds: string[];
  onClearSelected?: (ids: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "center",
    },
    confirmButton: {
      backgroundColor: Colors.Red,
      "&:hover": {
        backgroundColor: Colors.Red2,
      },
    },
    rejectButton: {
      backgroundColor: Colors.Orange,
      "&:hover": {
        backgroundColor: Colors.DarkOrange,
      },
    },
  })
);

const DeleteConfirmationWindow: FunctionComponent<OwnProps> = ({
  closeModal,
  open,
  deletedFileIds,
  deleteFiles,
  onClearSelected,
}) => {
  const classes = useStyles();

  const deleteCurrentFile = () => {
    deleteFiles(deletedFileIds);
    if (onClearSelected) {
      onClearSelected(deletedFileIds);
    }
    closeModal();
  };

  return (
    <TransitionsModal closeModal={closeModal} open={open} size="480px">
      <Typography variant="h6">{`Are you sure you want to delete ${
        deletedFileIds.length > 1 ? "selected files" : "this file"
      } ?`}</Typography>
      <div className={classes.buttonsContainer}>
        <AppButton
          className={classes.confirmButton}
          size={ButtonSize.TINY_BUTTON}
          handler={deleteCurrentFile}
        >
          Yes
        </AppButton>
        <AppButton className={classes.rejectButton} size={ButtonSize.TINY_BUTTON} handler={closeModal}>
          No
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default DeleteConfirmationWindow;
