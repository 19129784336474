import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { init } from "../../store/global/global.actions";
import Preloader from "../../components/preloader/preloader.component";
import AuthService from "../../shared/services/auth.service";

const LoginComponent = () => {
  const dispatch = useDispatch();
  const isLoggedIn = AuthService.getInstance().isLoggedIn;

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(init());
    }
  }, [dispatch, isLoggedIn]);

  return isLoggedIn ? null : <Preloader />;
};

export default LoginComponent;
