enum ApiMethods {
  SetToken = "setToken",
  GetUrl = "getUrl",
  Auth = "auth",
  UpdateToken = "updateToken",
  Logout = "logout",
  FetchLogs = "fetchLogs",
  FetchLoggingStatus = "fetchLoggingStatus",
  ChangeLoggingStatus = "changeLoggingStatus",
  FetchTraceLoggingStatus = "fetchTraceLoggingStatus",
  ChangeTraceLoggingStatus = "changeTraceLoggingStatus",
  DownloadFile = "downloadFile",
  RenameFile = "renameFile",
  ChangeFileType = "changeFileType",
  DownloadIsoxml = "downloadIsoxml",
  GetFileList = "getFileList",
  ExportEfdi = "exportEfdi",
  UploadFile = "uploadFile",
  DeleteFiles = "deleteFiles",
  Validate = "validate",
  UpdateTelemetry = "updateTelemetry",
  JustifyOnboarding = "justifyOnboarding",
  ClearLogs = "clearLogs",
  ReceiveFiles = "receiveFiles",
  ClearFeed = "clearFeed",
  SendFiles = "sendFiles",
  PublishFiles = "publishFiles",
  SetCapabilities = "setCapabilities",
  GetCapabilities = "getCapabilities",
  GetEndpoints = "getEndpoints",
  UpdateEndpoints = "updateEndpoints",
  GetOnboardStatus = "getOnboardStatus",
  Onboard = "onboard",
  GetOnboardUrl = "getOnboardUrl",
  GetReonboardUrl = "getReonboardUrl",
  Reconnect = "reconnect",
  Offboard = "offboard",
  SetSubscriptions = "setSubscriptions",
  GetSubscriptions = "getSubscriptions",
  GetListFormats = "getListFormats",
  GetSimulationProgress = "getSimulationProgress",
  GetSimulatorFileList = "getSimulatorFileList",
  GetTimeLogInfo = "getTimeLogInfo",
  Simulate = "simulate",
  Pause = "pause",
  Resume = "resume",
  Stop = "stop",
  Forward = "forward",
  GetEnvironments = "getEnvironment",
}

export default ApiMethods;
