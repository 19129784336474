import { ExportParams } from "../../pages/simulation/simulation.component";
import { ConfigurationParams } from "../../pages/simulation/simulator-configuration.component";
import { Action } from "../action";
import { Device, Progress, SimulatorFile, SimulatorTimelogEntry } from "./simulation.store";

export enum SimulationActionType {
  GET_FILES = "GET_FILES",
  GET_FILES_SUCCESSFUL = "GET_FILES_SUCCESS",
  GET_FILES_FAILURE = "GET_FILES_FAILURE",

  GET_TIMELOGS = "GET_TIMELOGS",
  GET_TIMELOGS_SUCCESSFUL = "GET_TIMELOGS_SUCCESSFUL",
  GET_TIMELOGS_FAILURE = "GET_TIMELOGS_FAILURE",

  START_SIMULATION = "START_SIMULATION",
  START_SIMULATION_SUCCESSFUL = "START_SIMULATION_SUCCESSFUL",
  START_SIMULATION_FAILURE = "START_SIMULATION_FAILURE",

  STOP_SIMULATION = "STOP_SIMULATION",
  STOP_SIMULATION_SUCCESSFUL = "STOP_SIMULATION_SUCCESSFUL",
  STOP_SIMULATION_FAILURE = "STOP_SIMULATION_FAILURE",

  PAUSE_SIMULATION = "PAUSE_SIMULATION",
  PAUSE_SIMULATION_SUCCESSFUL = "PAUSE_SIMULATION_SUCCESSFUL",
  PAUSE_SIMULATION_FAILURE = "PAUSE_SIMULATION_FAILURE",

  RESUME_SIMULATION = "RESUME_SIMULATION",
  RESUME_SIMULATION_SUCCESSFUL = "RESUME_SIMULATION_SUCCESSFUL",
  RESUME_SIMULATION_FAILURE = "RESUME_SIMULATION_FAILURE",

  FORWARD_SIMULATION = "FORWARD_SIMULATION",
  FORWARD_SIMULATION_SUCCESSFUL = "FORWARD_SIMULATION_SUCCESSFUL",
  FORWARD_SIMULATION_FAILURE = "FORWARD_SIMULATION_FAILURE",

  SET_PROGRESS = "SET_PROGRESS",
  CHECK_PROGRESS = "CHECK_PROGRESS",
  SET_CONFIGURATION = "SET_CONFIGURATION",
  RESET = "RESET",

  EXPORT = "EXPORT",
  EXPORT_SUCCESSFUL = "EXPORT_SUCCESSFUL",
  EXPORT_FAILURE = "EXPORT_FAILURE",
}

export const getFiles = (): Action<null> => ({
  type: SimulationActionType.GET_FILES,
  payload: null,
});

export const getFilesSuccessful = (files: SimulatorFile[]): Action<SimulatorFile[]> => ({
  type: SimulationActionType.GET_FILES_SUCCESSFUL,
  payload: files,
});

export const getFilesFailure = (): Action<null> => ({
  type: SimulationActionType.GET_FILES_FAILURE,
  payload: null,
});

export const getTimelogs = (filesIds: string[]): Action<string[]> => ({
  type: SimulationActionType.GET_TIMELOGS,
  payload: filesIds,
});

export const getTimelogsSuccessful = (
  devices: Device[],
  timelogs: SimulatorTimelogEntry[]
): Action<{ devices: Device[]; timelogs: SimulatorTimelogEntry[] }> => ({
  type: SimulationActionType.GET_TIMELOGS_SUCCESSFUL,
  payload: { devices, timelogs },
});

export const getTimelogsFailure = (): Action<null> => ({
  type: SimulationActionType.GET_TIMELOGS_FAILURE,
  payload: null,
});

export const startSimulation = (data: ConfigurationParams): Action<any> => ({
  type: SimulationActionType.START_SIMULATION,
  payload: data,
});

export const startSimulationSuccessful = (): Action<null> => ({
  type: SimulationActionType.START_SIMULATION_SUCCESSFUL,
  payload: null,
});

export const startSimulationFailure = (errorMessage: string): Action<string> => ({
  type: SimulationActionType.START_SIMULATION_FAILURE,
  payload: errorMessage,
});

export const stopSimulation = (): Action<null> => ({
  type: SimulationActionType.STOP_SIMULATION,
  payload: null,
});

export const stopSimulationSuccessful = (): Action<null> => ({
  type: SimulationActionType.STOP_SIMULATION_SUCCESSFUL,
  payload: null,
});

export const stopSimulationFailure = (errorMessage: string): Action<string> => ({
  type: SimulationActionType.STOP_SIMULATION_FAILURE,
  payload: errorMessage,
});

export const forwardSimulation = (): Action<null> => ({
  type: SimulationActionType.FORWARD_SIMULATION,
  payload: null,
});

export const forwardSimulationSuccessful = (): Action<null> => ({
  type: SimulationActionType.FORWARD_SIMULATION_SUCCESSFUL,
  payload: null,
});

export const forwardSimulationFailure = (errorMessage: string): Action<string> => ({
  type: SimulationActionType.FORWARD_SIMULATION_FAILURE,
  payload: errorMessage,
});

export const pauseSimulation = (): Action<null> => ({
  type: SimulationActionType.PAUSE_SIMULATION,
  payload: null,
});

export const pauseSimulationSuccessful = (): Action<null> => ({
  type: SimulationActionType.PAUSE_SIMULATION_SUCCESSFUL,
  payload: null,
});

export const pauseSimulationFailure = (errorMessage: string): Action<string> => ({
  type: SimulationActionType.PAUSE_SIMULATION_FAILURE,
  payload: errorMessage,
});

export const resumeSimulation = (): Action<null> => ({
  type: SimulationActionType.RESUME_SIMULATION,
  payload: null,
});

export const resumeSimulationSuccessful = (): Action<null> => ({
  type: SimulationActionType.RESUME_SIMULATION_SUCCESSFUL,
  payload: null,
});

export const resumeSimulationFailure = (errorMessage: string): Action<string> => ({
  type: SimulationActionType.RESUME_SIMULATION_FAILURE,
  payload: errorMessage,
});

export const setProgress = (progress: Progress): Action<Progress> => ({
  type: SimulationActionType.SET_PROGRESS,
  payload: progress,
});

export const setConfiguration = (configuration: ConfigurationParams): Action<ConfigurationParams> => ({
  type: SimulationActionType.SET_CONFIGURATION,
  payload: configuration,
});

export const reset = (): Action<null> => ({
  type: SimulationActionType.RESET,
  payload: null,
});

export const exportEfdi = (
  id: string,
  params: ExportParams,
  filename: string
): Action<{ id: string; params: ExportParams; filename: string }> => ({
  type: SimulationActionType.EXPORT,
  payload: { id, params, filename },
});

export const exportEfdiSuccessfull = (): Action<null> => ({
  type: SimulationActionType.EXPORT_SUCCESSFUL,
  payload: null,
});

export const exportEfdiFailure = (): Action<null> => ({
  type: SimulationActionType.EXPORT_FAILURE,
  payload: null,
});
