import AuthService, { C_SESSION } from "./auth.service";
import { SocketRequest, SocketResponse } from "../../utils/ws-methods";

export const NOT_LOGGED_ERROR = -32000;
export const WRONG_TELEMETRY_FILE = -32002;

interface SubscribedHandlers {
  [method: string]: (data?: any) => any;
}

interface BoundHandlers {
  [id: number]: (data?: any) => any;
}

export default class WSService {
  static instance: WSService;
  private ws: WebSocket | undefined;
  private ready: boolean;
  private lastId: number;

  private subscribedHandlers: SubscribedHandlers;
  private boundHandlers: BoundHandlers;
  private boundErrorHandlers: BoundHandlers;

  private timer: any;

  constructor() {
    if (WSService.instance) {
      throw new Error("Instantiation failed: use WSService.getInstance() instead of new");
    } else {
      this.ready = false;
      this.lastId = 0;
      this.subscribedHandlers = {};
      this.boundHandlers = {};
      this.boundErrorHandlers = {};

      WSService.instance = this;
    }
  }

  static getInstance() {
    if (WSService.instance) {
      return WSService.instance;
    }

    return new WSService();
  }

  async wsInit() {
    if (!WSService.instance) {
      return;
    }

    return new Promise((resolve: (data?: any) => any, reject: (error?: any) => any) => {
      if (this.ready) {
        return resolve();
      }

      const {
        REACT_APP_BACKEND_HOST: host,
        REACT_APP_BACKEND_PORT: port,
        REACT_APP_USE_SSL: ssl,
      } = process.env;

      this.ws = new WebSocket(
        `${ssl === "on" ? "wss" : "ws"}://${host}${port ? `:${port}` : ""}/websocket/sdsd`
      );

      document.cookie = "X-Authorization=" + AuthService.getInstance().getToken() + "; path=/";

      this.ws.onopen = () => {
        clearInterval(this.timer);

        if (this.ws) {
          this.ws.send(
            JSON.stringify({
              [C_SESSION]: AuthService.getInstance().getCookie(),
            })
          );
          this.ready = true;
        }

        return resolve();
      };

      this.ws.onerror = () => {
        this.ready = false;

        this.timer = setInterval(() => {
          this.wsInit();
        }, 5000);
      };

      this.ws.onclose = () => {
        if (!this.ready) {
          return;
        }

        this.timer = setInterval(() => {
          this.wsInit();
        }, 5000);
      };

      this.ws.onmessage = (ev: any) => {
        const response: SocketResponse = JSON.parse(ev.data);
        const { message, error, id } = response;

        if (error) {
          const handler = this.boundErrorHandlers[id];

          if (handler && typeof handler === "function") {
            return handler({ error, id });
          }
        }

        if (message) {
          const handler = this.boundHandlers[id];

          if (handler && typeof handler === "function") {
            return handler({ message, id });
          }
        }

        const { endpoint, method, params } = response;
        const responseMethod: string = endpoint && method ? `${endpoint}.${method}` : "";

        if (Object.keys(this.subscribedHandlers).indexOf(responseMethod) !== -1) {
          const listener = this.subscribedHandlers[responseMethod];

          if (listener && typeof listener === "function") {
            return listener(message || params[0]);
          }
        }

        return reject();
      };
    });
  }

  async sendRequest(request: SocketRequest) {
    return new Promise((resolve: (data: any) => any, reject: (error?: any) => any) => {
      if (!this.ws || !this.ready) {
        return reject();
      }

      this.lastId++;
      request = { ...request, id: this.lastId };
      this.boundHandlers[this.lastId] = resolve;
      this.boundErrorHandlers[this.lastId] = reject;
      this.ws.send(JSON.stringify(request));
    })
      .then(({ result, id }: any) => {
        if (id) {
          delete this.boundHandlers[id];
          delete this.boundErrorHandlers[id];
        }

        return result;
      })
      .catch(({ error, id }: any) => {
        if (id) {
          delete this.boundHandlers[id];
          delete this.boundErrorHandlers[id];
        }

        throw error;
      });
  }

  isReady(): boolean {
    return this.ready;
  }

  wsClose() {
    if (this.ws) {
      this.ready = false;
      this.lastId = 0;
      this.subscribedHandlers = {};
      this.boundHandlers = {};
      this.boundErrorHandlers = {};
      this.ws.close();
    }
  }

  subscribe(method: string, func: (data: any) => any) {
    this.subscribedHandlers[method] = func;
  }

  unsubscribe(method: string) {
    delete this.subscribedHandlers[method];
  }
}
