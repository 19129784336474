import React, { FunctionComponent, useMemo } from "react";
import { NavLink } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import PaperComponent from "../../components/paper/paper.component";
import { Colors } from "../../theme/colors";
import { OwnProps } from "./endpoints-details.container";
import { Endpoint } from "../../store/endpoints/endpoints.store";
import RoutePaths from "../../routes/route-paths";
import Capabilities from "../../components/capabilities/capabilities.container";
import { Capability, CapabilityDirection } from "../../api/backend-api";

export interface ConnectedState {
  endpoint: Endpoint;
  capabilities: Capability[];
  isMqtt: boolean;
}

interface EndpointDetailsProps extends ConnectedState, OwnProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backLink: {
      display: "flex",
      justifyContent: "flex-start",
      textDecoration: "none",
      color: theme.palette.primary.main,

      "&:hover": {
        color: Colors.DarkGreen,
      },
    },
    heading: {
      paddingTop: "30px",

      "&>span": {
        display: "block",
      },
    },
    headingTitle: {
      textTransform: "uppercase",
      fontSize: "10px",
      letterSpacing: "2px",
    },
    headingContent: {
      fontWeight: "bold",
      fontSize: "14px",
    },
    capabilities: {
      paddingRight: "20px",
    },
    section: {
      width: "500px",
    },
  })
);

const EndpointsDetails: FunctionComponent<EndpointDetailsProps> = ({
  endpoint: { name, accepts = [], sends = [] },
  capabilities,
  isMqtt,
}) => {
  const classes = useStyles();

  const preparedCapabilities = useMemo(
    () =>
      capabilities.map((capability) => {
        const sending = sends.includes(capability.type);
        const accepting = accepts.includes(capability.type);

        if (sending && accepting) {
          return { ...capability, direction: CapabilityDirection.SEND_AND_RECEIVE };
        } else if (sending) {
          return { ...capability, direction: CapabilityDirection.SEND };
        } else if (accepting) {
          return { ...capability, direction: CapabilityDirection.RECEIVE };
        }

        return capability;
      }),
    [capabilities, accepts, sends]
  );

  return (
    <PaperComponent>
      <div style={{ width: "140px" }}>
        <NavLink to={RoutePaths.Endpoints} className={classes.backLink}>
          <ArrowBackIcon fontSize="small" />
          back to Endpoints
        </NavLink>
      </div>
      <Typography variant="h6" className={classes.heading} style={{ paddingBottom: "30px" }}>
        <span className={classes.headingTitle}>Name</span>
        <span className={classes.headingContent}>{name}</span>
      </Typography>
      <div className={classes.section}>
        <Capabilities
          capabilities={preparedCapabilities}
          blockClassName={classes.capabilities}
          readonly={true}
          isMqtt={isMqtt}
        />
      </div>
    </PaperComponent>
  );
};

export default EndpointsDetails;
