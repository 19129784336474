import React, { FunctionComponent } from "react";
import { matchPath } from "react-router";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { additionalRoutes, sidebarRoutes } from "../../routes/routes";
import AppButton from "../common/button/button.component";
import { ButtonSize } from "../../shared/button-style";
import { Colors } from "../../theme/colors";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: `calc(100% - ${theme.appSidebar?.width}px)`,
      marginLeft: theme.appSidebar?.width,
    },
    toolbar: {
      justifyContent: "space-between",
      backgroundColor: Colors.White,
      borderBottom: `1px solid ${Colors.LightGrey}`,
      minHeight: 50,
    },
    logoutButton: {
      width: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(1),
    },
    logoutIcon: {
      marginRight: "10px",
    },
  })
);

const getPageTitle = (pathname: string) => {
  const currentRoute = [...sidebarRoutes, ...additionalRoutes].find((route) => matchPath(pathname, route));

  return currentRoute ? currentRoute.name : "";
};

interface Props {
  pathname: string;
  logout: () => any;
}

const AppBarComponent: FunctionComponent<Props> = (props: Props) => {
  const { pathname } = props;
  const classes = useStyles();
  const title = getPageTitle(pathname);

  return (
    <AppBar color="transparent" elevation={0} position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" {...{ "data-testid": "page-title" }}>
          {title}
        </Typography>
        <AppButton handler={props.logout} className={classes.logoutButton} size={ButtonSize.TINY_BUTTON}>
          <div className={classes.logoutIcon}>
            <AccountCircleIcon />
          </div>
          Logout
        </AppButton>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
