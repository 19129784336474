export interface LoadingStatus {
  pending: boolean;
  success: boolean;
  error: string | undefined;
}

export const loadInitial = (): LoadingStatus => ({
  pending: false,
  success: false,
  error: undefined,
});

export const loadPending = (): LoadingStatus => ({
  pending: true,
  success: false,
  error: undefined,
});

export const loadSuccess = (): LoadingStatus => ({
  pending: false,
  success: true,
  error: undefined,
});

export const loadFailed = (error: string): LoadingStatus => ({
  pending: false,
  success: false,
  error,
});
