import { TelemetryEntry, GeoJSON, Feature } from "../store/telemetry/telemetry.store";

const MAX_CUTTED_MARKERS_COUNT = 200;
const MAX_MARKERS_COUNT = 400;

export const getInvalidMarkers = (telemetry: TelemetryEntry[][] | undefined) => {
  return (
    telemetry &&
    telemetry.map((telemetryRow: TelemetryEntry[]) => {
      const notAvalilableTelemetryRow = telemetryRow.reduce((acc: any[], item: any) => {
        if (item.value === "D_NOT_AVAILABLE" || typeof item.value === "object") {
          acc.push(item.value);
        }
        return acc;
      }, []);
      return notAvalilableTelemetryRow.length > 1 && notAvalilableTelemetryRow;
    })
  );
};

export const cutMarkers = (markers: GeoJSON, telemetry: TelemetryEntry[][] | undefined) => {
  const { features } = markers;
  const invalidMarkers = getInvalidMarkers(telemetry)?.filter((item: any) => item);

  if (features && features.length - 1 > MAX_MARKERS_COUNT) {
    const step = Math.floor(features.length / MAX_CUTTED_MARKERS_COUNT);
    let lastIndex = step;
    const cuttedMarkers = features.reduce((acc: Feature[], item: any, index: number) => {
      if (index === lastIndex) {
        acc.push(item);
        lastIndex = index + step;
      }
      // removed coordinated with invalid status
      const adjustedMarkers = acc.filter(
        (marker: Feature) =>
          !invalidMarkers?.some(
            (invalidMarker: any) =>
              marker.geometry.coordinates[0] === invalidMarker[0].long &&
              marker.geometry.coordinates[1] === invalidMarker[0].lat
          )
      );

      return adjustedMarkers;
    }, []);
    return {
      ...markers,
      features: [...cuttedMarkers],
    };
  } else {
    return markers;
  }
};

export const connectTheDots = (data: any) => {
  const coords = [];
  for (const i in data._layers) {
    if (data._layers[i]._latlng) {
      const { lat, lng } = data._layers[i]._latlng;
      coords.push([lat, lng]);
    }
  }
  return coords;
};

export enum MapData {
  MAX_ZOOM = 18,
  HYBRID_SATELLITE_MAP = "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
  BASIC_MAP = "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
  ADJUST_ZOOM_TITLE = "Adjust Zoom",
  MAP_TYPE_TITLE = "Cnage map view",
}
