import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";

import authenticationSaga from "./authentication/authentication.saga";
import authenticationReducer from "./authentication/authentication.reducer";
import globalSaga from "./global/global.saga";
import globalReducer from "./global/global.reducer";
import dataStorageReducer from "./data-storage/data-storage.reducer";
import endpointsReducer from "./endpoints/endpoints.reducer";
import dataStorageSaga from "./data-storage/data-storage.saga";
import endpointsSaga from "./endpoints/endpoints.saga";
import simulationReducer from "./simulation/simulation.reducer";
import simulationSaga from "./simulation/simulation.saga";
import validationReducer from "./validation/validation.reducer";
import validationSaga from "./validation/validation.saga";
import notificationsReducer from "./notifications/notifications.reducer";
import commonReducer from "./common/common.reducer";
import telemetryReducer from "./telemetry/telemetry.reducer";
import telemetrySaga from "./telemetry/telemetry.saga";

import { configureStore } from "./configureStore";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    global: globalReducer,
    dataStorage: dataStorageReducer,
    authentication: authenticationReducer,
    endpoints: endpointsReducer,
    simulation: simulationReducer,
    validation: validationReducer,
    notification: notificationsReducer,
    common: commonReducer,
    telemetry: telemetryReducer,
  });

const sagas = [
  globalSaga,
  authenticationSaga,
  dataStorageSaga,
  endpointsSaga,
  simulationSaga,
  validationSaga,
  telemetrySaga,
];

const store = configureStore();

export { rootReducer, sagas, store };
