import { connect } from "react-redux";
import { Dispatch } from "redux";

import EndpointsComponent, { ConnectedDispatch, ConnectedState } from "./endpoints.component";

import {
  getSendFilesProgress,
  getReloadProgress,
  getAllEndpoints,
  getReceiverEndpoints,
  getPublishFilesProgress,
} from "../../store/endpoints/endpoints.selectors";

import { AppState } from "../../store/app.state";
import { sendFiles, reload, publishFiles, fetchAllEndpoints } from "../../store/endpoints/endpoints.actions";
import { updateFileList } from "../../store/data-storage";

const mapStateToProps = (state: AppState): ConnectedState => ({
  allEndpoints: getAllEndpoints(state),
  receiverEndpoints: getReceiverEndpoints(state),
  sendFilesInProgress: getSendFilesProgress(state),
  publishFilesInProgress: getPublishFilesProgress(state),
  reloadInProgress: getReloadProgress(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  sendFiles: (files: string[], endpoints: string[]) => dispatch(sendFiles(files, endpoints)),
  publishFiles: (files: string[], endpoints: string[]) => dispatch(publishFiles(files, endpoints)),
  reload: () => dispatch(reload()),
  fetchAllEndpoints: () => dispatch(fetchAllEndpoints()),
  fetchFiles: () => dispatch(updateFileList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndpointsComponent);
