/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, FunctionComponent } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route, Redirect } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import classNames from "classnames";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Preloader from "../preloader/preloader.component";
import getRoutes from "../../routes";
import { ONBOARD_PATHNAME } from "../../routes/routes";
import SidebarComponent from "../sidebar/sidebar.component";
import AppBarComponent from "../app-bar/app-bar.component";
import RoutePaths from "../../routes/route-paths";
import LoginComponent from "../../pages/login/login.component";
import PublicRoute from "../../routes/public-route";
import { Colors } from "../../theme/colors";
import NotificationContainer from "../../components/notifications/notifications.container";
import { OwnProps } from "./app.container";
import CallbackComponent from "../../pages/callback/callback.component";
import { useDispatch } from "react-redux";
import { relogin } from "../../store/authentication/authentication.actions";
import AuthService from "../../shared/services/auth.service";
import NoAccessComponent from "../../pages/no-access/no-access.component";
import { wsInit, fetchLoggingStatus, fetchTraceLoggingStatus } from "../../store/global/global.actions";

export interface ConnectedState {
  isLoading?: boolean;
  lastError?: any;
  isOnboarded: boolean;
  isLoggedIn: any;
  pathname: string;
  roles?: string[];
}

export interface ConnectedDispatch {
  justifyOnboarding: () => void;
  logout: (force?: boolean) => void;
  fetchLoggingStatus: () => void;
  fetchTraceLoggingStatus: () => void;
}

export interface AppProps extends ConnectedState, ConnectedDispatch, OwnProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      display: "flex",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(9),
    },
    toolbar: theme.mixins.toolbar,
    snackbar: {
      fontSize: theme.typography.body2.fontSize,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    snackbarSuccess: {
      backgroundColor: Colors.Green,
    },
    snackbarError: {
      backgroundColor: Colors.Red,
    },
    snackbarWarning: {
      backgroundColor: Colors.DarkOrange,
    },
    snackbarInfo: {
      backgroundColor: Colors.Yellow,
    },
  })
);

const AppComponent: FunctionComponent<AppProps> = (props: AppProps) => {
  const { isLoading, isOnboarded, isLoggedIn, pathname, history, roles } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEndUser = AuthService.getInstance().isEndUser();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(relogin());
      dispatch(wsInit());

      dispatch(fetchLoggingStatus());
      dispatch(fetchTraceLoggingStatus());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (pathname === ONBOARD_PATHNAME) {
      props.justifyOnboarding();
    }
  }, [pathname]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{
        variantSuccess: classNames(classes.snackbar, classes.snackbarSuccess),
        variantError: classNames(classes.snackbar, classes.snackbarError),
        variantWarning: classNames(classes.snackbar, classes.snackbarWarning),
        variantInfo: classNames(classes.snackbar, classes.snackbarInfo),
      }}
      hideIconVariant={true}
    >
      <ConnectedRouter history={history}>
        <Box display="flex">
          <Switch>
            <PublicRoute path={RoutePaths.Login} component={LoginComponent} isLoggedIn={isLoggedIn} />
            <PublicRoute path={RoutePaths.Callback} component={CallbackComponent} isLoggedIn={isLoggedIn} />

            {isLoggedIn ? (
              <>
                {roles?.length ? (
                  <Route path="/">
                    <AppBarComponent logout={props.logout} pathname={pathname} />
                    <SidebarComponent isOnboarded={isOnboarded} isEndUser={isEndUser} />

                    <main className={classes.content} {...{ "data-testid": "main-container" }}>
                      {isLoading ? <Preloader /> : getRoutes(isLoggedIn)}
                    </main>
                  </Route>
                ) : (
                  <Route component={() => <NoAccessComponent />} />
                )}
              </>
            ) : (
              <Redirect to={RoutePaths.Login} />
            )}
          </Switch>
        </Box>
      </ConnectedRouter>
      <NotificationContainer />
    </SnackbarProvider>
  );
};

export default AppComponent;
