import { createSelector } from "reselect";

import { AppState } from "../app.state";

export const getValidationState = (state: AppState) => state.validation;

export const getValidationProgress = createSelector(
  getValidationState,
  (state) => state.validationInProgress || false
);
