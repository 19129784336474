import React, { FunctionComponent, useState, useEffect } from "react";

import {
  makeStyles,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { createStyles } from "@material-ui/core/styles";

import TableComponent from "../../components/common/table/table.component";
import AppButton from "../../components/common/button/button.component";
import { ButtonSize } from "../../shared/button-style";
import { OwnProps } from "./capabilities.container";
import { CAPABILITY_DIRECTION, Capability, PUSH_NOTIFICATIONS } from "../../api/backend-api";

const useStyles = makeStyles(() =>
  createStyles({
    capabilitiesValue: {
      textTransform: "uppercase",
      fontSize: "12px",
    },
    heading: {
      "&>span": {
        display: "block",
      },
    },
    headingTitle: {
      textTransform: "uppercase",
      fontSize: "10px",
      letterSpacing: "2px",
    },
  })
);

export interface ConnectedState {
  saveStatus: boolean;
}

export interface ConnectedDispatch {
  save: (capabilities: Capability[], pushNotifications: number) => any;
}

interface CapabilitiesProps extends OwnProps, ConnectedState, ConnectedDispatch {}

const Capabilities: FunctionComponent<CapabilitiesProps> = (props: CapabilitiesProps) => {
  const { capabilities, pushNotifications = 0, saveStatus, blockClassName, readonly, isMqtt } = props;
  const classes = useStyles();
  const [directions, setDirections] = useState<{ [key: string]: number }>({});
  const [pushNotificationsValue, setPushNotificationsValue] = useState<number>(0);

  useEffect(() => {
    setDirections(
      capabilities.reduce((dir, { type, direction }) => ({ ...dir, [type]: direction ?? -1 }), {})
    );

    setPushNotificationsValue(pushNotifications);
  }, [capabilities, pushNotifications]);

  const handleChange = (type: string, value: any) => {
    const newDirections = { ...directions };
    newDirections[type] = value;
    setDirections(newDirections);
  };

  const getValue = (type: string) => {
    const value = directions[type];

    return value === undefined ? -1 : value;
  };

  const renderCapabilityDirectionsOptions = () =>
    CAPABILITY_DIRECTION.map((direction: string, index: number) => (
      <MenuItem key={index} value={index}>
        {direction}
      </MenuItem>
    ));

  const onSavePress = () => {
    const capabilitiesToSave = capabilities
      .map((capability) => ({ ...capability, direction: directions[capability.type] }))
      .filter((value) => value.direction !== -1);
    props.save(capabilitiesToSave, pushNotificationsValue);
  };

  return (
    <>
      <div {...(blockClassName ? { className: blockClassName } : {})}>
        <Typography variant="h6" className={classes.heading}>
          {readonly ? <span className={classes.headingTitle}>Capabilities</span> : "Capabilities"}
        </Typography>
        <TableComponent
          fixed={false}
          size="small"
          tableBody={capabilities.map(({ type, name: capabilityName }) => (
            <TableRow key={type}>
              <TableCell>{capabilityName}</TableCell>
              <TableCell className={classes.capabilitiesValue}>
                <FormControl>
                  <Select
                    value={getValue(type)}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      handleChange(type, event.target.value)
                    }
                    disabled={readonly || saveStatus}
                  >
                    <MenuItem value={-1}>None</MenuItem>
                    {renderCapabilityDirectionsOptions()}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        />
      </div>
      {!readonly && (
        <>
          {isMqtt && (
            <div {...(blockClassName ? { className: blockClassName } : {})}>
              <Typography variant="h6" className={classes.heading}>
                Push notifications
              </Typography>
              <FormControl style={{ textTransform: "uppercase", paddingTop: "6px" }}>
                <Select
                  value={pushNotificationsValue || 0}
                  disabled={saveStatus}
                  onChange={(event: React.ChangeEvent<any>) => setPushNotificationsValue(event.target.value)}
                >
                  <MenuItem value={0}>{PUSH_NOTIFICATIONS[0]}</MenuItem>
                  <MenuItem value={1}>{PUSH_NOTIFICATIONS[1]}</MenuItem>
                  <MenuItem value={2}>{PUSH_NOTIFICATIONS[2]}</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div>
            <AppButton
              size={ButtonSize.SMALL_BUTTON}
              disabled={saveStatus}
              handler={onSavePress}
              testId="save-button"
            >
              Send &amp; Save
            </AppButton>
          </div>
        </>
      )}
    </>
  );
};

export default Capabilities;
