import { takeLatest, call } from "redux-saga/effects";
import { put } from "redux-saga-test-plan/matchers";

import {
  sendFilesSuccessful,
  sendFilesFailure,
  EndpointsActionType,
  reloadFailure,
  reloadSuccessful,
  setEndpoints,
  publishFilesSuccessful,
  publishFilesFailure,
  fetchAllEndpointsSuccessful,
  fetchAllEndpoints as getAllEndpoints,
} from "../endpoints/endpoints.actions";

import logger from "../../logger";
import { setLastError } from "../global/global.actions";
import { store } from "../index";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { SuccessMessages } from "../../utils/notifications.messages";
import { select } from "typed-redux-saga";
import { getOnboardStatus } from "../global/global.selectors";
import BackendClient from "../../api/backend-client";
import { Dispatch } from "redux";
import ApiMethods from "../../api/api-methods";
import wsClient from "../../api/ws-client";
import { Roles, WsMethods } from "../../utils/ws-methods";

const logError = logger.error("endpoints.saga");

let backendClient: BackendClient;

export function* sendFiles({ payload }: any) {
  try {
    const [fileIds, targets] = payload;

    yield call([backendClient, ApiMethods.SendFiles], fileIds, targets);
    yield put(sendFilesSuccessful());
    yield put(showSuccessfulRequest(SuccessMessages.SHOW_SUCCESSFULL_SEND_FILE_TO_ENPOINT));
  } catch (e) {
    yield put(sendFilesFailure());
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    logError("files were not send");
  }
}

export function* publishFiles({ payload }: any) {
  try {
    const [fileIds, targets] = payload;

    yield call([backendClient, ApiMethods.PublishFiles], fileIds, targets);
    yield put(publishFilesSuccessful());
    yield put(showSuccessfulRequest(SuccessMessages.SHOW_SUCCESSFULL_SEND_FILE_TO_ENPOINT));
  } catch (e) {
    yield put(publishFilesFailure());
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    logError("files were not published");
  }
}

export function* fetchAllEndpoints() {
  try {
    const isOnboarded = yield select(getOnboardStatus);

    if (isOnboarded) {
      const { all = [], receiver = [] } = yield call([backendClient, ApiMethods.GetEndpoints]);
      yield put(setEndpoints(all, receiver));
      yield put(fetchAllEndpointsSuccessful());
    }
  } catch (e) {
    yield put(reloadFailure());
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    logError("endpoints reload is failed");
  }
}

const reloadEndpoints = (success: any) => {
  if (success) {
    store.dispatch(getAllEndpoints());
  }
};

export function* reload() {
  try {
    const isOnboarded = yield select(getOnboardStatus);

    if (isOnboarded) {
      yield call([backendClient, ApiMethods.UpdateEndpoints]);
      yield put(reloadSuccessful());
      wsClient.subscribe(`${Roles.AGRIROUTER}.${WsMethods.ENDPOINTS}`, reloadEndpoints);
    }
  } catch (e) {
    yield put(reloadFailure());
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    logError("endpoints reload is failed");
  }
}

export default function* endpointsSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);

  yield takeLatest(EndpointsActionType.SEND_FILES, sendFiles);
  yield takeLatest(EndpointsActionType.PUBLISH_FILES, publishFiles);
  yield takeLatest(EndpointsActionType.RELOAD, reload);
  yield takeLatest(EndpointsActionType.FETCH_ALL_ENDPOINTS, fetchAllEndpoints);
}
