import React, { FunctionComponent } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import TransitionsModal from "../../common/modal-winidow/modal-window.component";
import AppButton from "../../common/button/button.component";
import { ButtonSize } from "../../../shared/button-style";
import PaperComponent from "../../paper/paper.component";

interface Props {
  open: boolean;
  closeModal: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

const ChangelogWindow: FunctionComponent<Props> = (props: Props) => {
  const { open } = props;
  const classes = useStyles();

  return (
    <TransitionsModal open={open} closeModal={props.closeModal}>
      <Typography variant="h4" style={{ textAlign: "center", paddingBottom: "10px" }}>
        Changelog
      </Typography>
      <PaperComponent>
        <Typography variant="h5">Version 3.0.0 [2022-07-04]</Typography>
        <Typography variant="h6">Enhancements</Typography>
        <Typography>
          <ul>
            <li>
              <b>IO-20</b> Refactor backend to use official Java SDK. At the same time, the backend was mostly
              reimplemented from scratch, now based on Spring Boot.
            </li>
          </ul>
        </Typography>
        <Typography variant="h6">Bugfixes</Typography>
        <Typography>
          <ul>
            <li>
              <b>IO-37</b> when running a simulation, it is now possible to see the status of the simulation
              after reloading the application
            </li>
          </ul>
        </Typography>
      </PaperComponent>
      <PaperComponent>
        <Typography variant="h5">Version 2.1.0 [2021-12-21]</Typography>
        <Typography variant="h6">Enhancements</Typography>
        <Typography>
          <ul>
            <li>
              <b>IO-42</b> enable trace logging for 24h periods
            </li>
            <li>
              <b>IO-25</b> log window is now foldable to increase screen real estate
            </li>
            <li>
              <b>IO-49</b> map view now uses Google Maps to enable hybrid and map views (also solving IO-5
              "show streets and places")
            </li>
            <li>
              <b>IO-48</b> position markers in map view are now "thinned out" to display a maximum of 200
              points on the map
            </li>
          </ul>
        </Typography>
        <Typography variant="h6">Bugfixes</Typography>
        <Typography>
          <ul>
            <li>
              <b>IO-1</b> invalid GPS points are no longer drawn on the map
            </li>
            <li>
              <b>IO-41</b> uploading too many files at once now produces an error message instead of silently
              failing
            </li>
            <li>
              <b>IO-43</b> prohibited uploading of files that are too large for MongoDB
            </li>
          </ul>
        </Typography>
      </PaperComponent>
      <PaperComponent>
        <Typography variant="h5">Version 2.0.2 [2021-04-13]</Typography>
        <Typography variant="h6">Enhancements</Typography>
        <Typography>
          <ul>
            <li>
              <b>IO-36</b> maximum file upload size changed to 100 MB
            </li>
          </ul>
        </Typography>
      </PaperComponent>
      <PaperComponent>
        <Typography variant="h5">Version 2.0.1 [2021-02-16]</Typography>
        <Typography variant="h6">Bugfixes</Typography>
        <Typography>
          <ul>
            <li>
              <b>IO-13</b> Refreshing Endpoints does not work
            </li>
            <li>
              <b>IO-15</b> Files with special characters in their name can't be uploaded
            </li>
            <li>
              <b>IO-29/IO-30</b> Publishing files from Data Storage view allowed (and required!) setting an
              endpoint
            </li>
          </ul>
        </Typography>
        <Typography variant="h6">Enhancements</Typography>
        <Typography>
          <ul>
            <li>
              <b>IO-14</b> Files can be uploaded via drag-and-drop
            </li>
            <li>
              <b>IO-17</b> File selection is possible by clicking on its name
            </li>
            <li>
              <b>IO-23</b> Introduce a changelog
            </li>
          </ul>
        </Typography>
      </PaperComponent>
      <PaperComponent>
        <Typography variant="h5">Version 2.0.0</Typography>
        <Typography>
          <ul>
            <li>complete rework of frontend</li>
          </ul>
        </Typography>
      </PaperComponent>
      <div className={classes.buttonsContainer}>
        <AppButton size={ButtonSize.TINY_BUTTON} handler={props.closeModal}>
          Close
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default ChangelogWindow;
