import React, { FunctionComponent, useState, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

import { Typography, Box, Checkbox } from "@material-ui/core";

import Capabilities from "../../components/capabilities/capabilities.container";
import AppButton from "../../components/common/button/button.component";
import ConfirmModal from "./confirm-modal.component";
import useStyles from "./settings.styles";
import Subscriptions from "../../components/subscriptions/subscriptions.container";
import AgrirouterLogo from "../../images/agrirouter.png";
import { ButtonSize } from "../../shared/button-style";
import PageWithLogging from "../../components/page/page.component";
import { useSelector } from "react-redux";
import { AppState } from "../../store/app.state";
import { getTraceLoggingStatusEnabledTill } from "../../store/global/global.selectors";
import AuthService from "../../shared/services/auth.service";
import { Capability, StatusData } from "../../api/backend-api";

export interface ConnectedState {
  capabilities: Capability[];
  pushNotifications: number;
  reonboardStatus: boolean;
  disconnectStatus: boolean;
  reconnectStatus: boolean;
  changePasswordStatus: boolean;
  expireDays: number | null;
  isOnboarded: boolean;
  onboarding: boolean;
  username: string;
  serverErrorMessage: string;
  isMqtt: boolean;
  isQA: boolean;
  statusData: StatusData;
  links: any;
}

export interface ConnectedDispatch {
  reonboard: () => any;
  secureOnboarding: (qa: boolean, mqtt: boolean) => any;
  disconnect: () => any;
  reconnect: () => any;
  fetchEnvironments: () => any;
  changeTraceLoggingStatus: (isTraceLoggingEnabled: boolean) => any;
}

interface SettingsProps extends ConnectedState, ConnectedDispatch {}

interface ChangePasswordScheme {
  current: string;
  new: string;
  repeat: string;
}

const SettingsComponent: FunctionComponent<SettingsProps> = ({
  capabilities,
  pushNotifications,
  expireDays,
  isOnboarded,
  username,
  changePasswordStatus,
  serverErrorMessage,
  onboarding,
  isMqtt,
  isQA,
  reconnectStatus,
  statusData,
  links,
  fetchEnvironments,
  changeTraceLoggingStatus,
  ...actions
}: SettingsProps) => {
  const classes = useStyles();

  const { reset } = useForm<ChangePasswordScheme>();
  const isEndUser = AuthService.getInstance().isEndUser();
  const { email, userName } = AuthService.getInstance().getUserInfo();
  const [disconnectWarningIsShown, setDisconnectWarningIsShown] = useState(false);
  const [changePasswordFormIsSent, setChangePasswordFormIsSent] = useState(false);

  const traceLoggingStatusEnabledTill = useSelector((state: AppState) =>
    getTraceLoggingStatusEnabledTill(state)
  );

  const isTraceLoggingStatusEnabled = useMemo(
    () => traceLoggingStatusEnabledTill - new Date().getTime() > 0,
    [traceLoggingStatusEnabledTill]
  );

  useEffect(() => {
    fetchEnvironments();
  }, [fetchEnvironments]);

  useEffect(() => {
    if (changePasswordFormIsSent && !changePasswordStatus && !serverErrorMessage) {
      reset();
      setChangePasswordFormIsSent(false);
    }
  }, [changePasswordStatus, changePasswordFormIsSent, serverErrorMessage, reset]);

  useEffect(() => {
    if (!isOnboarded && disconnectWarningIsShown) {
      setDisconnectWarningIsShown(false);
    }
  }, [isOnboarded, disconnectWarningIsShown]);

  const toggleTraceLogging = useCallback(
    (value: boolean) => {
      if (value) {
        changeTraceLoggingStatus(true);
      } else {
        changeTraceLoggingStatus(false);
      }
    },
    [changeTraceLoggingStatus]
  );

  return (
    <PageWithLogging isCollapsible={true}>
      <div className={classes.container}>
        <div className={classes.section}>
          <div className={classes.block} style={{ marginBottom: 16 }}>
            <Typography variant="h6">User Info</Typography>
            <div className={classes.infoWrapper}>
              <Typography className={classes.label} variant="body1">{`User name: `}</Typography>
              <Typography variant="body1">{`${userName ?? "-"}`}</Typography>
            </div>
            <div className={classes.infoWrapper}>
              <Typography className={classes.label} variant="body1">{`E-mail: `}</Typography>
              <Typography variant="body1">{`${email ?? "-"}`}</Typography>
            </div>
          </div>
          <div className={classes.block}>
            <Typography variant="h6">agrirouter Linkage</Typography>
            <Typography variant="body1">
              {`${isOnboarded ? "Connected" : "Connect"} to the agrirouter`}.
            </Typography>
            {isOnboarded && (
              <div style={{ margin: "4px 0px" }}>
                <div className={classes.infoWrapper}>
                  <Typography className={classes.label} variant="body1">{`Account ID: `}</Typography>
                  <Typography variant="body1">{`${statusData.accountId ?? "-"}`}</Typography>
                </div>
                <div className={classes.infoWrapper}>
                  <Typography className={classes.label} variant="body1">{`Endpoint ID: `}</Typography>
                  <Typography variant="body1">{`${statusData.endpointId ?? "-"}`}</Typography>
                </div>
              </div>
            )}
            {isOnboarded ? (
              <>
                <Typography variant="body1">{`The connection expires in ${expireDays} days.`}</Typography>
                {isMqtt && (
                  <div className={classes.container}>
                    <AppButton
                      handler={actions.reconnect}
                      className={classes.reconnectButton}
                      progress={reconnectStatus}
                      disabled={reconnectStatus}
                    >
                      Reconnect MQTT
                    </AppButton>
                  </div>
                )}
                <div className={classes.container}>
                  <AppButton
                    handler={() => setDisconnectWarningIsShown(true)}
                    className={classes.dangerButton}
                  >
                    {`Disconnect ${isMqtt ? "MQTT " : ""}${isQA ? links.qa : ""}`}
                  </AppButton>
                  <AppButton handler={actions.reonboard} progress={onboarding} disabled={onboarding}>
                    {`Reonboard ${isMqtt ? "MQTT " : ""}${isQA ? links.qa : ""}`}
                  </AppButton>
                </div>
              </>
            ) : (
              <>
                <Typography variant="body1">
                  Before using the send feature you have to connect the InOut-Tool to your&nbsp;
                  <img src={AgrirouterLogo} alt="agrirouter" height="16px" /> account.
                </Typography>

                <Box display="flex">
                  <AppButton
                    handler={() => actions.secureOnboarding(false, true)}
                    size={ButtonSize.MEDIUM_BUTTON}
                    progress={onboarding}
                    disabled={onboarding}
                  >
                    Onboard MQTT {links.production ?? ""}
                  </AppButton>
                  {!isEndUser && (
                    <AppButton
                      handler={() => actions.secureOnboarding(true, true)}
                      size={ButtonSize.SMALL_BUTTON}
                      progress={onboarding}
                      disabled={onboarding}
                    >
                      Onboard MQTT {links.qa ?? ""}
                    </AppButton>
                  )}
                </Box>
                {!isEndUser && (
                  <Box display="flex">
                    <AppButton
                      handler={() => actions.secureOnboarding(false, false)}
                      size={ButtonSize.MEDIUM_BUTTON}
                      progress={onboarding}
                      disabled={onboarding}
                    >
                      Onboard REST {links.production ?? ""}
                    </AppButton>
                    <AppButton
                      handler={() => actions.secureOnboarding(true, false)}
                      size={ButtonSize.SMALL_BUTTON}
                      progress={onboarding}
                      disabled={onboarding}
                    >
                      Onboard REST {links.qa ?? ""}
                    </AppButton>
                  </Box>
                )}
              </>
            )}
          </div>
        </div>
        {isOnboarded && !isEndUser && (
          <>
            <div className={classes.section}>
              <Capabilities
                capabilities={capabilities}
                pushNotifications={pushNotifications}
                blockClassName={classes.block}
                isMqtt={isMqtt}
              />
            </div>
            <div className={classes.section}>
              <Subscriptions />
            </div>
          </>
        )}
      </div>
      {!isEndUser && (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={isTraceLoggingStatusEnabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              toggleTraceLogging(event.target.checked)
            }
          />
          <Typography>Enable trace logging for 24h</Typography>
        </Box>
      )}

      {disconnectWarningIsShown && (
        <ConfirmModal
          visibility={disconnectWarningIsShown}
          text="Are you sure you want to delete the agrirouter onboarding? This does also delete the endpoint in the agrirouter control center."
          buttonLabel="Disconnect"
          handler={actions.disconnect}
          closeHandler={() => setDisconnectWarningIsShown(false)}
        />
      )}
    </PageWithLogging>
  );
};

export default SettingsComponent;
