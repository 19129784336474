import { IFileDto } from "../store/data-storage/data-storage.store";

const typeTransformation = (type: string): string => {
  const res = type.match(/page=[^&]+/);

  return res?.[0].replace(/page=/, "").toUpperCase() || "";
};

export const transformer = (file: IFileDto): IFileDto => ({
  ...file,
  rawType: file.type,
  type: typeTransformation(file.type),
});
