import { connect } from "react-redux";

import { AppState } from "../../store/app.state";
import { getNotification } from "../../store/notifications/notifications.selectors";
import NotificationsComponent, { ConnectedState } from "./notifications.component";

const mapStateToProps = (state: AppState): ConnectedState => ({
  notification: getNotification(state),
});

export default connect(mapStateToProps)(NotificationsComponent);
