import { createSelector } from "reselect";

import { AppState } from "../app.state";
import { Endpoint, emptyEndpoint } from "./endpoints.store";

export const getEndpointsState = (state: AppState) => state.endpoints;

export const getEndpointById = (state: AppState, id: string): Endpoint =>
  state.endpoints.allEndpoints.find((endpoint) => id === endpoint.id) || emptyEndpoint;

export const getAllEndpoints = createSelector(getEndpointsState, (state) => state.allEndpoints || []);

export const getReceiverEndpoints = createSelector(
  getEndpointsState,
  (state) => state.receiverEndpoints || []
);

export const getSendFilesProgress = createSelector(
  getEndpointsState,
  (state) => state.sendFilesInProgress || false
);

export const getPublishFilesProgress = createSelector(
  getEndpointsState,
  (state) => state.publishFilesInProgress || false
);

export const getReloadProgress = createSelector(
  getEndpointsState,
  (state) => state.reloadInProgress || false
);
