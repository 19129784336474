import { IFileDto } from "../store/data-storage/data-storage.store";

export const pluralize = (text: string, count: number) => `${text}${count > 1 ? "s" : ""}`;

export const formatUnsupportedTypesMessage = (
  selected: IFileDto[],
  unsupportedTypes: string[],
  publishMode: boolean
) => {
  let convertMessage = "";
  if (unsupportedTypes.includes("EFDITIMELOG")) {
    if (unsupportedTypes.length === 1) {
      convertMessage = "to <b>ISOXML</b>";
    } else {
      convertMessage = "to <b>ISOXML</b> or another acceptable format";
    }
  } else {
    convertMessage = "to acceptable";
  }

  return `Cannot ${publishMode ? "publish" : "send"} ${pluralize(
    "file",
    selected.length
  )} with unsupported ${pluralize("type", unsupportedTypes.length)}: <b>${unsupportedTypes.join(
    ", "
  )}</b>. Please convert ${selected.length > 1 ? "them" : "it"} ${convertMessage} before ${
    publishMode ? "publishing" : "sending"
  }.`;
};
