import React, { FunctionComponent } from "react";

import { Typography } from "@material-ui/core";

import AppButton from "../../components/common/button/button.component";
import TransitionsModal from "../../components/common/modal-winidow/modal-window.component";
import { ButtonSize } from "../../shared/button-style";
import useStyles from "./settings.styles";

interface ConfirmModalProps {
  closeHandler: () => any;
  handler: () => any;
  visibility: boolean;
  text: string;
  buttonLabel: string;
}

const ConfirmModal: FunctionComponent<ConfirmModalProps> = (props: ConfirmModalProps) => {
  const classes = useStyles();
  const { visibility, text, buttonLabel } = props;

  return (
    <TransitionsModal closeModal={props.closeHandler} open={visibility}>
      <Typography variant="body1">{text}</Typography>
      <div className={classes.oneButton}>
        <AppButton
          size={ButtonSize.TINY_BUTTON}
          className={classes.cancelButton}
          handler={props.closeHandler}
        >
          Cancel
        </AppButton>
        <AppButton handler={props.handler} className={classes.dangerButton}>
          {buttonLabel}
        </AppButton>
      </div>
    </TransitionsModal>
  );
};

export default ConfirmModal;
