import { connect } from "react-redux";
import { Dispatch } from "redux";

import LoggingComponent, { ConnectedDispatch, ConnectedState } from "./logging.component";
import { AppState } from "../../store/app.state";
import {
  getEarliestLogEntryTime,
  getTraceLoggingStatusEnabledTill,
  getLatestLogEntryTime,
  getLogs,
} from "../../store/global/global.selectors";
import { clearLogs } from "../../store/global/global.actions";

const DEFAULT_LIMIT = 50;

export interface OwnProps {
  full?: boolean;
}

const mapStateToProps = (state: AppState, { full }: OwnProps): ConnectedState => {
  const entries = getLogs(state, !full ? DEFAULT_LIMIT : undefined);

  return {
    entries,
    timeFrom: getEarliestLogEntryTime(state),
    timeTo: getLatestLogEntryTime(state),
    traceLoggingStatusEnabledTill: getTraceLoggingStatusEnabledTill(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  clearLogs: () => dispatch(clearLogs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggingComponent);
