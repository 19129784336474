import React, { FunctionComponent, useEffect, useState, useCallback } from "react";

import useStyles from "./upload-window.styles";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

import TransitionsModal from "../../common/modal-winidow/modal-window.component";
import { ButtonSize } from "../../../shared/button-style";
import AppButton from "../../common/button/button.component";
import { MessageField } from "../../common/message-field/message-field.component";
import { IValidationMessage } from "../../../store/validation/validation.store";
import { getPriority } from "../validation-window/validation.helper";

export interface ConnectedProps {
  validatedFileLog: IValidationMessage;
}

interface Props extends ConnectedProps {
  open: boolean;
  closeModal: () => void;
  fileName?: string;
}

const UploadModalWindow: FunctionComponent<Props> = ({ closeModal, open, validatedFileLog }) => {
  const classes = useStyles();
  const [log, setLog] = useState<any>();

  useEffect(() => {
    if (!!validatedFileLog) {
      setLog({ ...validatedFileLog });
    }
  }, [validatedFileLog]);

  const renderValidationIcon = useCallback(() => {
    const priority = getPriority(log[0]);

    switch (priority) {
      case 0: {
        return <CheckCircleIcon className={classes.checkIcon} />;
      }
      case 1: {
        return <ReportProblemIcon className={classes.warningIcon} />;
      }
      case 2: {
        return <ReportProblemIcon className={classes.errorIcon} />;
      }
      case 3: {
        return <ErrorIcon className={classes.fatalIcon} />;
      }
    }
  }, [classes, log]);

  return (
    <>
      {!!log && !!log[0] && (
        <TransitionsModal closeModal={closeModal} open={open} size="870px">
          <div className={classes.flexWrapper}>
            <div className={classes.header}>
              <Typography className={classes.title}>Upload</Typography>
              <div className={classes.statusIcon}>{renderValidationIcon()}</div>
            </div>
            <div>
              <Typography className={classes.contactDetails}>{log[0].filename}</Typography>
            </div>
            <MessageField message={log[0].output.join("\n")} />
            <div className={classes.buttonWrapper}>
              <AppButton size={ButtonSize.TINY_BUTTON} handler={closeModal}>
                OK
              </AppButton>
            </div>
          </div>
        </TransitionsModal>
      )}
    </>
  );
};

export default UploadModalWindow;
