import { connect } from "react-redux";

import ValidationModalWindow, { ConnectedProps } from "./validation-window.component";
import { AppState } from "../../../store/app.state";
import { getLastValidatedFileLog } from "../../../store/data-storage/data-storage.selectors";

const mapStateToProps = (state: AppState): ConnectedProps => ({
  lastValidatedFileLog: getLastValidatedFileLog(state),
});

export default connect(mapStateToProps)(ValidationModalWindow);
