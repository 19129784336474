/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useCallback, useEffect, useRef } from "react";

import { makeStyles, TextField } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";

import {
  Autorenew as AutorenewIcon,
  Telegram as TelegramIcon,
  Publish as PublishIcon,
} from "@material-ui/icons";

import { ButtonSize } from "../../shared/button-style";
import AppButton from "../../components/common/button/button.component";
import SendFilesWindow from "../../components/modal-windows/send-files-window/send-files-window.container";
import { AccordionComponent } from "../../components/common/accordion/accordion.component";
import EndpointsTable from "./endpoints-table.component";
import { Endpoint } from "../../store/endpoints/endpoints.store";
import { IFileDto } from "../../store/data-storage/data-storage.store";
import PageWithLogging from "../../components/page/page.component";

export interface ConnectedState {
  allEndpoints: Endpoint[];
  receiverEndpoints: Endpoint[];
  sendFilesInProgress: boolean;
  publishFilesInProgress: boolean;
  reloadInProgress: boolean;
}

export interface ConnectedDispatch {
  sendFiles: (files: string[], endpoints: string[]) => any;
  publishFiles: (files: string[], endpoints: string[]) => any;
  reload: () => any;
  fetchAllEndpoints: () => any;
  fetchFiles: () => any;
}

export enum EndpointsSections {
  FILTERED = "filtered",
  UNFILTERED = "unfiltered",
}

interface EndpointsProps extends ConnectedState, ConnectedDispatch {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlsBar: {
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonIcon: {
      marginRight: "10px",
    },
    logging: {
      paddingTop: "50px",
    },
    searchField: {
      flexGrow: 1,
      margin: "0 10px",
    },
  })
);

const EndpointsComponent: FunctionComponent<EndpointsProps> = (props: EndpointsProps) => {
  const {
    allEndpoints = [],
    receiverEndpoints = [],
    sendFilesInProgress,
    reloadInProgress,
    publishFilesInProgress,
  } = props;

  const classes = useStyles();

  const [selected, setSelected] = useState<Endpoint[]>([]);
  const [sendModalIsOpen, setSendModalIsOpen] = useState<boolean>(false);
  const [publishModalIsOpen, setPublishModalIsOpen] = useState<boolean>(false);
  const prevSendFilesInProgress = useRef(false);
  const prevPublishFilesInProgress = useRef(false);
  const [searchTerm, setSearchTerm] = useState("");

  const sections: { [label: string]: { title: string; items: Endpoint[] } } = {
    [EndpointsSections.FILTERED]: { title: "Endpoints with Routes to IO-Tool", items: receiverEndpoints },
    [EndpointsSections.UNFILTERED]: { title: "All Endpoints", items: allEndpoints },
  };

  useEffect(() => {
    props.fetchAllEndpoints();
    props.fetchFiles();
  }, []);

  useEffect(() => {
    if (prevSendFilesInProgress.current !== sendFilesInProgress && !sendFilesInProgress && sendModalIsOpen) {
      setSendModalIsOpen(false);
      setSelected([]);
    }

    prevSendFilesInProgress.current = sendFilesInProgress;
  }, [sendModalIsOpen, sendFilesInProgress]);

  useEffect(() => {
    if (
      prevPublishFilesInProgress.current !== publishFilesInProgress &&
      !publishFilesInProgress &&
      publishModalIsOpen
    ) {
      setPublishModalIsOpen(false);
      setSelected([]);
    }

    prevPublishFilesInProgress.current = publishFilesInProgress;
  }, [publishModalIsOpen, publishFilesInProgress]);

  const handleSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const searchedResults = useCallback((searchedEndpoint: string, items: Endpoint[]) => {
    const searchedEndpoints: Endpoint[] = [];
    items.map(
      (item: Endpoint) =>
        !!item &&
        !!item.name &&
        item.name.toLowerCase().includes(searchedEndpoint.toLowerCase()) &&
        searchedEndpoints.push(item)
    );
    return searchedEndpoints;
  }, []);

  const toggleSelection = useCallback(
    (id: string) => {
      const selectedIndex = selected.findIndex(({ id: selectedId }) => id === selectedId);

      if (selectedIndex === -1) {
        const selectedValue = receiverEndpoints.find((item: Endpoint) => item.id === id);

        if (selectedValue) {
          setSelected([...selected, selectedValue]);
        }
      } else {
        setSelected(selected.filter(({ id: selectedId }) => selectedId !== id));
      }
    },
    [selected, receiverEndpoints]
  );

  const openSendModal = useCallback(() => setSendModalIsOpen(true), []);
  const closeSendModal = useCallback(() => setSendModalIsOpen(false), []);
  const openPublishModal = useCallback(() => setPublishModalIsOpen(true), []);
  const closePublishModal = useCallback(() => setPublishModalIsOpen(false), []);

  const getFilesProcessor = useCallback(
    (publish: boolean = false) => (files: IFileDto[]) => {
      props[publish ? "publishFiles" : "sendFiles"](
        files.map(({ id }) => id),
        selected.map(({ id }) => id)
      );
    },
    [selected]
  );

  return (
    <PageWithLogging isCollapsible={true}>
      <div className={classes.controlsBar}>
        <AppButton
          size={ButtonSize.TINY_BUTTON}
          handler={props.reload}
          disabled={reloadInProgress}
          testId="reload-button"
        >
          <div className={classes.buttonIcon}>
            <AutorenewIcon />
          </div>
          Reload
        </AppButton>
      </div>
      <TextField
        className={classes.searchField}
        id="standard-basic"
        label="Search..."
        onChange={handleSearchTerm}
        value={searchTerm}
      />
      {Object.keys(sections).map((label: any) => {
        const { title, items } = sections[`${label}`];

        return (
          <React.Fragment key={label}>
            <AccordionComponent
              title={`${title.toUpperCase()} (${searchedResults(searchTerm, items).length})`}
              panelId={label}
              defaultExpanded={true}
            >
              <EndpointsTable
                section={label}
                endpoints={searchedResults(searchTerm, items)}
                selected={selected}
                toggleSelection={toggleSelection}
              />
            </AccordionComponent>
            {label === EndpointsSections.FILTERED && (
              <div className={classes.controlsBar}>
                <AppButton size={ButtonSize.SMALL_BUTTON} handler={openPublishModal}>
                  <div className={classes.buttonIcon}>
                    <PublishIcon />
                  </div>
                  Publish files
                </AppButton>
                <AppButton
                  size={ButtonSize.SMALL_BUTTON}
                  disabled={selected.length === 0}
                  handler={openSendModal}
                >
                  <div className={classes.buttonIcon}>
                    <TelegramIcon />
                  </div>
                  Send files
                </AppButton>
              </div>
            )}
          </React.Fragment>
        );
      })}
      {sendModalIsOpen && (
        <SendFilesWindow
          publishMode={false}
          open={sendModalIsOpen}
          closeModal={closeSendModal}
          endpoints={selected}
          processFiles={getFilesProcessor()}
        />
      )}
      {publishModalIsOpen && (
        <SendFilesWindow
          publishMode={true}
          open={publishModalIsOpen}
          closeModal={closePublishModal}
          endpoints={selected}
          processFiles={getFilesProcessor(true)}
        />
      )}
    </PageWithLogging>
  );
};

export default EndpointsComponent;
