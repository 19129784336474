import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { FunctionComponent } from "react";
import { ButtonSize } from "../../shared/button-style";
import AppButton from "../common/button/button.component";

export interface ConnectedState {
  receiveAllProgress: boolean;
  receiveTodayProgress: boolean;
  clearFeedProgress: boolean;
}

export interface ConnectedDispatch {
  receiveAllFiles: () => void;
  receiveFromToday: () => void;
  clearFeed: () => void;
  cancelReceiveAll: () => void;
  cancelReceiveFromToday: () => void;
}

const useStyles = makeStyles((theme) => ({
  receiveButtonBar: {
    display: "flex",
  },
}));

interface Props extends ConnectedState, ConnectedDispatch {}

const ReceiveControllBar: FunctionComponent<Props> = ({
  receiveAllFiles,
  receiveFromToday,
  clearFeed,
  cancelReceiveAll,
  cancelReceiveFromToday,
  receiveAllProgress,
  receiveTodayProgress,
  clearFeedProgress,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.receiveButtonBar}>
      <AppButton
        typeName="button"
        handler={() => (receiveAllProgress ? cancelReceiveAll() : receiveAllFiles())}
        size={ButtonSize.SMALL_BUTTON}
        disabled={receiveTodayProgress || clearFeedProgress}
        progress={receiveAllProgress}
      >
        {receiveAllProgress ? "Cancel" : "Receive all"}
      </AppButton>
      <AppButton
        typeName="button"
        handler={() => (receiveTodayProgress ? cancelReceiveFromToday() : receiveFromToday())}
        size={ButtonSize.SMALL_BUTTON}
        disabled={receiveAllProgress || clearFeedProgress}
        progress={receiveTodayProgress}
      >
        {receiveTodayProgress ? "Cancel" : "Receive from today"}
      </AppButton>
      <AppButton
        typeName="button"
        handler={() => clearFeed()}
        size={ButtonSize.SMALL_BUTTON}
        disabled={clearFeedProgress || receiveTodayProgress || receiveAllProgress}
        progress={clearFeedProgress}
      >
        Clear feed
      </AppButton>
    </div>
  );
};

export default ReceiveControllBar;
