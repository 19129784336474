import { takeLatest, call, put } from "redux-saga/effects";

import logger from "../../logger";
import BackendClient from "../../api/backend-client";
import ApiMethods from "../../api/api-methods";
import { validateFailure, validateSuccessful, ValidationActionType } from "./validation.actions";
import { waitForInit } from "../global/global.saga";
import { setLastError } from "../global/global.actions";
import { SimulatorFile } from "../simulation/simulation.store";
import { Dispatch } from "redux";

const logError = logger.error("validation.saga");
const logInfo = logger.info("validation.saga");

let backendClient: BackendClient;

export function* validateFiles(): any {
  try {
    yield call(waitForInit);

    const files = yield call([backendClient, ApiMethods.GetSimulatorFileList]);
    const ids = files.map((file: SimulatorFile) => file.id);
    const validatedFiles = yield call([backendClient, ApiMethods.Validate], ids);
    logInfo(validatedFiles);
    yield put(validateSuccessful(validatedFiles));
  } catch (e) {
    yield put(setLastError(e));
    const message = "file validation is failed";
    yield put(validateFailure(message));
    logError(message);
  }
}

export default function* validationSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);

  yield takeLatest(ValidationActionType.VALIDATE_ALL_FILES, validateFiles);
}
