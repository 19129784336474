import { connect } from "react-redux";

import UploadModalWindow, { ConnectedProps } from "./upload-window.component";
import { AppState } from "../../../store/app.state";
import { getUploadedValidatedFileLog } from "../../../store/data-storage/data-storage.selectors";

const mapStateToProps = (state: AppState): ConnectedProps => ({
  validatedFileLog: getUploadedValidatedFileLog(state),
});

export default connect(mapStateToProps)(UploadModalWindow);
