import { connect } from "react-redux";

import EndpointDetailsComponent, { ConnectedState } from "./endpoints-details.component";
import { getEndpointById } from "../../store/endpoints/endpoints.selectors";
import { AppState } from "../../store/app.state";
import { getCapabilities, isMqtt } from "../../store/global/global.selectors";

export interface OwnProps {
  match: { params: { id?: string } };
}

const mapStateToProps = (
  state: AppState,
  {
    match: {
      params: { id = "" },
    },
  }: OwnProps
): ConnectedState => ({
  endpoint: getEndpointById(state, id),
  capabilities: getCapabilities(state).map(({ name, type }) => ({ name, type })),
  isMqtt: isMqtt(state),
});

export default connect(mapStateToProps)(EndpointDetailsComponent);
