import React, { FunctionComponent } from "react";
import moment from "moment";

import { Slider, Typography, Tooltip } from "@material-ui/core";
import TimerIcon from "@material-ui/icons/Timer";
import UpdateIcon from "@material-ui/icons/Update";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PersonIcon from "@material-ui/icons/Person";
import DashboardIcon from "@material-ui/icons/Dashboard";

import useStyles from "./simulation.styles";
import { Progress } from "../../store/simulation/simulation.store";
import { LOG_ENTRY_FORMAT } from "../../shared/time-formats";
import { INITIAL_CONFIGURATION } from "./simulator-configuration.component";

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent = (props: Props) => {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

export interface SliderProps {
  progress: Progress | null;
  isSimulationInProgress: boolean;
}

export const SimulationSlider: FunctionComponent<SliderProps> = ({
  progress,
  isSimulationInProgress,
}: SliderProps) => {
  const classes = useStyles();

  const {
    interval = INITIAL_CONFIGURATION.interval,
    scale = INITIAL_CONFIGURATION.scale,
    name = INITIAL_CONFIGURATION.timelog,
    timeLog: { start = "", end = "", current = "" } = {},
    simulation: { start: realStart = "", end: realEnd = "" } = {},
    position: { size = 1, current: currentPosition = 0 } = {},
  } = progress || {};

  return (
    <div className={`${classes.sliderWrapper}${!isSimulationInProgress ? ` ${classes.disabled}` : ""}`}>
      <div className={classes.sliderInfoSection}>
        <div className={classes.intervalInfo}>
          <div className={classes.sharedStyle}>
            <UpdateIcon /> {`${interval || 0}S`}
          </div>
        </div>
        <div className={classes.sharedStyle}>
          <FastForwardIcon /> {`${scale === INITIAL_CONFIGURATION.scale ? scale : scale.toFixed(2)}X`}
        </div>
        <div className={classes.sharedStyle}>
          <PersonIcon /> {realStart && moment(realStart).format(LOG_ENTRY_FORMAT)}
        </div>
        <div className={classes.sharedStyle}>
          <TimerIcon /> {`ETA ${realEnd && moment(realEnd).format(LOG_ENTRY_FORMAT)}`}
        </div>
      </div>
      <div className={classes.otherInfo}>
        <Typography className={classes.timelogTitle} variant="caption">
          {name}
        </Typography>
        <div className={[classes.size, classes.sharedStyle].join(" ")}>
          <DashboardIcon fontSize="small" />
          <Typography variant="caption">{`${currentPosition}/${size}`}</Typography>
        </div>
      </div>
      <Slider
        disabled={!isSimulationInProgress}
        className={classes.slider}
        valueLabelDisplay={isSimulationInProgress ? "on" : "off"}
        value={(currentPosition * 100) / size}
        valueLabelFormat={() => (current ? moment(current).format(LOG_ENTRY_FORMAT) : "")}
        ValueLabelComponent={ValueLabelComponent}
      />
      <div className={classes.duration}>
        <div className={classes.startSimulation}>
          <Typography variant="caption">{start ? moment(start).format(LOG_ENTRY_FORMAT) : "--"}</Typography>
        </div>
        <div className={classes.endSimulation}>
          <Typography variant="caption">{end ? moment(end).format(LOG_ENTRY_FORMAT) : "--"} </Typography>
        </div>
      </div>
    </div>
  );
};
