import { push } from "connected-react-router";
import { call, takeLeading, put } from "redux-saga/effects";

import logger from "../../logger";

import {
  AuthenticationActionType,
  loginSuccessful,
  loginFailure,
  logoutSuccessful,
  logout as logoutAction,
  logoutFailure,
} from "./authentication.actions";

import AuthService from "../../shared/services/auth.service";
import BackendClient from "../../api/backend-client";
import { setLastError } from "../global/global.actions";
import { showWarningRequest } from "../notifications/notifications.actions";
import { Action } from "../action";
import wsClient from "../../api/ws-client";
import { delay, takeEvery } from "typed-redux-saga";
import { checkStatus } from "../global/global.saga";
import RoutePaths from "../../routes/route-paths";
import { Dispatch } from "redux";
import ApiMethods from "../../api/api-methods";
import StorageService, { AuthLocalStorageKeys } from "../../shared/services/storage.service";

const logError = logger.error("authentication.saga");

let backendClient: BackendClient;

export function* login({ payload }: any): any {
  try {
    const { state, code } = payload;
    const { initialize, getToken } = AuthService.getInstance();
    const { data } = yield call([backendClient, ApiMethods.Auth], state, code);
    const success = yield call(initialize, data);

    if (success) {
      const token = yield call(getToken);
      yield call([backendClient, ApiMethods.SetToken], token);

      delay(1500);
      yield put(loginSuccessful());
    }
  } catch (e) {
    yield put(setLastError(e));
    yield AuthService.getInstance().deleteCookie();
    yield put(loginFailure("Error"));
    logError("Could not login user", e?.message || "");
  }
}

export function* scheduledTokenRefresh(): any {
  try {
    while (true) {
      yield call(refreshToken);
      const expiresIn =
        JSON.parse(StorageService.getInstance().getItem(AuthLocalStorageKeys.EXPIRES_IN) ?? "") ?? 300;
      const delayTime = (expiresIn - 60) * 1000;
      yield call(delay, delayTime);
    }
  } catch (e) {
    yield call(logError, "Could not refresh token ", e?.message || "");
  }
}

function* refreshToken(): any {
  try {
    const isRefreshTokenExpired = AuthService.getInstance().isRefreshTokenExpired();

    if (!isRefreshTokenExpired) {
      const { getRefreshToken, updateToken } = AuthService.getInstance();
      const refresh = yield call(getRefreshToken);
      const { data } = yield call([backendClient, ApiMethods.UpdateToken], refresh ?? "");

      yield call([backendClient, "setToken"], data?.access_token);

      yield call(updateToken, data);
    } else {
      yield put(showWarningRequest("Token expired"));
      yield delay(600);
      yield put(logoutAction(false));
    }
  } catch (e) {
    yield put(logoutAction(true));
  }
}

export function* relogin(): any {
  try {
    const { isLoggedIn } = AuthService.getInstance();

    if (isLoggedIn) {
      yield call(checkStatus);
      yield call(scheduledTokenRefresh);
    } else {
      yield call(internalLogout);
    }
  } catch (e) {
    logError("Could not relogin user", e?.message || "");
    yield call(internalLogout);
  }
}

export function* logout(action: Action<boolean>) {
  try {
    if (!action.payload) {
      const { getTokenId } = AuthService.getInstance();
      const id = getTokenId() ?? "";
      yield call([backendClient, ApiMethods.Logout], id);
    }
    yield call(internalLogout);
    yield put(logoutSuccessful());
  } catch (e) {
    if (action.payload) {
      yield put(logoutAction(false));
    } else {
      yield put(setLastError(e));
      yield call(internalLogout);
      logError("Could not logout user", e?.message || "");
    }
  }
}

export function* internalLogout(): any {
  try {
    yield call([localStorage, "clear"]);
    yield call([wsClient, "wsClose"]);
    yield AuthService.getInstance().deleteCookie();
    yield put(push(RoutePaths.Login));
    yield put(setLastError({}));
  } catch (e) {
    yield put(logoutFailure("Could not logout user"));
    logError("Could not logout user", e?.message || "");
  }
}

export default function* authenticationSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);

  yield takeLeading(AuthenticationActionType.LOGIN, login);
  yield takeLeading(AuthenticationActionType.LOGOUT, logout);
  yield takeEvery(AuthenticationActionType.RELOGIN, relogin);
  yield takeEvery(AuthenticationActionType.SCHEDULED_TOKEN_REFRESH, scheduledTokenRefresh);
  yield takeEvery(AuthenticationActionType.REFRESH_TOKEN, refreshToken);
}
