import { Dispatch } from "redux";
import { connect } from "react-redux";

import AppComponent, { ConnectedState, ConnectedDispatch } from "./app.component";
import { getFetchProgress, getLastError, getOnboardStatus } from "../../store/global/global.selectors";
import { AppState } from "../../store/app.state";
import {
  justifyOnboarding,
  fetchLoggingStatus,
  fetchTraceLoggingStatus,
} from "../../store/global/global.actions";
import { logout } from "../../store/authentication/authentication.actions";
import { getCurrentPathname } from "../../store/router.selectors";
import AuthService from "../../shared/services/auth.service";

export interface OwnProps {
  history: any;
}

const mapStateToProps = (state: AppState): ConnectedState => ({
  isLoading: getFetchProgress(state),
  lastError: getLastError(state),
  isOnboarded: getOnboardStatus(state),
  isLoggedIn: AuthService.getInstance().isLoggedIn ?? false,
  pathname: getCurrentPathname(state),
  roles: AuthService.getInstance().getRoles() ?? [],
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  logout: () => dispatch(logout()),
  justifyOnboarding: () => dispatch(justifyOnboarding()),
  fetchLoggingStatus: () => dispatch(fetchLoggingStatus()),
  fetchTraceLoggingStatus: () => dispatch(fetchTraceLoggingStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
