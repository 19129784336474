import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { logout } from "../../store/authentication/authentication.actions";
import { ButtonSize } from "../../shared/button-style";
import AppButton from "../../components/common/button/button.component";
import PaperComponent from "../../components/paper/paper.component";
import { Colors } from "../../theme/colors";
import { Typography } from "@material-ui/core";
import AuthService from "../../shared/services/auth.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoutButton: {
      width: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(1),
    },
    logoutIcon: {
      marginRight: "10px",
    },
    pageWrapper: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",

      "& > .MuiPaper-root": {
        borderTop: `2px solid ${Colors.Green}`,
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "150px",
      justifyContent: "space-around",
    },
  })
);

const NoAccessComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { userName } = AuthService.getInstance().getUserInfo();
  const onLogout = useCallback(() => dispatch(logout()), [dispatch]);

  return (
    <div className={classes.pageWrapper} {...{ "data-testid": "no-access-container" }}>
      <PaperComponent style={{ minWidth: 280 }}>
        <div className={classes.content}>
          <Typography>Please contact the administrator!</Typography>
          <div
            style={{ width: "90%" }}
            dangerouslySetInnerHTML={{ __html: `The user: <b>${userName}</b> has no access` }}
          />

          <AppButton handler={onLogout} className={classes.logoutButton} size={ButtonSize.TINY_BUTTON}>
            <div className={classes.logoutIcon}>
              <AccountCircleIcon />
            </div>
            Logout
          </AppButton>
        </div>
      </PaperComponent>
    </div>
  );
};

export default NoAccessComponent;
