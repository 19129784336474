import { Action } from "../action";
import { Endpoint } from "./endpoints.store";

export enum EndpointsActionType {
  SET_ENDPOINTS = "SET_ENDPOINTS",

  SEND_FILES = "SEND_FILES",
  SEND_FILES_SUCCESSFUL = "SEND_FILES_SUCCESSFUL",
  SEND_FILES_FAILURE = "SEND_FILES_FAILURE",

  PUBLISH_FILES = "PUBLISH_FILES",
  PUBLISH_FILES_SUCCESSFUL = "PUBLISH_FILES_SUCCESSFUL",
  PUBLISH_FILES_FAILURE = "PUBLISH_FILES_FAILURE",

  RELOAD = "RELOAD",
  RELOAD_SUCCESSFUL = "RELOAD_SUCCESSFUL",
  RELOAD_FAILURE = "RELOAD_FAILURE",

  FETCH_ALL_ENDPOINTS = "FETCH_ALL_ENDPOINTS",
  FETCH_ALL_ENDPOINTS_SUCCESSFUL = "FETCH_ALL_ENDPOINTS_SUCCESSFUL",
  FETCH_ALL_ENDPOINTS_FAILURE = "FETCH_ALL_ENDPOINTS_FAILURE",
}

export const setEndpoints = (
  allEndpoints: Endpoint[],
  receiverEndpoints: Endpoint[]
): Action<{ allEndpoints: Endpoint[]; receiverEndpoints: Endpoint[] }> => ({
  type: EndpointsActionType.SET_ENDPOINTS,
  payload: { allEndpoints, receiverEndpoints },
});

export const sendFiles = (files: string[], endpoints: string[]): Action<any[]> => ({
  type: EndpointsActionType.SEND_FILES,
  payload: [files, endpoints, true],
});

export const sendFilesSuccessful = (): Action<null> => ({
  type: EndpointsActionType.SEND_FILES_SUCCESSFUL,
  payload: null,
});

export const sendFilesFailure = (): Action<null> => ({
  type: EndpointsActionType.SEND_FILES_FAILURE,
  payload: null,
});

export const publishFiles = (files: string[], endpoints: string[]): Action<any[]> => ({
  type: EndpointsActionType.PUBLISH_FILES,
  payload: [files, endpoints, true],
});

export const publishFilesSuccessful = (): Action<null> => ({
  type: EndpointsActionType.PUBLISH_FILES_SUCCESSFUL,
  payload: null,
});

export const publishFilesFailure = (): Action<null> => ({
  type: EndpointsActionType.PUBLISH_FILES_FAILURE,
  payload: null,
});

export const reload = (): Action<null> => ({
  type: EndpointsActionType.RELOAD,
  payload: null,
});

export const reloadSuccessful = (): Action<null> => ({
  type: EndpointsActionType.RELOAD_SUCCESSFUL,
  payload: null,
});

export const reloadFailure = (): Action<null> => ({
  type: EndpointsActionType.RELOAD_FAILURE,
  payload: null,
});

export const fetchAllEndpoints = (): Action<null> => ({
  type: EndpointsActionType.FETCH_ALL_ENDPOINTS,
  payload: null,
});

export const fetchAllEndpointsSuccessful = (): Action<null> => ({
  type: EndpointsActionType.FETCH_ALL_ENDPOINTS_SUCCESSFUL,
  payload: null,
});

export const fetchAllEndpointsFailure = (): Action<null> => ({
  type: EndpointsActionType.FETCH_ALL_ENDPOINTS_FAILURE,
  payload: null,
});
