import React, { FunctionComponent } from "react";

import { makeStyles, Paper, Table, TableHead, TableBody } from "@material-ui/core";

export interface Props {
  tableHead?: React.ReactNode;
  tableBody: React.ReactNode[];
  tableTitle?: React.ReactNode;
  className?: string;
  fixed?: boolean;
  empty?: boolean;
  stickyHeader?: boolean;
  size?: "small" | "medium";
  isBottomFixed?: boolean;
  style?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  fixedTable: {
    minWidth: 650,
  },
}));

const TableComponent: FunctionComponent<Props> = ({
  tableHead,
  tableBody,
  tableTitle,
  className,
  fixed = true,
  size = "small",
  empty,
  stickyHeader,
  style,
}) => {
  const classes = useStyles();

  return (
    <div className={`${className} ${classes.root}`}>
      {tableTitle}
      <Paper className={classes.paper} style={style ?? {}}>
        <Table
          className={fixed ? classes.fixedTable : undefined}
          size={size}
          aria-label="a dense table"
          stickyHeader={!!stickyHeader}
        >
          {empty && <caption style={{ textAlign: "center" }}>No data</caption>}
          {tableHead && <TableHead>{tableHead}</TableHead>}
          <TableBody>{tableBody}</TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default TableComponent;
