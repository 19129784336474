import { connect } from "react-redux";
import { Dispatch } from "redux";

import SimulatorConfigurationComponent, {
  ConnectedState,
  ConnectedDispatch,
  ConfigurationParams,
} from "./simulator-configuration.component";

import { AppState } from "../../store/app.state";
import { getTimelogs } from "../../store/simulation/simulation.actions";

import {
  getSimulationTimelogs,
  getSimulationTimelogsStatus,
} from "../../store/simulation/simulation.selectors";

import { SimulatorFile } from "../../store/simulation/simulation.store";

export interface OwnProps {
  files: { [key: string]: SimulatorFile[] };
  configuration: ConfigurationParams;
  isSimulationInProgress: boolean;
  onUpdateConfiguration: (configuration: ConfigurationParams) => any;
  stopSimulation: () => any;
}

const mapStateToProps = (state: AppState): ConnectedState => ({
  timelogs: getSimulationTimelogs(state),
  getTimelogsStatus: getSimulationTimelogsStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  getTimelogs: (fileIds: string[]) => dispatch(getTimelogs(fileIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimulatorConfigurationComponent);
