export interface IButtonSize {
  width: number;
}

export const ButtonSize = {
  TINY_BUTTON: { width: 100 },
  SMALL_BUTTON: { width: 150 },
  NORMAL_BUTTON: { width: 190 },
  MEDIUM_BUTTON: { width: 200 },
  LARGE_BUTTON: { width: 290 },
};
