import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Colors } from "../../theme/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    section: {
      padding: "10px",
      flex: 1,
    },
    passwordSection: {
      width: "30%",
      minWidth: "350px",
    },
    userContainer: {
      alignItems: "center",
      justifyContent: "space-between",
    },
    oneButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
    dangerButton: {
      backgroundColor: Colors.Red,

      "&:hover": {
        backgroundColor: Colors.Red2,
      },
    },
    reconnectButton: {
      backgroundColor: Colors.Green2,

      "&:hover": {
        backgroundColor: Colors.DarkGreen,
      },
    },
    cancelButton: {
      backgroundColor: Colors.Grey2,

      "&:hover": {
        backgroundColor: Colors.Grey,
      },
    },
    block: {
      "& h6": {
        fontWeight: "bold",
      },
    },
    error: {
      color: Colors.Red3,
    },
    infoWrapper: {
      display: "flex",
    },
    label: {
      minWidth: 110,
    },
  })
);

export default useStyles;
