import { ConnectedComponent } from "react-redux";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import RoutePaths from "./route-paths";
import DataStorageComponent from "../pages/data-storage/data-storage.container";
import EndpointsComponent from "../pages/endpoints/endpoints.container";
import SettingsComponent from "../pages/settings/settings.container";
import SimulationComponent from "../pages/simulation/simulation.container";
import StorageIcon from "@material-ui/icons/Storage";
import UpdateIcon from "@material-ui/icons/Update";
import SettingsIcon from "@material-ui/icons/Settings";
import DescriptionIcon from "@material-ui/icons/Description";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import LoggingComponent from "../pages/logging/logging.component";
import endpointsDetailsContainer from "../pages/endpoints-details/endpoints-details.container";
import telemetryContainer from "../pages/telemetry/telemetry.container";

export interface Route {
  name: string;
  path: RoutePaths;
  component: (() => JSX.Element) | ConnectedComponent<any, any>;
  onboarded: boolean;
}

export interface SidebarRoute extends Route {
  icon: (props: SvgIconProps) => JSX.Element;
}

export const DEFAULT_ONBOARD_ROUTE = RoutePaths.DataStorage;
export const DEFAULT_OFFBOARD_ROUTE = RoutePaths.Settings;
export const ONBOARD_PATHNAME = "/rest/onboard";

export const sidebarRoutes: SidebarRoute[] = [
  {
    name: "Data Storage",
    path: RoutePaths.DataStorage,
    icon: StorageIcon,
    component: DataStorageComponent,
    onboarded: false,
  },
  {
    name: "Endpoints",
    path: RoutePaths.Endpoints,
    icon: SettingsInputAntennaIcon,
    component: EndpointsComponent,
    onboarded: true,
  },
  {
    name: "Simulation",
    path: RoutePaths.Simulation,
    icon: UpdateIcon,
    component: SimulationComponent,
    onboarded: true,
  },
  {
    name: "Logging",
    path: RoutePaths.Logging,
    icon: DescriptionIcon,
    component: LoggingComponent,
    onboarded: false,
  },
  {
    name: "Settings",
    path: RoutePaths.Settings,
    icon: SettingsIcon,
    component: SettingsComponent,
    onboarded: false,
  },
];

export const additionalRoutes: Route[] = [
  {
    name: "Endpoints Details",
    path: RoutePaths.EndpointsDetails,
    component: endpointsDetailsContainer,
    onboarded: true,
  },
  {
    name: "Telemetry",
    path: RoutePaths.Telemetry,
    component: telemetryContainer,
    onboarded: true,
  },
];
