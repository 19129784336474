import { connect } from "react-redux";
import { Dispatch } from "redux";

import DataStorageComponent, { ConnectedState, ConnectedDispatch } from "./data-storage.component";

import {
  getFiles,
  reloadFiles,
  getReloadProgress,
  uploadFile,
  updateFileList,
} from "../../store/data-storage";

import { AppState } from "../../store/app.state";
import { getPublishFilesProgress, getSendFilesProgress } from "../../store/endpoints/endpoints.selectors";
import { getValidationProgress } from "../../store/validation/validation.selectors";
import { getUploadModalWindow, getValidationModalWindow } from "../../store/common/common.selectors";
import { toggleUploadModalWindow, toggleValidationModalWindow } from "../../store/common/common.actions";
import { getOnboardStatus } from "../../store/global/global.selectors";
import AuthService from "../../shared/services/auth.service";

const mapStateToProps = (state: AppState): ConnectedState => ({
  files: getFiles(state),
  filesReloadProgress: getReloadProgress(state),
  sendFilesProgress: getSendFilesProgress(state),
  publishFilesProgress: getPublishFilesProgress(state),
  progressValidationStatus: getValidationProgress(state),
  isOpen: getUploadModalWindow(state),
  isValidationOpen: getValidationModalWindow(state),
  isOnboarded: getOnboardStatus(state),
  isLoggedIn: AuthService.getInstance().isLoggedIn ?? false,
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  reloadFiles: () => dispatch(reloadFiles()),
  uploadFile: (file) => dispatch(uploadFile(file)),
  toggleUploadWindow: (isOpen: boolean) => dispatch(toggleUploadModalWindow(isOpen)),
  toggleValidationModalWindow: (isValidModalOpen: boolean) =>
    dispatch(toggleValidationModalWindow(isValidModalOpen)),
  fetchFiles: () => dispatch(updateFileList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataStorageComponent);
