import React, { FunctionComponent, ReactNode, MouseEventHandler } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      width: "600px",
      maxHeight: "80%",
      overflowY: "scroll",
      lineHeight: "30px",
      padding: theme.spacing(2, 3, 2),
      "&:focus": {
        outline: "none",
      },
    },
  })
);

interface Props {
  open: boolean;
  closeModal?: MouseEventHandler;
  children?: ReactNode;
  size?: string;
  backdropBacground?: boolean;
}

const TransitionsModal: FunctionComponent<Props> = ({
  open,
  closeModal,
  children,
  size,
  backdropBacground,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={closeModal}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          invisible: backdropBacground,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{ width: size }}>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
