import { Action } from "../action";
import { GeoJSON, TelemetryCaption, TelemetryEntry } from "./telemetry.store";

export enum TelemetryActionType {
  INIT_TELEMETRY = "INIT_TELEMETRY",
  INIT_TELEMETRY_SUCCESSFUL = "INIT_TELEMETRY_SUCCESSFUL",
  INIT_TELEMETRY_FAILURE = "INIT_TELEMETRY_FAILURE",

  LOAD_TELEMETRY = "LOAD_TELEMETRY",
  LOAD_TELEMETRY_SUCCESSFUL = "LOAD_TELEMETRY_SUCCESSFUL",
  LOAD_TELEMETRY_FAILURE = "LOAD_TELEMETRY_FAILURE",

  SET_TELEMETRY = "SET_TELEMETRY",
  RESET_TELEMETRY = "RESET_TELEMETRY",

  UPDATE_TELEMETRY = "UPDATE_TELEMETRY",
  UPDATE_TELEMETRY_SUCCESSFUL = "UPDATE_TELEMETRY_SUCCESSFUL",
  UPDATE_TELEMETRY_FAILURE = "UPDATE_TELEMETRY_FAILURE",

  DOWNLOAD = "DOWNLOAD",
  DOWNLOAD_SUCCESSFUL = "DOWNLOAD_SUCCESSFUL",
  DOWNLOAD_FAILURE = "DOWNLOAD_FAILURE",
}

export type GetTelemetryAction = Action<{ fileId: string; rows: number }>;

export const initTelemetry = (fileId: string, rows: number): GetTelemetryAction => ({
  type: TelemetryActionType.INIT_TELEMETRY,
  payload: { fileId, rows },
});

export type InitTelemetrySuccessfulAction = Action<{ caption: TelemetryCaption; total: number }>;

export const initTelemetrySuccessful = (
  caption: TelemetryCaption,
  total: number
): InitTelemetrySuccessfulAction => ({
  type: TelemetryActionType.INIT_TELEMETRY_SUCCESSFUL,
  payload: { caption, total },
});

export const initTelemetryFailure = (): Action<null> => ({
  type: TelemetryActionType.INIT_TELEMETRY_FAILURE,
  payload: null,
});

export type SetTelemetryAction = Action<{ telemetry: TelemetryEntry[][]; geoJSON: GeoJSON; offset: number }>;

export const setTelemetry = (
  telemetry: TelemetryEntry[][],
  geoJSON: GeoJSON,
  offset: number
): SetTelemetryAction => ({
  type: TelemetryActionType.SET_TELEMETRY,
  payload: { telemetry, geoJSON, offset },
});

export const updateTelemetry = (fileId: string): Action<{ fileId: string }> => ({
  type: TelemetryActionType.UPDATE_TELEMETRY,
  payload: { fileId },
});

export const updateTelemetrySuccessful = (): Action<null> => ({
  type: TelemetryActionType.UPDATE_TELEMETRY_SUCCESSFUL,
  payload: null,
});

export const updateTelemetryFailure = (): Action<null> => ({
  type: TelemetryActionType.UPDATE_TELEMETRY_FAILURE,
  payload: null,
});

export const loadTelemetry = (fileId: string, rows: number): GetTelemetryAction => ({
  type: TelemetryActionType.LOAD_TELEMETRY,
  payload: { fileId, rows },
});

export const loadTelemetrySuccessful = (): Action<null> => ({
  type: TelemetryActionType.LOAD_TELEMETRY_SUCCESSFUL,
  payload: null,
});

export const loadTelemetryFailure = (): Action<null> => ({
  type: TelemetryActionType.LOAD_TELEMETRY_FAILURE,
  payload: null,
});

export type DownloadTelemetryAction = Action<{ isIsoxml: boolean; id: string }>;

export const download = (isIsoxml: boolean, id: string): DownloadTelemetryAction => ({
  type: TelemetryActionType.DOWNLOAD,
  payload: { isIsoxml, id },
});

export const downloadSuccessful = (): Action<null> => ({
  type: TelemetryActionType.DOWNLOAD_SUCCESSFUL,
  payload: null,
});

export const downloadFailure = (): Action<null> => ({
  type: TelemetryActionType.DOWNLOAD_FAILURE,
  payload: null,
});

export const resetTelemetry = (): Action<null> => ({
  type: TelemetryActionType.RESET_TELEMETRY,
  payload: null,
});
