import createReducer from "../createReducer";
import { Action } from "../action";

import initialState, { IValidationMessage, ValidationState } from "./validation.store";
import { ValidationActionType } from "./validation.actions";

export default createReducer(initialState, {
  [ValidationActionType.VALIDATE_ALL_FILES](state: ValidationState): ValidationState {
    return { ...state, validationInProgress: true };
  },
  [ValidationActionType.VALIDATE_ALL_FILES_SUCCESSFUL](
    state: ValidationState,
    action: Action<IValidationMessage>
  ): ValidationState {
    const validationMessage = action.payload;
    return {
      ...state,
      validationLogs: { ...state.validationLogs, ...validationMessage },
      validationInProgress: false,
    };
  },
  [ValidationActionType.VALIDATE_ALL_FILES_FAILURE](state: ValidationState, action: any): ValidationState {
    return { ...state, validationInProgress: false };
  },
});
