import React from "react";
import ReactDOM from "react-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { DEBUG_BASE } from "./logger";
import ReduxRoot from "./redux-root";
import { ioTheme } from "./theme/theme";
import StorageService from "./shared/services/storage.service";

const storageService = StorageService.getInstance();
if (process.env.NODE_ENV !== "production") {
  storageService.setItem("debug", `${DEBUG_BASE}*`);
}

const rootEl = document.getElementById("root");

ReactDOM.render(
  <>
    <MuiThemeProvider theme={ioTheme}>
      <CssBaseline>
        <ReduxRoot />
      </CssBaseline>
    </MuiThemeProvider>
  </>,
  rootEl
);
