import { takeLatest, call, put } from "redux-saga/effects";
import { saveAs } from "file-saver";

import BackendClient from "../../api/backend-client";
import ApiMethods from "../../api/api-methods";

import {
  exportEfdiFailure,
  exportEfdiSuccessfull,
  forwardSimulationFailure,
  forwardSimulationSuccessful,
  getFilesFailure,
  getFilesSuccessful,
  getTimelogsFailure,
  getTimelogsSuccessful,
  pauseSimulationFailure,
  pauseSimulationSuccessful,
  resumeSimulationFailure,
  resumeSimulationSuccessful,
  setConfiguration,
  setProgress,
  SimulationActionType,
  startSimulationFailure,
  startSimulation as startSimulationAction,
  stopSimulation,
  stopSimulationFailure,
  stopSimulationSuccessful,
} from "./simulation.actions";

import wsClient from "../../api/ws-client";
import logger from "../../logger";
import { store } from "../index";
import { MethodsName, Roles, WsMethods } from "../../utils/ws-methods";
import { setLastError } from "../global/global.actions";
import { Action } from "../action";
import { Progress } from "./simulation.store";
import { showFailedRequest } from "../notifications/notifications.actions";
import { ExportParams } from "../../pages/simulation/simulation.component";
import { select } from "typed-redux-saga";
import { getProgress } from "./simulation.selectors";
import { Dispatch } from "redux";
import { IStartSimulate } from "../../api/backend-api";
import { transformer } from "../../utils/files-transformer";

const logError = logger.error("simulation.saga");
const logInfo = logger.info("simulation.saga");

let backendClient: BackendClient;

const saveProgress = (progress: Progress) => {
  store.dispatch(setProgress(progress));
};

function* initSimulation() {
  try {
    const progress = yield call([backendClient, ApiMethods.GetSimulationProgress]);

    const existsProgress = yield select(getProgress);
    yield put(setProgress(progress));

    if (Object.keys(progress).length && !existsProgress) {
      yield put(setConfiguration(progress.configuration));
      yield put(startSimulationAction(progress.configuration));
    }

    const files = yield call([backendClient, ApiMethods.GetSimulatorFileList]);

    logInfo(`${Roles.SIMULATOR}.${MethodsName.LIST_FILES}`, files.map(transformer));
    yield put(getFilesSuccessful(files.map(transformer)));
  } catch (e) {
    yield put(getFilesFailure());
    yield put(setLastError(e));
    logError("get files for simulator is failed");
  }
}

function* getTimelogs({ payload: fileIds }: Action<string[]>) {
  try {
    const data = yield call([backendClient, ApiMethods.GetTimeLogInfo], fileIds[0]);
    logInfo(MethodsName.GET_TIMELOGS, data);
    const timelogs = Object.keys(data.timelogs).map((id: string) => ({ ...data.timelogs[id], id }));
    yield put(getTimelogsSuccessful(data.ddis, timelogs));
  } catch (e) {
    yield put(getTimelogsFailure());
    yield put(setLastError(e));
    logError("get timelog info for simulator is failed");
  }
}

function* startSimulation({ payload }: Action<any>): any {
  try {
    const { file, timelog, endless, replaceTime, skipInitial, interval, scale } = payload;

    const requestBody: IStartSimulate = {
      fileId: file,
      timeLogName: timelog,
      endless,
      replaceTime,
      skip: skipInitial,
      interval,
      scale,
    };

    const existsProgress = yield select(getProgress);
    if (!existsProgress || !Object.keys(existsProgress).length) {
      const progress = yield call([backendClient, ApiMethods.Simulate], requestBody);

      if (progress.hasOwnProperty("success") && !progress.success) {
        yield put(stopSimulation());
      } else {
        yield put(setProgress(progress));
        wsClient.subscribe(`${Roles.SIMULATOR}.${WsMethods.PROGRESS}`, saveProgress);
        logInfo("simulation is stated");
      }
    }
  } catch (e) {
    yield put(showFailedRequest(e.message));
    yield put(startSimulationFailure("error"));
    yield put(stopSimulation());
    yield put(setLastError(e));
    logError("start simulation is failed");
  }
}

function* stopSimulate() {
  try {
    yield call([backendClient, ApiMethods.Stop]);
    yield put(stopSimulationSuccessful());
    wsClient.unsubscribe("simulator.progress");
    logInfo("simulation is stopped");
  } catch (e) {
    yield put(showFailedRequest(e.message));
    yield put(stopSimulationFailure("stop simulation is failed"));
    yield put(setLastError(e));
    logError("stop simulation is failed");
  }
}

function* pauseSimulation(): any {
  try {
    const progress = yield call([backendClient, ApiMethods.Pause]);
    yield put(setProgress(progress));
    yield put(pauseSimulationSuccessful());
    logInfo("simulation is paused");
  } catch (e) {
    yield put(showFailedRequest(e.message));
    yield put(pauseSimulationFailure("error"));
    yield put(setLastError(e));
    logError("pause simulation is failed");
  }
}

function* resumeSimulation(): any {
  try {
    const progress = yield call([backendClient, ApiMethods.Resume]);
    yield put(setProgress(progress));
    yield put(resumeSimulationSuccessful());
    logInfo("simulation is re-stated");
  } catch (e) {
    yield put(resumeSimulationFailure("error"));
    yield put(setLastError(e));
    yield put(showFailedRequest(e.message));
    logError("resume simulation is failed");
  }
}

function* forwardSimulation(): any {
  try {
    const progress = yield call([backendClient, ApiMethods.Forward]);
    yield put(setProgress(progress));
    yield put(forwardSimulationSuccessful());
    logInfo("go to the next simulation");
  } catch (e) {
    yield put(showFailedRequest(e.message));
    yield put(forwardSimulationFailure("go to next simulation is failed"));
    yield put(setLastError(e));
    logError("go to the next simulation is failed");
  }
}

function* exportEfdi({
  payload: { id, params, filename },
}: Action<{ id: string; params: ExportParams; filename: string }>) {
  try {
    const { file } = yield call([backendClient, ApiMethods.ExportEfdi], id, params);
    saveAs(new Blob([file]), `${filename}`);
    yield put(exportEfdiSuccessfull());
  } catch (e) {
    yield put(showFailedRequest(e.message));
    yield put(exportEfdiFailure());
    yield put(setLastError(e));
    logError("export EFDI is failed");
  }
}

export default function* simulationSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);

  yield takeLatest(SimulationActionType.GET_FILES, initSimulation);
  yield takeLatest(SimulationActionType.GET_TIMELOGS, getTimelogs);
  yield takeLatest(SimulationActionType.START_SIMULATION, startSimulation);
  yield takeLatest(SimulationActionType.FORWARD_SIMULATION, forwardSimulation);
  yield takeLatest(SimulationActionType.STOP_SIMULATION, stopSimulate);
  yield takeLatest(SimulationActionType.PAUSE_SIMULATION, pauseSimulation);
  yield takeLatest(SimulationActionType.RESUME_SIMULATION, resumeSimulation);
  yield takeLatest(SimulationActionType.EXPORT, exportEfdi);
}
