import createReducer from "../createReducer";
import { Action } from "../action";

import initialState, {
  Progress,
  SimulationState,
  SimulatorFile,
  Device,
  SimulatorTimelogEntry,
} from "./simulation.store";

import { SimulationActionType } from "./simulation.actions";
import { loadFailed, loadPending, loadSuccess } from "../loading-status";
import { ConfigurationParams } from "../../pages/simulation/simulator-configuration.component";

export default createReducer(initialState, {
  [SimulationActionType.GET_FILES](state: SimulationState): SimulationState {
    return { ...state, getFilesInProgress: true };
  },
  [SimulationActionType.GET_FILES_SUCCESSFUL](
    state: SimulationState,
    { payload: files }: Action<SimulatorFile[]>
  ): SimulationState {
    return { ...state, files, getFilesInProgress: false };
  },
  [SimulationActionType.GET_FILES_FAILURE](state: SimulationState): SimulationState {
    return { ...state, getFilesInProgress: false };
  },
  [SimulationActionType.GET_TIMELOGS](state: SimulationState): SimulationState {
    return { ...state, getTimelogsInProgress: true, timelogs: [] };
  },
  [SimulationActionType.GET_TIMELOGS_SUCCESSFUL](
    state: SimulationState,
    { payload: { timelogs, devices } }: Action<{ devices: Device[]; timelogs: SimulatorTimelogEntry[] }>
  ): SimulationState {
    return { ...state, devices, timelogs, getTimelogsInProgress: false };
  },
  [SimulationActionType.GET_TIMELOGS_FAILURE](state: SimulationState): SimulationState {
    return { ...state, getTimelogsInProgress: false };
  },
  [SimulationActionType.START_SIMULATION](state: SimulationState): SimulationState {
    return { ...state, simulationInProgress: true, loadStatus: loadPending() };
  },
  [SimulationActionType.START_SIMULATION_SUCCESSFUL](
    state: SimulationState,
    action: Action<any>
  ): SimulationState {
    return { ...state, simulationInProgress: false, loadStatus: loadSuccess() };
  },
  [SimulationActionType.START_SIMULATION_FAILURE](state: SimulationState, action: any): SimulationState {
    return { ...state, simulationInProgress: false, loadStatus: loadFailed(action.payload) };
  },
  [SimulationActionType.STOP_SIMULATION](state: SimulationState): SimulationState {
    return { ...state, loadStatus: loadPending() };
  },
  [SimulationActionType.STOP_SIMULATION_SUCCESSFUL](state: SimulationState): SimulationState {
    return { ...state, simulationInProgress: false, loadStatus: loadSuccess() };
  },
  [SimulationActionType.STOP_SIMULATION_FAILURE](state: SimulationState, action: any): SimulationState {
    return { ...state, loadStatus: loadFailed(action.payload) };
  },
  [SimulationActionType.SET_PROGRESS](
    state: SimulationState,
    { payload }: Action<Progress>
  ): SimulationState {
    return { ...state, progress: payload };
  },
  [SimulationActionType.SET_CONFIGURATION](
    state: SimulationState,
    { payload }: Action<ConfigurationParams>
  ): SimulationState {
    return { ...state, configuration: payload };
  },
  [SimulationActionType.RESET](state: SimulationState): SimulationState {
    return { ...state, progress: null, simulationInProgress: false };
  },
  [SimulationActionType.EXPORT](state: SimulationState): SimulationState {
    return { ...state, exportInProgress: true };
  },
  [SimulationActionType.EXPORT_SUCCESSFUL](state: SimulationState): SimulationState {
    return { ...state, exportInProgress: false };
  },
  [SimulationActionType.EXPORT_FAILURE](state: SimulationState): SimulationState {
    return { ...state, exportInProgress: false };
  },
});
