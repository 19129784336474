enum RoutePaths {
  Login = "/login",
  DataStorage = "/data-storage",
  Endpoints = "/endpoints",
  EndpointsDetails = "/endpoints/:id",
  Simulation = "/simulation",
  Logging = "/logging",
  Settings = "/settings",
  Telemetry = "/data-storage/telemetry/:fileId",
  AdminPanel = "/admin-users",
  Auth = "/auth/callback",
  Callback = "/callback",
}

export default RoutePaths;
