import { createSelector } from "reselect";
import AuthService from "../../shared/services/auth.service";

import { AppState } from "../app.state";

export const getAuthenticationState = (state: AppState) => state.authentication;

export const getAuthenticationStatus = createSelector(
  getAuthenticationState,
  (state) => state.loginInProgress || false
);

export const getChangePasswordStatus = createSelector(
  getAuthenticationState,
  (state) => state.changePasswordInProgress || false
);

export const getChangePasswordError = createSelector(
  getAuthenticationState,
  (state) => state.changePasswordError || ""
);

export const getIsAuthenticated = createSelector(
  getAuthenticationState,
  (state) => state.isAuthenticated || AuthService.getInstance().isLoggedIn
);

export const getUsername = createSelector(getAuthenticationState, (state) => state.user?.username || "");

export const isUserAdmin = createSelector(getAuthenticationState, (state) => state.user?.isAdmin || false);
