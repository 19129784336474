import { connect } from "react-redux";

import DeleteConfirmationWindow, { ConnectedDispatch } from "./delete-confirmation-window.component";
import { Dispatch } from "redux";
import { deleteFile } from "../../../store/data-storage";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  deleteFiles: (ids: string[]) => dispatch(deleteFile(ids)),
});

export default connect(null, mapDispatchToProps)(DeleteConfirmationWindow);
