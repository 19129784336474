import { takeLatest, call, select, take } from "redux-saga/effects";
import { put } from "redux-saga-test-plan/matchers";
import { push } from "connected-react-router";

import { relogin, AuthenticationActionType } from "../authentication/authentication.actions";
import { updateFileList } from "../data-storage/data-storage.actions";
import { setEndpoints } from "../endpoints/endpoints.actions";
import wsClient from "../../api/ws-client";
import BackendClient from "../../api/backend-client";
import { LogEntry, StatusData } from "../../api/backend-api";
import ApiMethods from "../../api/api-methods";

import {
  getDataStatus,
  getOnboardingJustificationStatus,
  getOnboardStatus,
  getPushNotifications,
  getStatusData,
} from "./global.selectors";

import {
  GlobalActionType,
  initFailure,
  initSuccessfull,
  setLastError,
  setCapabilities,
  setLogs,
  setLogEntry,
  saveCapabilitiesSuccessful,
  saveCapabilitiesFailure,
  onboardSuccessful,
  onboardFailure,
  disconnectSuccessful,
  disconnectFailure,
  setStatusData,
  reloadCapabilitiesSuccessful,
  setSubscriptions,
  saveSubscriptionsSuccessful,
  saveSubscriptionsFailure,
  reloadSubscriptionsSuccessful,
  reloadSubscriptionsFailure,
  clearLogsFailure,
  clearLogsSuccess,
  reloadCapabilitiesFailure,
  reconnectSuccessful,
  reconnectFailure,
  justifyOnboardingFinish,
  setInitializationCheckingDone,
  fetchLoggingStatusesFailure,
  fetchLoggingStatusesSuccess,
  changeLoggingStatusSuccess,
  changeLoggingStatusFailure,
  fetchTraceLoggingStatusesSuccess,
  changeTraceLoggingStatusSuccess,
  fetchTraceLoggingStatusesFailure,
  changeTraceLoggingStatusFailure,
  reloadCapabilities,
  reloadSubscriptions,
  reonboardSuccessful,
  fetchEnvironmentsSuccess,
} from "./global.actions";

import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import logger from "../../logger";

import { MethodsName, Roles, WsMethods } from "../../utils/ws-methods";

import { FailureMessages, SuccessMessages } from "../../utils/notifications.messages";
import { store } from "../index";
import { DEFAULT_OFFBOARD_ROUTE, DEFAULT_ONBOARD_ROUTE, ONBOARD_PATHNAME } from "../../routes/routes";
import { getCurrentLocation, getCurrentPathname } from "../router.selectors";
import { reload } from "../endpoints/endpoints.saga";
import { Dispatch } from "redux";

const logError = logger.error("global.saga");
const logInfo = logger.info("global.saga");

let backendClient: BackendClient;

const addLogEntryToStore = (data: LogEntry) => {
  if (data.text.includes("deactivated")) {
    store.dispatch(relogin());
  } else store.dispatch(setLogEntry(data));
};

const updateFilesList = () => store.dispatch(updateFileList());

export function* init(): any {
  try {
    const redirectUri = yield call([backendClient, ApiMethods.GetUrl]);

    window.location.href = redirectUri;
  } catch (e) {
    yield put(initFailure());
    yield put(showFailedRequest(e?.message));
  }
}

export function* justifyOnboarding() {
  try {
    const location = yield select(getCurrentLocation);
    yield call([backendClient, ApiMethods.JustifyOnboarding], location);
  } catch (e) {
    logError("Unable to start onboarding process on the backend", e?.message || "");
  } finally {
    yield put(justifyOnboardingFinish());
  }
}

export function* checkStatus(): any {
  try {
    const justificationStatus = yield select(getOnboardingJustificationStatus);
    const currentPath = yield select(getCurrentPathname);

    if (currentPath === ONBOARD_PATHNAME && !justificationStatus) {
      yield take(GlobalActionType.JUSTIFY_ONBOARDING_FINISH);
    }

    const status = yield call([backendClient, ApiMethods.GetOnboardStatus]);

    logInfo(MethodsName.STATUS, status);
    yield put(setStatusData(status));
    yield put(setInitializationCheckingDone());

    if (status.onboarding) {
      yield call(secureOnboard);
    }
  } catch (e) {
    yield put(setLastError(e));
    logError("Unable to get status", e?.message || "");
  }
}

export function* secureOnboard() {
  try {
    const result = yield call([backendClient, ApiMethods.Onboard]);

    if (!result?.success) {
      throw new Error(result?.message || "Onboarding was unsuccessfull. Please, try later");
    }

    yield call(checkStatus);
  } catch (e) {
    yield put(showFailedRequest(e.message));
    const currentStatusData = yield select(getStatusData);
    yield put(setStatusData({ ...currentStatusData, onboarding: false, onboarded: false }));
    yield put(setInitializationCheckingDone());
  }
}

export function* fetchData(): any {
  try {
    const isOnboard = yield select(getOnboardStatus);
    const currentPath = yield select(getCurrentPathname);

    const logs = yield call([backendClient, ApiMethods.FetchLogs]);
    yield put(setLogs(logs || []));
    wsClient.subscribe(`${Roles.USER}.${WsMethods.LOG}`, addLogEntryToStore);

    if (isOnboard) {
      const { all = [], receiver = [] } = yield call([backendClient, ApiMethods.GetEndpoints]);
      yield put(setEndpoints(all, receiver));

      const { capabilities = [], pushNotifications = 0 } = yield call([
        backendClient,
        ApiMethods.GetCapabilities,
      ]);
      yield put(setCapabilities(capabilities, pushNotifications));

      const subs = yield call([backendClient, ApiMethods.GetSubscriptions]);

      yield put(setSubscriptions(subs));

      wsClient.subscribe(`${Roles.FILE}.${WsMethods.FILE}`, updateFilesList);
      if (currentPath === "/") {
        yield put(push(DEFAULT_ONBOARD_ROUTE));
      }
    } else {
      yield put(push(DEFAULT_OFFBOARD_ROUTE));
    }

    yield put(initSuccessfull());
  } catch (e) {
    yield put(initFailure());
    yield put(setLastError(e));
    logError("Could not init application", e?.message || "");
  }
}

const updateCapabilities = ({ success }: any) => {
  if (success) {
    store.dispatch(reloadCapabilities());
    store.dispatch(reloadSubscriptions());
    store.dispatch(saveCapabilitiesSuccessful());
  }
};

export function* saveCapabilities({ payload: { capabilities, pushNotifications } }: any) {
  try {
    const currentPushNotifications = yield select(getPushNotifications);

    yield call(
      [backendClient, ApiMethods.SetCapabilities],
      capabilities,
      pushNotifications === undefined ? currentPushNotifications : pushNotifications
    );

    wsClient.subscribe(`${Roles.AGRIROUTER}.${WsMethods.CAPABILITIES}`, updateCapabilities);
  } catch (e) {
    yield put(saveCapabilitiesFailure());
    yield put(setLastError(e));
    logError("capabilities were not saved");
    yield call(refreshCapabilities);
    yield put(showFailedRequest(FailureMessages.SHOW_FAILURE_SAVE_CAPABILITIES));
  }
}

const updateSubscriptions = ({ success }: any) => {
  if (success) {
    store.dispatch(reloadSubscriptions());
    store.dispatch(saveSubscriptionsSuccessful());
    store.dispatch(showSuccessfulRequest(SuccessMessages.SHOW_SUCCESSFULL_SAVE_SUBSCRIPTIONS));
  }
};

export function* saveSubscriptions({ payload: { subscriptions } }: any) {
  try {
    yield call([backendClient, ApiMethods.SetSubscriptions], subscriptions);
    wsClient.subscribe(`${Roles.AGRIROUTER}.${WsMethods.SUBSCRIPTIONS}`, updateSubscriptions);
  } catch (e) {
    yield put(saveSubscriptionsFailure());
    yield put(setLastError(e));
    logError("subscriptions were not saved");
    yield call(refreshSubscriptions);
    yield put(showFailedRequest(FailureMessages.SHOW_FAILURE_SAVE_SUBSCRIPTIONS));
  }
}

function* refreshCapabilities() {
  try {
    const { pushNotifications, capabilities } = yield call([backendClient, ApiMethods.GetCapabilities]);
    yield put(setCapabilities(capabilities || [], pushNotifications));
    yield put(reloadCapabilitiesSuccessful());
  } catch (e) {
    yield put(reloadCapabilitiesFailure());
    yield put(setLastError(e));
    logError("capabilities reload is failed");
  }
}

function* refreshSubscriptions() {
  try {
    const subs = yield call([backendClient, "getSubscriptions"]);
    yield put(setSubscriptions(subs || []));
    yield put(reloadSubscriptionsSuccessful());
  } catch (e) {
    yield put(reloadSubscriptionsFailure());
    yield put(setLastError(e));
    logError("subscriptions reload is failed");
  }
}

export function* onboard({ payload: { params } }: any) {
  try {
    const { mqtt, qa } = params;

    const result = yield call([backendClient, ApiMethods.GetOnboardUrl], mqtt, qa);
    const { status, data } = result || {};

    if (status === 200) {
      if (data) {
        const onboardRedirectUrl = process.env.REACT_APP_ONBOARD_REDIRECT_URL
          ? `${data}&redirect_uri=${process.env.REACT_APP_ONBOARD_REDIRECT_URL}`
          : data;

        window.location.href = onboardRedirectUrl;
      }
    } else {
      throw new Error("Onboard was not successful");
    }

    yield put(onboardSuccessful());
    yield call(reload);
  } catch (e) {
    yield put(onboardFailure());
    yield put(setLastError(e));
    logError("Onboard was not successful");
  }
}

export function* disconnect() {
  try {
    yield call([backendClient, ApiMethods.Offboard]);

    yield put(disconnectSuccessful());

    const statusData: StatusData = {
      expireDays: null,
      onboarded: false,
      onboarding: false,
      qa: false,
      mqtt: false,
      pendingEndpoints: false,
      accountId: undefined,
      endpointId: undefined,
    };

    yield put(setStatusData(statusData));
  } catch (e) {
    yield put(disconnectFailure());
    yield put(setLastError(e));
    logError("disconnect was not successful");
  }
}

export function* reconnect() {
  try {
    yield call([backendClient, ApiMethods.Reconnect]);

    yield put(reconnectSuccessful());
  } catch (e) {
    yield put(reconnectFailure());
    yield put(setLastError(e));
    logError("reconnect was not successful");
  }
}

export function* clearLogs() {
  try {
    yield call([backendClient, ApiMethods.ClearLogs]);
    yield put(clearLogsSuccess());
    yield put(setLogs([]));
  } catch (e) {
    yield put(clearLogsFailure());
    yield put(setLastError(e));
    logError("clear logs was not successful");
  }
}

export function* fetchLoggingStatus(): any {
  try {
    const isLoggingEnabled = yield call([backendClient, ApiMethods.FetchLoggingStatus]);

    yield put(fetchLoggingStatusesSuccess(isLoggingEnabled));
  } catch (e) {
    yield put(fetchLoggingStatusesFailure());
    yield put(setLastError(e));
    logError("Fetch logging statuses were not successful");
  }
}

export function* changeLoggingStatus({ payload }: any): any {
  try {
    const status: any = {
      isLoggingEnabled: payload,
    };
    yield call([backendClient, ApiMethods.ChangeLoggingStatus], payload);
    yield put(changeLoggingStatusSuccess(status));
  } catch (e) {
    yield put(changeLoggingStatusFailure());
    yield put(setLastError(e));
    logError("Change logging status was not successful");
  }
}

export function* fetchTraceLoggingStatus(): any {
  try {
    const traceLoggingEnabledTill = yield call([backendClient, ApiMethods.FetchTraceLoggingStatus]);
    yield put(fetchTraceLoggingStatusesSuccess(traceLoggingEnabledTill));
  } catch (e) {
    yield put(fetchTraceLoggingStatusesFailure());
    yield put(setLastError(e));
    logError("Fetch trace logging statuses were not successful");
  }
}

export function* changeTraceLoggingStatus({ payload }: any): any {
  try {
    const traceLoggingEnabledTill = yield call([backendClient, ApiMethods.ChangeTraceLoggingStatus], payload);

    yield put(changeTraceLoggingStatusSuccess(traceLoggingEnabledTill));
  } catch (e) {
    yield put(changeTraceLoggingStatusFailure());
    yield put(setLastError(e));
    logError("Change trace logging status was not successful");
  }
}

export function* reonboard() {
  try {
    const result = yield call([backendClient, ApiMethods.GetReonboardUrl]);
    const { status, data } = result || {};

    if (status === 200) {
      if (data) {
        const reonboardRedirectUrl = process.env.REACT_APP_ONBOARD_REDIRECT_URL
          ? `${data}&redirect_uri=${process.env.REACT_APP_ONBOARD_REDIRECT_URL}`
          : data;

        window.location.href = reonboardRedirectUrl;
      }
    } else {
      throw new Error("Re-onboard was not successful");
    }

    yield put(reonboardSuccessful());
    yield call(reload);
  } catch (e) {
    yield put(onboardFailure());
    yield put(setLastError(e));
    logError("Re-onboard was not successful");
  }
}

export function* fetchEnvironments() {
  try {
    const data = yield call([backendClient, ApiMethods.GetEnvironments]);
    yield put(fetchEnvironmentsSuccess(data));
  } catch (e) {
    yield put(setLastError(e));
    logError("fetch environment links were not successful");
  }
}

export function* wsInit(): any {
  try {
    yield call([wsClient, "wsInit"]);
  } catch (e) {
    yield put(setLastError(e));
    logError("Failed to connect to websocket");
  }
}

export function* waitForInit(): any {
  const isInited = yield select(getDataStatus);

  if (!isInited) {
    yield take(GlobalActionType.INIT_SUCCESSFUL);
  }
}

export default function* globalSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);

  yield takeLatest(GlobalActionType.INIT, init);
  yield takeLatest(GlobalActionType.WS_INIT, wsInit);
  yield takeLatest(GlobalActionType.JUSTIFY_ONBOARDING, justifyOnboarding);
  yield takeLatest(GlobalActionType.SET_INITIALIZATION_CHECKINGS_DONE, fetchData);
  yield takeLatest(GlobalActionType.SAVE_CAPABILITIES, saveCapabilities);
  yield takeLatest(GlobalActionType.SAVE_SUBSCRIPTIONS, saveSubscriptions);
  yield takeLatest(AuthenticationActionType.RELOGIN_SUCCESSFULL, checkStatus);
  yield takeLatest(GlobalActionType.ONBOARD, onboard);
  yield takeLatest(GlobalActionType.REONBOARD, reonboard);
  yield takeLatest(GlobalActionType.RECONNECT, reconnect);
  yield takeLatest(GlobalActionType.DISCONNECT, disconnect);
  yield takeLatest(GlobalActionType.CLEAR_LOGS, clearLogs);
  yield takeLatest(GlobalActionType.FETCH_LOGGING_STATUS, fetchLoggingStatus);
  yield takeLatest(GlobalActionType.CHANGE_LOGGING_STATUS, changeLoggingStatus);
  yield takeLatest(GlobalActionType.CHANGE_TRACE_LOGGING_STATUS, changeTraceLoggingStatus);
  yield takeLatest(GlobalActionType.FETCH_TRACE_LOGGING_STATUS, fetchTraceLoggingStatus);
  yield takeLatest(GlobalActionType.RELOAD_CAPABILITIES, refreshCapabilities);
  yield takeLatest(GlobalActionType.RELOAD_SUBSCRIPTIONS, refreshSubscriptions);
  yield takeLatest(GlobalActionType.FETCH_ENVIRONMENT, fetchEnvironments);
}
