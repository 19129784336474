import { Capability, LogEntry, OnboardParams, StatusData, Subscription } from "../../api/backend-api";
import { Action } from "../action";

export enum GlobalActionType {
  INIT = "INIT",
  INIT_SUCCESSFUL = "INIT_SUCCESSFUL",
  INIT_FAILURE = "INIT_FAILURE",

  WS_INIT = "WS_INIT",

  SET_INITIALIZATION_CHECKINGS_DONE = "SET_INITIALIZATION_CHECKINGS_DONE",

  JUSTIFY_ONBOARDING = "JUSTIFY_ONBOARDING",
  JUSTIFY_ONBOARDING_FINISH = "JUSTIFY_ONBOARDING_FINISH",

  SAVE_CAPABILITIES = "SAVE_CAPABILITIES",
  SAVE_CAPABILITIES_SUCCESSFUL = "SAVE_CAPABILITIES_SUCCESSFUL",
  SAVE_CAPABILITIES_FAILURE = "SAVE_CAPABILITIES_FAILURE",

  SAVE_SUBSCRIPTIONS = "SAVE_SUBSCRIPTIONS",
  SAVE_SUBSCRIPTIONS_SUCCESSFUL = "SAVE_SUBSCRIPTIONS_SUCCESSFUL",
  SAVE_SUBSCRIPTIONS_FAILURE = "SAVE_SUBSCRIPTIONS_FAILURE",

  RELOAD_CAPABILITIES = "RELOAD_CAPABILITIES",
  RELOAD_CAPABILITIES_SUCCESSFUL = "RELOAD_CAPABILITIES_SUCCESSFUL",
  RELOAD_CAPABILITIES_FAILURE = "RELOAD_CAPABILITIES_FAILURE",

  RELOAD_SUBSCRIPTIONS = "RELOAD_SUBSCRIPTIONS",
  RELOAD_SUBSCRIPTIONS_SUCCESSFUL = "RELOAD_SUBSCRIPTIONS_SUCCESSFUL",
  RELOAD_SUBSCRIPTIONS_FAILURE = "RELOAD_SUBSCRIPTIONS_FAILURE",

  ONBOARD = "ONBOARD",
  ONBOARD_SUCCESSFUL = "ONBOARD_SUCCESSFUL",
  ONBOARD_FAILURE = "ONBOARD_FAILURE",

  REONBOARD = "REONBOARD",
  REONBOARD_SUCCESSFUL = "REONBOARD_SUCCESSFUL",
  REONBOARD_FAILURE = "REONBOARD_FAILURE",

  RECONNECT = "RECONNECT",
  RECONNECT_SUCCESSFUL = "RECONNECT_SUCCESSFUL",
  RECONNECT_FAILURE = "RECONNECT_FAILURE",

  DISCONNECT = "DISCONNECT",
  DISCONNECT_SUCCESSFUL = "DISCONNECT_SUCCESSFUL",
  DISCONNECT_FAILURE = "DISCONNECT_FAILURE",

  SET_LAST_ERROR = "SET_LAST_ERROR",
  SET_CAPABILITIES = "SET_CAPABILITIES",
  SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS",
  SET_LOGS = "SET_LOGS",
  SET_LOG_ENTRY = "SET_LOG_ENTRY",
  SET_STATUS_DATA = "SET_STATUS_DATA",

  CLEAR_LOGS = "CLEAR_LOGS",
  CLEAR_LOGS_SUCCESS = "CLEAR_LOGS_SUCCESS",
  CLEAR_LOGS_FAILURE = "CLEAR_LOGS_FAILURE",

  FETCH_LOGGING_STATUS = "FETCH_LOGGING_STATUS",
  FETCH_LOGGING_STATUS_SUCCESS = "FETCH_LOGGING_STATUS_SUCCESS",
  FETCH_LOGGING_STATUS_FAILURE = "FETCH_LOGGING_STATUS_FAILURE",

  CHANGE_LOGGING_STATUS = "CHANGE_LOGGING_STATUS",
  CHANGE_LOGGING_STATUS_SUCCESS = "CHANGE_LOGGING_STATUS_SUCCESS",
  CHANGE_LOGGING_STATUS_FAILURE = "CHANGE_LOGGING_STATUS_FAILURE",

  FETCH_TRACE_LOGGING_STATUS = "FETCH_TRACE_LOGGING_STATUS",
  FETCH_TRACE_LOGGING_STATUS_SUCCESS = "FETCH_TRACE_LOGGING_STATUS_SUCCESS",
  FETCH_TRACE_LOGGING_STATUS_FAILURE = "FETCH_TRACE_LOGGING_STATUS_FAILURE",

  CHANGE_TRACE_LOGGING_STATUS = "CHANGE_TRACE_LOGGING_STATUS",
  CHANGE_TRACE_LOGGING_STATUS_SUCCESS = "CHANGE_TRACE_LOGGING_STATUS_SUCCESS",
  CHANGE_TRACE_LOGGING_STATUS_FAILURE = "CHANGE_TRACE_LOGGING_STATUS_FAILURE",

  FETCH_ENVIRONMENT = "FETCH_ENVIRONMENT",
  FETCH_ENVIRONMENT_SUCCESS = "FETCH_ENVIRONMENT_SUCCESS",
  FETCH_ENVIRONMENT_FAILURE = "FETCH_ENVIRONMENT_FAILURE",
}

export const init = (): Action<null> => ({
  type: GlobalActionType.INIT,
  payload: null,
});

export const initSuccessfull = (): Action<null> => ({
  type: GlobalActionType.INIT_SUCCESSFUL,
  payload: null,
});

export const initFailure = (): Action<null> => ({
  type: GlobalActionType.INIT_FAILURE,
  payload: null,
});

export const wsInit = (): Action<null> => ({
  type: GlobalActionType.WS_INIT,
  payload: null,
});

export const setInitializationCheckingDone = (): Action<null> => ({
  type: GlobalActionType.SET_INITIALIZATION_CHECKINGS_DONE,
  payload: null,
});

export const justifyOnboarding = (): Action<null> => ({
  type: GlobalActionType.JUSTIFY_ONBOARDING,
  payload: null,
});

export const justifyOnboardingFinish = (): Action<null> => ({
  type: GlobalActionType.JUSTIFY_ONBOARDING_FINISH,
  payload: null,
});

export const reloadCapabilities = (): Action<null> => ({
  type: GlobalActionType.RELOAD_CAPABILITIES,
  payload: null,
});

export const reloadCapabilitiesSuccessful = (): Action<null> => ({
  type: GlobalActionType.RELOAD_CAPABILITIES_SUCCESSFUL,
  payload: null,
});

export const reloadCapabilitiesFailure = (): Action<null> => ({
  type: GlobalActionType.RELOAD_CAPABILITIES_FAILURE,
  payload: null,
});

export const reloadSubscriptions = (): Action<null> => ({
  type: GlobalActionType.RELOAD_SUBSCRIPTIONS,
  payload: null,
});

export const reloadSubscriptionsSuccessful = (): Action<null> => ({
  type: GlobalActionType.RELOAD_SUBSCRIPTIONS_SUCCESSFUL,
  payload: null,
});

export const reloadSubscriptionsFailure = (): Action<null> => ({
  type: GlobalActionType.RELOAD_SUBSCRIPTIONS_FAILURE,
  payload: null,
});

export const saveCapabilities = (
  capabilities: Capability[],
  pushNotifications: number | null
): Action<{ capabilities: Capability[]; pushNotifications: number | null }> => ({
  type: GlobalActionType.SAVE_CAPABILITIES,
  payload: { capabilities, pushNotifications },
});

export const saveCapabilitiesSuccessful = (): Action<null> => ({
  type: GlobalActionType.SAVE_CAPABILITIES_SUCCESSFUL,
  payload: null,
});

export const saveCapabilitiesFailure = (): Action<null> => ({
  type: GlobalActionType.SAVE_CAPABILITIES_FAILURE,
  payload: null,
});

export const saveSubscriptions = (subscriptions: string[]): Action<{ subscriptions: string[] }> => ({
  type: GlobalActionType.SAVE_SUBSCRIPTIONS,
  payload: { subscriptions },
});

export const saveSubscriptionsSuccessful = (): Action<null> => ({
  type: GlobalActionType.SAVE_SUBSCRIPTIONS_SUCCESSFUL,
  payload: null,
});

export const saveSubscriptionsFailure = (): Action<null> => ({
  type: GlobalActionType.SAVE_SUBSCRIPTIONS_FAILURE,
  payload: null,
});

export const onboard = (params?: OnboardParams): Action<{ params: OnboardParams | typeof undefined }> => ({
  type: GlobalActionType.ONBOARD,
  payload: { params },
});

export const onboardSuccessful = (): Action<null> => ({
  type: GlobalActionType.ONBOARD_SUCCESSFUL,
  payload: null,
});

export const onboardFailure = (): Action<null> => ({
  type: GlobalActionType.ONBOARD_FAILURE,
  payload: null,
});

export const reonboard = (): Action<null> => ({
  type: GlobalActionType.REONBOARD,
  payload: null,
});

export const reonboardSuccessful = (): Action<null> => ({
  type: GlobalActionType.REONBOARD_SUCCESSFUL,
  payload: null,
});

export const reonboardFailure = (): Action<null> => ({
  type: GlobalActionType.REONBOARD_FAILURE,
  payload: null,
});

export const reconnect = (): Action<null> => ({
  type: GlobalActionType.RECONNECT,
  payload: null,
});

export const reconnectSuccessful = (): Action<null> => ({
  type: GlobalActionType.RECONNECT_SUCCESSFUL,
  payload: null,
});

export const reconnectFailure = (): Action<null> => ({
  type: GlobalActionType.RECONNECT_FAILURE,
  payload: null,
});

export const disconnect = (): Action<null> => ({
  type: GlobalActionType.DISCONNECT,
  payload: null,
});

export const disconnectSuccessful = (): Action<null> => ({
  type: GlobalActionType.DISCONNECT_SUCCESSFUL,
  payload: null,
});

export const disconnectFailure = (): Action<null> => ({
  type: GlobalActionType.DISCONNECT_FAILURE,
  payload: null,
});

export const setLastError = (error: any) => ({
  type: GlobalActionType.SET_LAST_ERROR,
  payload: error,
});

export const setCapabilities = (
  capabilities: Capability[],
  pushNotifications: number
): Action<{ capabilities: Capability[]; pushNotifications: number }> => ({
  type: GlobalActionType.SET_CAPABILITIES,
  payload: { capabilities, pushNotifications },
});

export const setSubscriptions = (
  subscriptions: Subscription[]
): Action<{ subscriptions: Subscription[] }> => ({
  type: GlobalActionType.SET_SUBSCRIPTIONS,
  payload: { subscriptions },
});

export const setLogs = (logs: LogEntry[]): Action<LogEntry[]> => ({
  type: GlobalActionType.SET_LOGS,
  payload: logs,
});

export const setLogEntry = (entry: LogEntry): Action<LogEntry> => ({
  type: GlobalActionType.SET_LOG_ENTRY,
  payload: entry,
});

export const setStatusData = (data: StatusData): Action<StatusData> => ({
  type: GlobalActionType.SET_STATUS_DATA,
  payload: data,
});

export const clearLogs = (): Action<null> => ({
  type: GlobalActionType.CLEAR_LOGS,
  payload: null,
});

export const clearLogsSuccess = (): Action<null> => ({
  type: GlobalActionType.CLEAR_LOGS_SUCCESS,
  payload: null,
});

export const clearLogsFailure = (): Action<null> => ({
  type: GlobalActionType.CLEAR_LOGS_FAILURE,
  payload: null,
});

export const fetchLoggingStatus = (): Action<null> => ({
  type: GlobalActionType.FETCH_LOGGING_STATUS,
  payload: null,
});

export const fetchLoggingStatusesSuccess = (isLoggingEnabled: boolean): Action<boolean> => ({
  type: GlobalActionType.FETCH_LOGGING_STATUS_SUCCESS,
  payload: isLoggingEnabled,
});

export const fetchLoggingStatusesFailure = (): Action<null> => ({
  type: GlobalActionType.FETCH_LOGGING_STATUS_FAILURE,
  payload: null,
});

export const changeLoggingStatus = (isLoggingEnabled: boolean): Action<boolean> => ({
  type: GlobalActionType.CHANGE_LOGGING_STATUS,
  payload: isLoggingEnabled,
});

export const changeLoggingStatusSuccess = (status: any): Action<any> => ({
  type: GlobalActionType.CHANGE_LOGGING_STATUS_SUCCESS,
  payload: status,
});

export const changeLoggingStatusFailure = (): Action<null> => ({
  type: GlobalActionType.CHANGE_LOGGING_STATUS_FAILURE,
  payload: null,
});

export const fetchTraceLoggingStatus = (): Action<null> => ({
  type: GlobalActionType.FETCH_TRACE_LOGGING_STATUS,
  payload: null,
});

export const fetchTraceLoggingStatusesSuccess = (traceLoggingEnabledTill: number): Action<number> => ({
  type: GlobalActionType.FETCH_TRACE_LOGGING_STATUS_SUCCESS,
  payload: traceLoggingEnabledTill,
});

export const fetchTraceLoggingStatusesFailure = (): Action<null> => ({
  type: GlobalActionType.FETCH_TRACE_LOGGING_STATUS_FAILURE,
  payload: null,
});

export const changeTraceLoggingStatus = (isTraceLoggingEnabled: boolean): Action<boolean> => ({
  type: GlobalActionType.CHANGE_TRACE_LOGGING_STATUS,
  payload: isTraceLoggingEnabled,
});

export const changeTraceLoggingStatusSuccess = (status: any): Action<any> => ({
  type: GlobalActionType.CHANGE_TRACE_LOGGING_STATUS_SUCCESS,
  payload: status,
});

export const changeTraceLoggingStatusFailure = (): Action<null> => ({
  type: GlobalActionType.CHANGE_TRACE_LOGGING_STATUS_FAILURE,
  payload: null,
});

export const fetchEnvironments = (): Action<null> => ({
  type: GlobalActionType.FETCH_ENVIRONMENT,
  payload: null,
});

export const fetchEnvironmentsSuccess = (links: any): Action<any> => ({
  type: GlobalActionType.FETCH_ENVIRONMENT_SUCCESS,
  payload: links,
});

export const fetchEnvironmentsFailure = (): Action<null> => ({
  type: GlobalActionType.FETCH_ENVIRONMENT_FAILURE,
  payload: null,
});
