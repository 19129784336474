import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "black",
    fontSize: "14pt",
  },
  title: {
    fontWeight: "bold",
    fontSize: "16pt",
    marginRight: 6,
  },
  contactDetails: {
    marginBottom: "20px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  statusIcon: {
    display: "flex",
  },
  fatalIcon: {
    color: Colors.Red,
    fontSize: "26px",
  },
  checkIcon: {
    color: Colors.Green2,
    fontSize: "26px",
  },
  errorIcon: {
    color: Colors.Orange,
    fontSize: "26px",
  },
  warningIcon: {
    color: Colors.Yellow,
    fontSize: "26px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0 20px 0",
  },
}));

export default useStyles;
