import { FunctionComponent, useEffect } from "react";
import { NotificationMessage } from "../../store/notifications/notifications.actions";
import { useSnackbar } from "notistack";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { NotificationDelay } from "./notifications-delay";

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      fontSize: "1em",
      color: "white",
      border: "1px solid white",
      padding: "4px 16px",
      "&:first-child": {
        marginRight: "1em",
      },
    },
  });

export interface ConnectedState {
  notification: NotificationMessage | undefined;
}

interface Props extends ConnectedState, WithStyles<typeof styles> {}

const NotificationsComponent: FunctionComponent<Props> = (props) => {
  const { notification } = props;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (notification && notification.message !== "") {
      enqueueSnackbar(notification.message, {
        variant: notification.variant,
        autoHideDuration: notification.delay || NotificationDelay.DEFAULT,
        preventDuplicate: true,
      });
    }
  }, [notification, enqueueSnackbar]);

  return null;
};

export default withStyles(styles)(NotificationsComponent);
