import { Action } from "../action";
import { NotificationDelay } from "../../components/notifications/notifications-delay";

export enum NotificationsActionType {
  SHOW_FAILED_REQUEST = "SHOW_FAILED_REQUEST",
  SHOW_WARNING_REQUEST = "SHOW_WARNING_REQUEST",
  SHOW_NOT_FOUND_REQUEST = "SHOW_NOT_FOUND_REQUEST",
  SHOW_SUCCESSFUL_REQUEST = "SHOW_SUCCESSFUL_REQUEST",
}

export interface NotificationMessage {
  message: string;
  variant: "default" | "error" | "success" | "warning" | "info";
  delay: number;
}

export const showFailedRequest = (message?: any): Action<NotificationMessage> => ({
  type: NotificationsActionType.SHOW_FAILED_REQUEST,
  payload: {
    message: !!message ? message : "Default error message",
    variant: "error",
    delay: NotificationDelay.LONG,
  },
});

export const showSuccessfulRequest = (message: string): Action<NotificationMessage> => ({
  type: NotificationsActionType.SHOW_SUCCESSFUL_REQUEST,
  payload: {
    message,
    variant: "success",
    delay: NotificationDelay.SHORT,
  },
});

export const showNotFoundRequest = (): Action<NotificationMessage> => ({
  type: NotificationsActionType.SHOW_NOT_FOUND_REQUEST,
  payload: {
    message: "No data could be found for the request!",
    variant: "warning",
    delay: NotificationDelay.DEFAULT,
  },
});

export const showWarningRequest = (message: string): Action<NotificationMessage> => ({
  type: NotificationsActionType.SHOW_WARNING_REQUEST,
  payload: {
    message,
    variant: "warning",
    delay: NotificationDelay.SHORT,
  },
});
