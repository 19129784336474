import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, makeStyles } from "@material-ui/core";
import { Colors } from "../../theme/colors";

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  circularProgress: {
    color: Colors.Green,
  },
  description: {
    fontSize: "16pt",
    justifyContent: "center",
    alignItems: "center",
    color: Colors.Grey,
  },
}));

const Preloader = () => {
  const classes = useStyles();

  return (
    <div className={classes.flexWrapper}>
      <CircularProgress className={classes.circularProgress} size={60} />
      <Typography className={classes.description}>Loading...</Typography>
    </div>
  );
};

export default Preloader;
